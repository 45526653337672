import { get, API_URL } from '../../api'
import { SET_REJECTED_POSTINGS } from '../constants'

export const fetchRejectedPostings = async dispatch => {
  const res = await get(`${API_URL}/posting/rejected`)
  if (res.status === 200) {
    const newPostings = await res.json()
    return dispatch({
      type: SET_REJECTED_POSTINGS,
      payload: newPostings
    })
  }
}

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

const StyledSelect = styled.select`
  background: transparent;
  border: 1px solid ${props => props.theme.colorBorder};
  font-size: ${props => props.theme.fontSize};
  height: 40px;
  width: 100%;
`

const DropdownInput = ({ onChange, options, value }) => (
  <StyledSelect onChange={e => onChange(e.target.value)} value={value}>
    <option value="">Valitse</option>
    {options.map((option, i) => (
      <option key={i} value={option.value}>
        {option.displayName}
      </option>
    ))}
  </StyledSelect>
)

DropdownInput.propTypes = {
  onChange: PropTypes.func,
  options: PropTypes.array,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
}

export default DropdownInput

import { SET_POSTINGS, SET_IS_SENT } from '../constants'

const postingsReducer = (state, action) => {
  switch (action.type) {
    case SET_POSTINGS:
      return { ...state, [action.payload.postingType]: action.payload.postings }
    case SET_IS_SENT:
      return { ...state, isSent: action.payload }
    default:
      return state
  }
}

export default postingsReducer

import { getFieldUnit } from '../postingInputFields'

export const createDisplayStringWithUnit = (posting, type, field) => {
  if (posting[field]) {
    return `${posting[field]} ${getFieldUnit(type, field)}`
  }
  return
}

export const createDisplayStringFromArray = arr => {
  if (arr) {
    return arr.reduce((acc, item) => {
      let stringToAdd = ''
      stringToAdd += arr.indexOf(item) !== 0 ? item.toLowerCase() : item
      if (arr.indexOf(item) !== arr.length - 1) {
        stringToAdd += ', '
      }
      return acc + stringToAdd
    }, '')
  }
}

export const createDisplayStringForPricePerArea = (price, area) => {
  const pricePerArea = (Number(price) / Number(area)).toFixed(2)
  return pricePerArea + ' €/m2'
}

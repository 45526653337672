import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { media } from '../styles'

const StyledSectionWithAside = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${props => props.theme.contentSpacingMobile};

  ${media.tabletLandscapeUp`
    flex-direction: row;
    padding-top: ${props => props.theme.contentSpacing};
  `}

  > :first-child {
    ${media.tabletLandscapeUp`
      flex: 3;
      padding-right: 40px;
    `}

    ${media.desktopUp`
      flex: 2;
    `}
  }
  > :nth-child(2) {
    display: none;

    ${media.tabletLandscapeUp`
      display: block;
      flex: 2;
    `}

    ${media.desktopUp`
      flex: 1;
    `}
  }
`

const SectionWithAside = ({ children }) => (
  <StyledSectionWithAside>{children}</StyledSectionWithAside>
)

SectionWithAside.propTypes = {
    children: PropTypes.any
}

export default SectionWithAside

import { SET_REJECTED_POSTINGS, REMOVE_REJECTED_POSTING } from '../constants'

const rejectedPostingsReducer = (state, action) => {
  switch (action.type) {
    case SET_REJECTED_POSTINGS: {
        return action.payload
    }
    case REMOVE_REJECTED_POSTING: {
        const type = action.payload.type
        const id = action.payload.id
        return {
            ...state,
            [type]: state[type].filter(p => p.id !== id)
        }
    }
    default:
      return state
  }
}

export default rejectedPostingsReducer

import { SET_FILTER_ITEMS } from '../constants'

const filterItemsReducer = (state, action) => {
  switch (action.type) {
    case SET_FILTER_ITEMS:
      return action.payload
    default:
      return state
  }
}

export default filterItemsReducer

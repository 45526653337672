import { mapKeys, camelCase, get } from 'lodash-es'

// Expects a CognitoUser object containing
// CognitoUser.attributes and CognitoUser.signInUserSession objects
// that is returned by Auth.currentAuthenticatedUser()
export function mapUserAttributes(user) {
  const allAttributes = {
    ...user.attributes,
    groups: get(
      user,
      ['signInUserSession', 'idToken', 'payload', 'cognito:groups'],
      []
    )
  }
  return mapKeys(mapKeysToCase(allAttributes, camelCase), (_, key) => {
    if (key === 'sub') return 'id'
    if (key === 'givenName') return 'firstname'
    if (key === 'familyName') return 'lastname'
    return key
  })
}

export function mapKeysToCase(arrayOrObject, caseFn) {
  if (Array.isArray(arrayOrObject))
    return arrayOrObject.map(object => mapKeys(object, (_, key) => caseFn(key)))
  return mapKeys(arrayOrObject, (_, key) => caseFn(key))
}

import { get, post, del, API_URL } from '../../api'
import {
  SET_UNAPPROVED_POSTINGS,
  REMOVE_UNAPPROVED_POSTING,
  REMOVE_REJECTED_POSTING
} from '../constants'
import { camelCase } from 'lodash-es'

export const fetchUnapprovedPostings = async dispatch => {
  const res = await get(`${API_URL}/posting/unapproved`)
  if (res.status === 200) {
    const newPostings = await res.json()
    return dispatch({
      type: SET_UNAPPROVED_POSTINGS,
      payload: newPostings
    })
  }
}

const removeUnapprovedPostingFromState = (postingType, postingId, dispatch) => {
  return dispatch({
    type: REMOVE_UNAPPROVED_POSTING,
    payload: {
      id: postingId,
      type: camelCase(postingType)
    }
  })
}

export const approvePosting = (
  postingType,
  postingId,
  isRejected
) => async dispatch => {
  const res = await post(
    `${API_URL}/posting/approve/${postingType}/${postingId}`
  )
  if (res.status !== 200) return
  isRejected
    ? dispatch({
        type: REMOVE_REJECTED_POSTING,
        payload: {
          id: postingId,
          type: camelCase(postingType)
        }
      })
    : removeUnapprovedPostingFromState(postingType, postingId, dispatch)
}

export const rejectPosting = (
  postingType,
  postingId,
  data
) => async dispatch => {
  const res = await post(
    `${API_URL}/posting/reject/${postingType}/${postingId}`,
    data
  )
  if (res.status === 200) {
    return removeUnapprovedPostingFromState(postingType, postingId, dispatch)
  }
}

export const adminDeletePosting = (
  postingType,
  postingId,
  data,
  isRejected
) => async dispatch => {
  const res = await del(`${API_URL}/posting/${postingType}/${postingId}`, data)
  if (res.status !== 200) return
  isRejected
    ? dispatch({
        type: REMOVE_REJECTED_POSTING,
        payload: {
          id: postingId,
          type: camelCase(postingType)
        }
      })
    : removeUnapprovedPostingFromState(postingType, postingId, dispatch)
}

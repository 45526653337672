import React, { useEffect, useState } from 'react'
import { API_URL, get } from './../api'
import { mapPostingToCard } from '../utils/mappings'
import { StyledCard, StyledPostingList } from './MapListView'

const postingType = 'rental'

const TiedepuistoRentalsView = () => {
  const [postings, setPostings] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      const response = await get(API_URL + '/rental/ext/tiedepuisto')
      const postings = await response.json()
      setPostings(postings)
    }
    fetchData()
  }, [])

  return (
    <StyledPostingList>
      {postings.map(posting => {
        const cardInfo = mapPostingToCard(posting, postingType)
        return cardInfo
          ? (
            <StyledCard
              key={posting.id}
              cardInfo={mapPostingToCard(posting, postingType)}
              type={postingType}
              linkTo={`/${postingType}/${posting.id}`}
              target="_blank"
            />
          )
          : null
      })}
    </StyledPostingList>
  )
}

export default TiedepuistoRentalsView

export const inputTypes = {
  DROPDOWN: 'dropdown',
  TEXT: 'text',
  NUMBER: 'number',
  TEXTAREA: 'textarea',
  ATTACHMENT: 'attachment',
  CHECKBOX_LIST: 'checkboxList',
  DATE: 'date',
  TIME: 'time'
}

export const postingTypeStrings = {
  RENTAL: 'rental',
  FOR_SALE: 'for-sale',
  CONFERENCE_ROOM: 'conference-room',
  PLOT: 'plot'
}

export const imageSizes = [
  [1200, 750],
  [900, 563],
  [600, 375],
  [432, 270],
  [336, 210]
]

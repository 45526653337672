import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { media } from '../styles'
import { fadeIn, grow } from '../styles/animations'

const StyledModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: ${props => (props.showModal ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 3;
  animation: ${fadeIn} 400ms, ${grow} 400ms;
`

export const ModalMain = styled.div`
  box-sizing: border-box;
  padding: 40px;
  flex: 1;
  background-color: ${props => props.theme.colorBackgroundPrimary};
  position: absolute;
  top: 0;
  overflow-y: scroll;

  ${media.tabletPortraitUp`
    position: relative;
  `}

  ${media.desktopUp`
    padding: 40px 60px;
  `}
`

const FullSizeModalMain = styled(ModalMain)`
  width: 100%;
  height: 100%;
  min-height: 100vh;

  ${media.tabletPortraitUp`
    max-width: 80%;
    max-height: 80%;
    min-height: auto;
  `}

  ${media.tabletLandscapeUp`
    max-width: 60%;
  `}
`

export const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 25px;
  height: 25px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;

  > div {
    width: 25px;
    height: 2px;
    position: absolute;
    left: 0;
    background-color: #000;

    &:first-child {
      transform: rotate(45deg);
    }

    &:nth-child(2) {
      transform: rotate(-45deg);
    }
  }

  ${media.tabletPortraitUp`
    top: 10px;
    padding: 0 10px;
  `}
`

const Modal = ({
  children,
  showModal,
  closeModal,
  fullSize,
  className,
  ...rest
}) => {
  const modalRef = useRef(null)

  const handleClick = e => {
    if (modalRef.current.contains(e.target)) return
    closeModal()
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClick)

    return () => {
      document.removeEventListener('mousedown', handleClick)
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closeModal])

  const renderContent = () => (
    <>
      <CloseButton type="button" onClick={closeModal}>
        <div />
        <div />
      </CloseButton>
      {children}
    </>
  )

  return (
    <StyledModal showModal={showModal} className={className} {...rest}>
      {fullSize
        ? (
          <FullSizeModalMain ref={modalRef}>{renderContent()}</FullSizeModalMain>
        )
        : (
          <ModalMain ref={modalRef}>{renderContent()}</ModalMain>
        )}
    </StyledModal>
  )
}

Modal.propTypes = {
  children: PropTypes.any,
  showModal: PropTypes.bool,
  closeModal: PropTypes.func,
  fullSize: PropTypes.bool,
  className: PropTypes.string
}

export default Modal

import { createGlobalStyle, css } from 'styled-components/macro'

export const sizes = {
  largeDesktopUp: 1600,
  desktopUp: 1200,
  tabletLandscapeUp: 900,
  tabletPortraitUp: 600
}

export const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${sizes[label]}px) {
      ${css(...args)}
    }
  `
  return acc
}, {})

export const theme = {
  colorBackgroundPrimary: '#fff',
  colorBackgroundSecondary: '#f5f5f5',
  colorAccentPrimary: '#fcbd29',
  colorAccentSecondary: '#022640',
  colorText: '#555',
  colorBorder: '#DDDDDD',
  colorSuccess: '#5cc34f',
  colorDanger: '#de3838',
  subNavHeight: '60px',
  mainNavHeight: '100px',
  mainNavHeightMobile: '60px',
  fontFamily: 'Roboto, sans-serif',
  fontSize: '16px',
  fontSizeSmall: '14px',
  fontSizeMobile: '12px',
  contentSpacing: '40px',
  contentSpacingMobile: '20px'
}

export const GlobalStyle = createGlobalStyle`
  html, body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: ${props => props.theme.fontFamily};
    font-size: ${props => props.theme.fontSizeMobile};
    color: ${props => props.theme.colorText};

    ${media.tabletPortraitUp`
      font-size: ${props => props.theme.fontSize};
    `}
  }

  p {
    font-size: ${props => props.theme.fontSize};
  }

  #root {
    width: 100%;
    height: 100%;
  }

  img {
    display: block;
    margin: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }

  h1, h2, h3 {
    color: ${props => props.theme.colorAccentSecondary};
    font-weight: normal;
    margin: 0;
  }

  h1 {
    font-size: 2.5rem;
  }

  h2 {
    font-size: 2rem;
    padding-bottom: 30px;
  }

  h3 {
    font-size: 1.5rem;
  }

  label, a {
    color: ${props => props.theme.colorAccentSecondary};
  }

  label {
    font-size: ${props => props.theme.fontSize};
  }
`

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

const StyledButton = styled.button`
  width: ${props => (props.isFullWidth ? '100%' : 'auto')};
  background-color: ${props =>
    props.isPrimary
      ? props.theme.colorAccentPrimary
      : props.theme.colorAccentSecondary};
  color: #fff;
  padding: 15px 30px;
  font-size: ${props => props.theme.fontSize};
  border: none;
  cursor: pointer;
`

const Button = ({ isPrimary, isFullWidth, children, ...rest }) => (
  <StyledButton
    type="button"
    isPrimary={isPrimary}
    isFullWidth={isFullWidth}
    {...rest}
  >
    {children}
  </StyledButton>
)

Button.propTypes = {
    isPrimary: PropTypes.bool,
    isFullWidth: PropTypes.bool,
    children: PropTypes.any
}

export default Button

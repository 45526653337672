import { postingTypeStrings } from '../constants'
import { createDisplayStringWithUnit } from './helpers'
import { getFirstUpcomingShowingText } from '../showings'

export const mapPostingToCard = (posting, type) => {
  if (!posting) {
    throw new Error('mapPostingToCard: no posting specified')
  }
  if (!type) {
    throw new Error('mapPostingToCard: no posting type specified')
  }
  switch (type) {
    case postingTypeStrings.RENTAL:
      return {
        leftColumnItems: [
          posting.address,
          posting.district
            ? posting.district + ', ' + posting.municipality
            : posting.municipality,
          posting.type
        ],
        rightColumnItems: [
          createDisplayStringWithUnit(posting, type, 'price'),
          createDisplayStringWithUnit(posting, type, 'areaM2')
        ],
        image: posting.coverImage,
        id: posting.id,
        highlight:
          posting.metadata && posting.metadata.showings
            ? getFirstUpcomingShowingText(posting.metadata.showings)
            : undefined
      }
    case postingTypeStrings.FOR_SALE:
      return {
        leftColumnItems: [
          posting.address,
          posting.district
            ? posting.district + ', ' + posting.municipality
            : posting.municipality,
          posting.type
        ],
        rightColumnItems: [
          createDisplayStringWithUnit(posting, type, 'price'),
          createDisplayStringWithUnit(posting, type, 'areaM2')
        ],
        image: posting.coverImage,
        id: posting.id,
        highlight:
          posting.metadata && posting.metadata.showings
            ? getFirstUpcomingShowingText(posting.metadata.showings)
            : undefined
      }
    case postingTypeStrings.PLOT:
      return {
        leftColumnItems: [
          posting.address,
          posting.district
            ? posting.district + ', ' + posting.municipality
            : posting.municipality
        ],
        rightColumnItems: [
          createDisplayStringWithUnit(posting, type, 'priceToBePaid'),
          createDisplayStringWithUnit(posting, type, 'plotAreaM2')
        ],
        image: posting.coverImage,
        id: posting.id
      }
    case postingTypeStrings.CONFERENCE_ROOM:
      return {
        leftColumnItems: [
          posting.name,
          posting.address + ', ' + posting.municipality,
          posting.price
        ],
        capacityOverlay: posting.capacity,
        image: posting.coverImage,
        id: posting.id
      }
    default:
      return null
  }
}

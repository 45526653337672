import React, { useEffect } from 'react'
import styled from 'styled-components/macro'
import { media } from '../styles'
import { createPortal } from 'react-dom'
import NavLogo from './NavLogo'

const StyledMobileMenu = styled.div`
  box-sizing: border-box;
  width: 280px;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  transform: translateX(${props => (props.isMenuOpen ? '0px' : '-280px')});
  right: 0;
  background-color: ${props => props.theme.colorBackgroundPrimary};
  transition: 250ms ease-in-out;
  z-index: 2;
  pointer-events: ${props => (props.isMenuOpen ? 'auto' : 'none')};
  overflow-y: scroll;
`

const ChildrenContainer = styled.div`
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${media.tabletLandscapeUp`
    padding: 15px 30px;
  `}

  > * {
    padding: 20px 0;
    transition: 250ms ease-in-out;
    border-bottom: 1px solid ${props => props.theme.colorBackgroundSecondary};
  }
`

const TopContainer = styled.div`
  width: 100%;
  padding: 10px;
  border-bottom: 1px solid ${props => props.theme.colorBackgroundSecondary};
  box-sizing: border-box;

  ${media.tabletLandscapeUp`
    height: ${props => props.theme.mainNavHeight};
    padding: 15px 30px;
    margin-bottom: 0;
    display: flex;
    align-items: center;
  `}
`

const MobileMenuOverlay = styled.div`
  box-sizing: border-box;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: ${props => (props.isMenuOpen ? 'auto' : 'none')};
  background-color: rgba(0, 0, 0, 0.75);
  opacity: ${props => (props.isMenuOpen ? '1' : '0')};
  transition: 250ms ease-in-out;
  z-index: 1;

  ${media.desktopUp`
    display: none;
  `}
`

const createDiv = divId => {
  let dom = document.getElementById(divId)
  if (dom) {
    return dom
  }
  dom = document.createElement('div')
  dom.id = divId
  document.body.appendChild(dom)
  return dom
}

export const destroyDiv = divId => {
  const dom = document.getElementById(divId)
  if (!dom) {
    return
  }
  dom.remove()
}

const MobileMenu = ({ isMenuOpen, onClick, children, className }) => {
  const DIV_ID = 'MobileMenuContainer'

  useEffect(
    () => () => {
      destroyDiv(DIV_ID)
    },
    []
  )

  return createPortal(
    <>
      <StyledMobileMenu isMenuOpen={isMenuOpen} className={className}>
        <TopContainer>
          <NavLogo />
        </TopContainer>
        <ChildrenContainer>{children}</ChildrenContainer>
      </StyledMobileMenu>
      <MobileMenuOverlay isMenuOpen={isMenuOpen} onClick={onClick} />
    </>,
    createDiv(DIV_ID)
  )
}

export default MobileMenu

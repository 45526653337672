import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import MainNav from '../components/MainNav'
import SubNav, { SubNavLink } from '../components/SubNav'
import BasePage from '../components/BasePage'
import { NonLinkCard } from '../components/Card'
import NavTabs from '../components/NavTabs'
import UserTools from '../components/UserTools'
import Footer from '../components/Footer'
import { useStateValue } from '../state'
import { mapPostingToCard, postingTypes } from '../utils/mappings'
import { media } from '../styles'
import { camelCase, isEmpty } from 'lodash-es'

const StyledSubNav = styled(SubNav)`
  z-index: 1;
`

const StyledListContainer = styled(BasePage)`
  width: 100%;
`

const StyledPostingList = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-gap: ${props => props.theme.contentSpacingMobile};
  grid-auto-rows: 400px;

  ${media.tabletPortraitUp`
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 380px;
  `}

  ${media.tabletLandscapeUp`
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: 330px;
    grid-gap: ${props => props.theme.contentSpacing};
  `}

  ${media.desktopUp`
    grid-template-columns: 1fr 1fr 1fr 1fr;
  `}

  ${media.largeDesktopUp`
    grid-auto-rows: 450px;
  `}
`

const LinksContainer = styled.div`
  display: none;

  ${media.tabletLandscapeUp`
    display: flex;
    flex-direction: row;
    align-items: center;
  `}
`

const OwnPostings = ({ location, history }) => {
  const [{ ownPostings }] = useStateValue()
  const splitUrl = location.pathname.split('/')
  const postingType = splitUrl[splitUrl.length - 1]

  useEffect(() => {
    document.title = 'Business Joensuu -  Omat ilmoitukset'
  }, [])

  const links = () =>
    Object.keys(ownPostings)
      .filter(x => !isEmpty(ownPostings[x]))
      .map((key, i) => {
        const type = postingTypes.find(x => camelCase(x.type) === key)
        return (
          <SubNavLink
            key={i}
            to={`/own-postings/${type.type}`}
            selected={postingType === type.type}
          >
            {type.displayName}
          </SubNavLink>
        )
      })

  return (
    <>
      <MainNav history={history}>
        <h3>Omat ilmoitukset</h3>
      </MainNav>
      <StyledSubNav>
        <LinksContainer>{links()}</LinksContainer>
        <NavTabs tabs={links()} />
      </StyledSubNav>
      <StyledListContainer>
        {ownPostings[camelCase(postingType)] &&
          ownPostings[camelCase(postingType)].length && (
            <>
              <h2>
                {postingTypes.find(x => x.type === postingType).displayName}
              </h2>
              <StyledPostingList>
                {ownPostings[camelCase(postingType)].map(posting => {
                  let statusText
                  if (!posting.isApproved) statusText = 'Odottaa hyväksyntää'
                  if (posting.isRejected) statusText = 'Hylätty'
                  const cardInfo = mapPostingToCard(posting, postingType)
                  return cardInfo
                    ? (
                      <NonLinkCard
                        key={posting.id}
                        cardInfo={cardInfo}
                        type={postingType}
                        statusText={statusText}
                      >
                        <UserTools posting={posting} postingType={postingType} />
                      </NonLinkCard>
                    )
                    : null
                })}
              </StyledPostingList>
            </>
          )}
      </StyledListContainer>
      <Footer />
    </>
  )
}

OwnPostings.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object
}

export default OwnPostings

import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import Modal, { ModalMain, CloseButton } from './Modal'
import { splitEvery } from 'ramda'
import { media } from '../styles'
import { buildSrcSet } from '../utils/image'
import { take, uniqBy } from 'lodash-es'
import { useGesture } from 'react-use-gesture'

const PostingImageContainer = styled.div``

const ImageContainer = styled.div`
  margin-bottom: 20px;
  ${media.tabletLandscapeUp`
    margin-bottom: 0;
  `}
`

const ImageRow = styled.div`
  width: 100%;

  &:last-child > ${ImageContainer} {
    &:last-child {
      margin-bottom: 0;
    }
  }

  ${media.tabletLandscapeUp`
    height: 400px;
    display: flex;
    flex-direction: row;

    > :not(:last-child) {
      margin-right: 20px;
    }

    :not(:last-child) {
      margin-bottom: 20px;
    }

    :nth-child(odd) > :first-child {
      flex: 1;
    }
    :nth-child(odd) > :last-child {
      flex: 2;
    }
    :nth-child(even) > :first-child {
      flex: 2;
    }
    :nth-child(even) > :last-child {
      flex: 1;
    }
  `}
`

const StyledModal = styled(Modal)`
  > ${ModalMain} {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    max-height: 100%;
    background-color: #000;
    padding: 0;

    > button {
      background-color: transparent;
      color: #fff;
      outline: none;
      padding: 0;

      &:not(:nth-child(1)) {
        position: relative;
      }
    }

    > img {
      object-fit: contain;

      ${media.tabletPortraitUp`
        max-width: 80%;
        max-height: 80%;
      `}
    }

    > ${CloseButton} > div {
      background-color: #fff;
    }
  }
`

const Arrow = styled.div`
  ${media.tabletPortraitUp`
    width: 20px;
    height: 20px;
    margin: 10px;
    border-bottom: 2px solid ${props => props.theme.colorText};
    border-right: 2px solid ${props => props.theme.colorText};
    border-color: #fff;
    transform: ${props =>
      props.isRight ? 'rotate(-45deg)' : 'rotate(135deg)'};
    cursor: pointer;
  `}
`

const StyledImg = styled.img`
  cursor: pointer;
`

const ModalImage = styled.img`
  pointer-events: none;
`

// Postfix is in format of -123x321.jpg
const getImageKeys = images =>
  Array.from(
    new Set(
      images.map(img => {
        const parts = img.split('-')
        return take(parts, parts.length - 1).join('-')
      })
    )
  )

const PostingImages = React.forwardRef(({ images }, ref) => {
  const [showModal, setShowModal] = useState(false)
  const [modalImage, setModalImage] = useState('')
  if (images.length < 2) return null
  const imageKeys = getImageKeys(images)
  const rows = splitEvery(2, imageKeys)
  const currentIndex = () =>
    imageKeys.indexOf(imageKeys.find(x => x === modalImage))

  const prev = () => {
    setModalImage(
      currentIndex() > 0
        ? imageKeys[currentIndex() - 1]
        : imageKeys[imageKeys.length - 1]
    )
  }

  const next = () => {
    setModalImage(
      currentIndex() < imageKeys.length - 1
        ? imageKeys[currentIndex() + 1]
        : imageKeys[0]
    )
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const keyUpHandler = useCallback(e => {
    if (e.keyCode === 39) next()
    if (e.keyCode === 37) prev()
  })

  useEffect(() => {
    document.addEventListener('keyup', keyUpHandler)
    return () => {
      document.removeEventListener('keyup', keyUpHandler)
    }
  }, [keyUpHandler])

  const bind = useGesture({
    onDragEnd: dragState => {
      if (dragState.delta[0] > 0) prev()
      if (dragState.delta[0] < 0) next()
    }
  })

  const uniqueImages = uniqBy(images, imageUrl => {
    const urlSplit = imageUrl.split('-')
    return urlSplit[urlSplit.length - 2]
  })

  return (
    <PostingImageContainer ref={ref}>
      <h2>Kuvat</h2>
      {rows.map((row, i) => (
        <ImageRow key={i}>
          {row.map((img, i) => (
            <ImageContainer key={i}>
              <StyledImg
                src={img + '-900x563.jpg'}
                srcSet={buildSrcSet(img)}
                sizes="
                  (max-width: 400px) 100px,
                  (max-width: 600px) 200px,
                  (max-width: 900px) 300px,
                  (max-width: 1200px) 500px,
                  1200px
                "
                alt="Kuva"
                onClick={() => {
                  setModalImage(img)
                  setShowModal(true)
                }}
              />
            </ImageContainer>
          ))}
        </ImageRow>
      ))}
      <StyledModal
        showModal={showModal}
        closeModal={() => setShowModal(false)}
        fullSize
        {...bind()}
      >
        {uniqueImages.length > 1 && <Arrow onClick={prev} />}
        <ModalImage src={modalImage + '-1200x750.jpg'} alt="Kuva" />
        {uniqueImages.length > 1 && <Arrow isRight onClick={next} />}
      </StyledModal>
    </PostingImageContainer>
  )
})

PostingImages.propTypes = {
    images: PropTypes.array
}

export default PostingImages

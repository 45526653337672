import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components/macro'
import { Marker, Popup } from 'react-map-gl'
import { mapPostingToCard } from '../utils/mappings'
import { CardInfo, LinkCard } from './Card'

const sharedMarkerAfterStyle = css`
  position: absolute;
  content: '';
  width: 0px;
  height: 0px;
  border: 10px solid transparent;
  border-top: 10px solid;
`

const MarkerContainerWithMockBorders = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: relative;
  background-color: ${props => props.theme.colorBackgroundPrimary};

  &::after {
    ${sharedMarkerAfterStyle}
    border: 14px solid transparent;
    top: 34px;
    left: 6px;
    border-top: 14px solid;
    border-top-color: ${props => props.theme.colorBackgroundPrimary};
  }
`

const StyledMarker = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  top: 2px;
  left: 2px;
  z-index: 1;
  border: 10px solid;
  border-color: ${props =>
    props.showPopup || props.isHovered
      ? props.theme.colorAccentSecondary
      : props.theme.colorAccentPrimary};

  &:hover {
    border-color: ${props => props.theme.colorAccentSecondary};
    &::after {
      border-top-color: ${props => props.theme.colorAccentSecondary};
    }
  }

  &::after {
    ${sharedMarkerAfterStyle}
    bottom: -27px;
    left: -2px;
    border-top-color: ${props =>
    props.showPopup || props.isHovered
      ? props.theme.colorAccentSecondary
      : props.theme.colorAccentPrimary};
  }
`

const ClusterMarker = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  top: 2px;
  left: 2px;
  z-index: 1;
  background-color: ${props =>
    props.showPopup || props.isHovered
      ? props.theme.colorAccentSecondary
      : props.theme.colorAccentPrimary};
  color: ${props =>
    props.showPopup || props.isHovered ? '#FFF' : props.theme.colorText};
  text-align: center;

  &:hover {
    background-color: ${props => props.theme.colorAccentSecondary};
    color: #fff;
    &::after {
      border-top-color: ${props => props.theme.colorAccentSecondary};
    }
  }

  &::after {
    ${sharedMarkerAfterStyle}
    bottom: -17px;
    left: 8px;
    border-top-color: ${props =>
    props.showPopup || props.isHovered
      ? props.theme.colorAccentSecondary
      : props.theme.colorAccentPrimary};
  }

  > div {
    padding-top: 8px;
    font-weight: 500;
  }
`

const StyledPopup = styled.div`
  width: 280px;
  height: 330px;

  ${CardInfo} {
    padding: 10px;
  }
`

const OverlayedPopup = styled(Popup)`
  z-index: 2;
  position: relative;

  > :nth-child(2) {
    padding: 0;
  }

  button {
    outline: none;
    font-size: 18px;
  }

  .mapboxgl-popup-close-button {
    background-color: ${props =>
    `${props.theme.colorAccentSecondary} !important`};
    color: #fff;
    z-index: 1;
  }
`

const sharedArrowStyle = css`
  position: absolute;
  width: 12px;
  height: 12px;
  border-bottom: 3px solid ${props => props.theme.colorText};
  border-right: 3px solid ${props => props.theme.colorText};
  cursor: pointer;
`

const ArrowRight = styled.div`
  ${sharedArrowStyle};
  right: -20px;
  top: 122px;
  transform: rotate(-45deg);
`

const ArrowLeft = styled.div`
  ${sharedArrowStyle};
  left: -20px;
  top: 122px;
  transform: rotate(135deg);
`

const CloseButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 25px;
  height: 25px;
  font-size: 18px;
  text-align: center;
  background-color: ${props =>
    `${props.theme.colorAccentSecondary} !important`};
  color: #fff;
`

export const PostingOnMap = ({
  posting,
  postingType,
  showPopup,
  setPostingPopupToShow,
  isHovered
}) => {
  const cardInfo = mapPostingToCard(posting, postingType)
  /* the offset values of the marker are the total height of the marker (top)
  and half of its width (left) */
  return (
    <>
      {showPopup && (
        <OverlayedPopup
          latitude={posting.coords[1]}
          longitude={posting.coords[0]}
          offsetTop={-46}
          onClose={() => setPostingPopupToShow(null)}
        >
          <StyledPopup>
            {cardInfo
              ? (
                <LinkCard
                  key={cardInfo.id}
                  cardInfo={cardInfo}
                  type={postingType}
                  linkTo={`/${postingType}/${cardInfo.id}`}
                />
              )
              : null}
          </StyledPopup>
        </OverlayedPopup>
      )}
      <Marker
        latitude={posting.coords[1]}
        longitude={posting.coords[0]}
        offsetLeft={-18}
        offsetTop={-46}
      >
        <MarkerContainerWithMockBorders>
          <StyledMarker
            onClick={() => setPostingPopupToShow(posting)}
            showPopup={showPopup}
            isHovered={isHovered}
          />
        </MarkerContainerWithMockBorders>
      </Marker>
    </>
  )
}

PostingOnMap.propTypes = {
  posting: PropTypes.object,
  postingType: PropTypes.string,
  showPopup: PropTypes.any,
  setPostingPopupToShow: PropTypes.func,
  isHovered: PropTypes.bool
}

export const ClusterOnMap = ({
  postings,
  postingType,
  popupToShow,
  setPostingPopupToShow,
  hoveredPosting
}) => {
  const popupPosting = popupToShow
    ? postings.find(posting => posting.id === popupToShow.id)
    : null
  const oneOfThePostingsIsHovered = hoveredPosting
    ? postings.some(posting => posting.id === hoveredPosting.id)
    : false

  const showNextPopup = () => {
    const currentIndex = postings.indexOf(popupPosting)
    const showIndex = currentIndex >= postings.length - 1 ? 0 : currentIndex + 1
    setPostingPopupToShow(postings[showIndex])
  }

  const showPrevPopup = () => {
    const currentIndex = postings.indexOf(popupPosting)
    const showIndex =
      currentIndex === 0 ? postings.length - 1 : currentIndex - 1
    setPostingPopupToShow(postings[showIndex])
  }

  /* the offset values of the marker are the total height of the marker (top)
  and half of its width (left) */
  return (
    <>
      {popupPosting && (
        <OverlayedPopup
          latitude={popupPosting.coords[1]}
          longitude={popupPosting.coords[0]}
          offsetTop={-46}
          closeButton={false}
        >
          <StyledPopup>
            <ArrowLeft onClick={showPrevPopup} />
            <LinkCard
              cardInfo={mapPostingToCard(popupPosting, postingType)}
              type={postingType}
              linkTo={`/${postingType}/${popupPosting.id}`}
            />
            <CloseButton onClick={() => setPostingPopupToShow(null)}>
              x
            </CloseButton>
            <ArrowRight onClick={showNextPopup} />
          </StyledPopup>
        </OverlayedPopup>
      )}
      <Marker
        latitude={postings[0].coords[1]}
        longitude={postings[0].coords[0]}
        offsetLeft={-18}
        offsetTop={-46}
      >
        <MarkerContainerWithMockBorders>
          <ClusterMarker
            onClick={() => setPostingPopupToShow(postings[0])}
            showPopup={popupPosting}
            isHovered={oneOfThePostingsIsHovered}
          >
            <div>{postings.length}</div>
          </ClusterMarker>
        </MarkerContainerWithMockBorders>
      </Marker>
    </>
  )
}

ClusterOnMap.propTypes = {
  postings: PropTypes.array,
  postingType: PropTypes.string,
  popupToShow: PropTypes.any,
  setPostingPopupToShow: PropTypes.any,
  hoveredPosting: PropTypes.any
}

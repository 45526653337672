import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'
import { media } from '../styles'

const DropdownButton = styled.div`
  width: 100%;
  padding-right: 25px;
  font-size: ${props => props.theme.fontSize};
  color: ${props => props.theme.colorText};
  display: flex;
  justify-content: space-between;

  > span {
    margin-right: ${props => (props.unapprovedCount > 0 ? '30px' : 'unset')};
  }
`

const StyledDropdown = styled.div`
  width: 100%;
  position: relative;
  outline: none;
  cursor: pointer;

  ${media.desktopUp`
    width: auto;
  `}
`

const Options = styled.div`
  width: 200px;
  background-color: ${props => props.theme.colorBackgroundPrimary};

  > *:not(:first-child) {
    display: block;
  }

  ${media.desktopUp`
    position: absolute;
    top: 30px;
    right: 0;
    border: 1px solid ${props => props.theme.colorBorder};
  `}
`

const LinkContainer = styled.div`
  padding: 30px 20px 0 20px;

  ${media.desktopUp`
    padding: 15px 20px;

    &:not(:first-child) {
      border-top: 1px solid ${props => props.theme.colorBorder};
    }
  `}

  > a {
    text-decoration: none;
    color: ${props => props.theme.colorText};
  }
`

const Arrow = styled.div`
  position: absolute;
  top: ${props => (props.isOpen ? '25px' : '22px')};
  right: 3px;
  width: 7px;
  height: 7px;
  border-bottom: 2px solid ${props => props.theme.colorText};
  border-right: 2px solid ${props => props.theme.colorText};
  transform: ${props => (props.isOpen ? 'rotate(-135deg)' : 'rotate(45deg)')};

  ${media.desktopUp`
    top: ${props => (props.isOpen ? '5px' : '2px')};
  `}
`

const EllipseWithNumber = styled.div`
  position: absolute;
  display: inline-block;
  width: 22px;
  height: 22px;
  background-color: ${props => props.theme.colorDanger};
  border-radius: 50%;
  color: #fff;
  text-align: center;
  line-height: 1.9;
  font-size: ${props => props.theme.fontSizeMobile};
  right: ${props => (props.isButton ? '30px' : '20px')};
  bottom: ${props => (props.isButton ? 'unset' : '18px')};

  ${media.desktopUp`
    right: 25px;
    bottom: ${props => (props.isButton ? '-1px' : '14px')};
  `}
`

const NavDropdown = ({ heading, links, unapprovedCount, onClick }) => {
  const [isOpen, setIsOpen] = useState(false)
  const dropdownRef = useRef(null)

  const handleClick = e => {
    if (dropdownRef.current.contains(e.target)) return
    setIsOpen(false)
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClick)

    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
  }, [])

  return (
    <StyledDropdown ref={dropdownRef}>
      <DropdownButton
        onClick={() => setIsOpen(!isOpen)}
        isOpen={isOpen}
        unapprovedCount={unapprovedCount}
      >
        <span>{heading}</span>
        {unapprovedCount && (
          <EllipseWithNumber isButton>{unapprovedCount}</EllipseWithNumber>
        )}
        <Arrow isOpen={isOpen} />
      </DropdownButton>
      {isOpen && links && (
        <Options>
          {links.map((link, i) => (
            <LinkContainer key={i}>
              <Link
                to={link.linkTo}
                onClick={() => {
                  setIsOpen(false)
                  onClick()
                }}
              >
                {link.displayName}
              </Link>
              {link.number && (
                <EllipseWithNumber>{link.number}</EllipseWithNumber>
              )}
            </LinkContainer>
          ))}
        </Options>
      )}
    </StyledDropdown>
  )
}

NavDropdown.propTypes = {
  heading: PropTypes.string,
  links: PropTypes.array,
  unapprovedCount: PropTypes.string,
  onClick: PropTypes.func
}

export default NavDropdown

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

const StyledHamburgerMenu = styled.button`
  background-color: transparent;
  border: none;
  outline: none;
  vertical-align: middle;
  position: relative;
  width: 30px;
  height: 25px;
  padding: 0;
  z-index: 1;

  > div {
    width: 25px;
    height: 3px;
    border-radius: 2px;
    background-color: #ddd;
    margin-bottom: 5px;
    position: absolute;
    left: 0;
    transition: 250ms ease-in-out;
  }

  > div:first-child {
    top: ${props => (props.isOpen ? '10px' : '0')};
    transform: ${props => (props.isOpen ? 'rotate(45deg)' : 'none')};
  }

  > div:nth-child(2) {
    top: 10px;
    width: ${props => (props.isOpen ? '0' : '25px')};
    opacity: ${props => (props.isOpen ? '0' : '1')};
  }

  > div:nth-child(3) {
    top: ${props => (props.isOpen ? '10px' : '20px')};
    transform: ${props => (props.isOpen ? 'rotate(-45deg)' : 'none')};
  }
`

const HamburgerMenu = ({ onClick, isOpen, className }) => (
  <StyledHamburgerMenu onClick={onClick} isOpen={isOpen} className={className}>
    <div />
    <div />
    <div />
  </StyledHamburgerMenu>
)

HamburgerMenu.propTypes = {
    onClick: PropTypes.func,
    isOpen: PropTypes.bool,
    className: PropTypes.string
}

export default HamburgerMenu

import { SET_USER, SET_EMPTY_USER } from '../constants'
import { isEmpty, get, isUndefined } from 'lodash-es'

export const setUser = (attributes, isAuthenticated) => async dispatch => {
  return dispatch({
    type: SET_USER,
    payload: {
      attributes,
      isAuthenticated: !isUndefined(isAuthenticated)
        ? isAuthenticated
        : !isEmpty(attributes),
      isAdmin: get(attributes, 'groups', []).includes('admin')
    }
  })
}
export const setEmptyUser = () => async dispatch => {
  return dispatch({
    type: SET_EMPTY_USER,
    payload: {
      attributes: undefined,
      isAuthenticated: true,
      isAdmin: false
    }
  })
}

import React from 'react'
import PropTypes from 'prop-types'
import { render } from 'react-dom'
import { ThemeProvider } from 'styled-components'
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from 'react-router-dom'
import * as serviceWorker from './serviceWorker'
import { GlobalStyle, theme } from './styles'
import MapListView from './views/MapListView'
import PostingDetails from './views/PostingDetails'
import CreateAccount from './views/CreateAccount'
import ConfirmAccount from './views/ConfirmAccount'
import ModifyAccount from './views/ModifyAccount'
import ResetAccountPassword from './views/ResetAccountPassword'
import Login from './views/Login'
import AuthGuard from './views/AuthGuard'
import AddPosting from './views/AddPosting'
import UnapprovedPostings from './views/UnapprovedPostings'
import OwnPostings from './views/OwnPostings'
import EditPosting from './views/EditPosting'
import OwnPostingsFetcher from './views/OwnPostingsFetcher'
import UnapprovedPostingsFetcher from './views/UnapprovedPostingsFetcher'
import RejectedPostingsFetcher from './views/RejectedPostingsFetcher'
import TiedepuistoRentalsView from './views/TiedepuistoRentalsView'
import { StateProvider } from './state'
import { postingTypes } from './utils/mappings'
import { initS3Client } from './utils/awsUpload'
import Amplify from '@aws-amplify/core'
import { useStateValue } from './state'
import { isEmpty } from 'lodash-es'
import * as Sentry from '@sentry/browser'
import ReactGA from 'react-ga'
import logger from './logger'
import 'setimmediate'

  ; (function () {
    const amplifyConfig = {
      Auth: {
        identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
        region: process.env.REACT_APP_COGNITO_REGION,
        userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_COGNITO_USER_POOL_CLIENT_ID
      }
    }
    logger.debug('Configuring Amplify with values: ', amplifyConfig)
    const amplifyConfigResult = Amplify.configure(amplifyConfig)
    logger.debug('Amplify configuration results fur thee: ', amplifyConfigResult)
  })()

Sentry.init({
  dsn: 'https://d6319568df3342628e989d4fac3ce4f7@sentry.io/1774091',
  environment: process.env.SENTRY_ENVIRONMENT
})

ReactGA.initialize('UA-88938314-3')
ReactGA.pageview(window.location.pathname + window.location.search)

initS3Client()

const PrivateRoute = ({
  component: Component,
  isAdminRoute,
  path,
  ...rest
}) => {
  const [{ user }] = useStateValue()
  return (
    <Route
      {...rest}
      render={props => {
        // User has not been fetched yet. Skip and wait for a state change.
        if (!user.isAuthenticated) return
        // If this is an admin route kick non admins out
        if (isAdminRoute && !user.isAdmin) return <Redirect to="/" />
        // User has been fetched. If the attributes are empty it means that
        // the user is not signed in and we should not let them use this route.
        return !isEmpty(user.attributes)
          ? (
            <Component {...props} />
          )
          : (
            <Redirect to={`/login?redirectTo=${path}`} />
          )
      }}
    />
  )
}

PrivateRoute.propTypes = {
  component: PropTypes.func,
  isAdminRoute: PropTypes.bool,
  path: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
}

const App = () => {
  const postingTypeUrls = postingTypes.map(
    postingType => '/' + postingType.type
  )
  return (
    <StateProvider>
      <ThemeProvider theme={theme}>
        <AuthGuard>
          <OwnPostingsFetcher />
          <UnapprovedPostingsFetcher />
          <RejectedPostingsFetcher />
          <GlobalStyle />
          <Router>
            <Switch>
              <Route path={postingTypeUrls} exact component={MapListView} />
              <Route
                path="/rental/ext/tiedepuisto"
                exact
                component={TiedepuistoRentalsView}
              />
              <Route
                path={postingTypeUrls.map(url => url + '/:id')}
                exact
                component={PostingDetails}
              />
              <Route path="/login" exact component={Login} />
              <Route path="/create-account" exact component={CreateAccount} />
              <Route path="/confirm-account" exact component={ConfirmAccount} />
              <PrivateRoute
                path="/modify-account"
                exact
                component={ModifyAccount}
              />
              <Route
                path="/reset-account-password"
                exact
                component={ResetAccountPassword}
              />
              <PrivateRoute path="/post" exact component={AddPosting} />
              <PrivateRoute
                path="/unapproved"
                exact
                isAdminRoute
                component={UnapprovedPostings}
              />
              <PrivateRoute
                path="/rejected"
                exact
                isAdminRoute
                component={UnapprovedPostings}
              />
              <PrivateRoute
                path={postingTypeUrls.map(url => '/own-postings' + url)}
                exact
                component={OwnPostings}
              />
              <PrivateRoute
                path={postingTypeUrls.map(url => '/edit' + url + '/:id')}
                exact
                component={EditPosting}
              />
              <Redirect exact from="/" to="/rental" />
            </Switch>
          </Router>
        </AuthGuard>
      </ThemeProvider>
    </StateProvider>
  )
}

render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components/macro'
import {
  scaleDataUrlToSizeAsBlob,
  getExifOrientation,
  transformFileByOrientation
} from '../utils/image'
import {
  range,
  startsWith,
  endsWith,
  isEmpty,
  uniq,
  get,
  flatten
} from 'lodash-es'
import { imageSizes } from '../utils/constants'
import { S3_BASE_URL, uploadToS3, getFilenameAndExt } from '../utils/awsUpload'
import { media } from '../styles'
import Modal, { ModalMain } from './Modal'
import Button from './Button'
import ImageCropper from './ImageCropper'

const StyledImageInput = styled.div`
  display: flex;
  flex-direction: column;

  ${media.tabletLandscapeUp`
    flex-direction: row;

    > :first-child {
      flex: 1;
      margin-right: 40px;
    }
    > :nth-child(2) {
      flex: 2;
    }
  `}
`

const sharedButtonStyle = css`
  opacity: ${props => (props.isDisabled ? 0.5 : 1)};
  cursor: ${props => (props.isDisabled ? 'default' : 'pointer')};
`

const StyledLabel = styled.label`
  ${sharedButtonStyle}
  box-sizing: border-box;
  width: 100%;
  padding: 10px;
  display: block;
  background-color: ${props => props.theme.colorAccentSecondary};
  color: #fff;
  text-align: center;

  > input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
`

const ThumbsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 140px 140px 140px;
  grid-gap: 15px;
  padding-bottom: 15px;

  ${media.tabletPortraitUp`
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 140px 140px;
  `}

  > * {
    background-color: ${props => props.theme.colorBackgroundSecondary};
  }
`

const Texts = styled.div`
  > :first-child {
    font-size: ${props => props.theme.fontSize};
    font-weight: bold;
    padding-bottom: 10px;
  }

  > :nth-child(2) {
    font-size: ${props => props.theme.fontSizeSmall};
    padding-bottom: 10px;
  }
`

const ImageContainer = styled.div`
  position: relative;
`

const sharedImageToolStyle = css`
  position: absolute;
  color: #fff;
  text-align: center;
`

const sharedCoverImageStyle = css`
  bottom: 0;
  width: 100%;
  font-size: ${props => props.theme.fontSizeSmall};
  padding: 4px 0;
`

const SetCoverImage = styled.span`
  ${sharedImageToolStyle}
  ${sharedCoverImageStyle}
  background-color: ${props => props.theme.colorAccentSecondary};
  cursor: pointer;
`

const CoverImage = styled.span`
  ${sharedImageToolStyle}
  ${sharedCoverImageStyle}
  background-color: ${props => props.theme.colorAccentPrimary};
  user-select: none;
`

const DeleteImage = styled.span`
  ${sharedImageToolStyle}
  top: 0;
  right: 0;
  font-size: ${props => props.theme.fontSizeSmall};
  cursor: pointer;
  padding: 4px 8px;
  background-color: ${props =>
    props.isCoverImage
      ? props.theme.colorAccentPrimary
      : props.theme.colorAccentSecondary};
`

const StyledImage = styled.img`
  outline: ${props =>
    props.isCoverImage
      ? `2px solid ${props.theme.colorAccentPrimary}`
      : 'none'};
`

const ImageCropperContainer = styled.div`
  height: 400px;
  background-color: #000;
  position: relative;
`

const CropperContainer = styled.div`
  margin: 0 auto 30px;
  height: 400px;
  overflow: hidden;
`

const StyledModal = styled(Modal)`
  > ${ModalMain} {
    text-align: center;
    max-height: 100%;

    > p {
      text-align: left;
      margin: 0 0 30px;
    }

    ${media.tabletLandscapeUp`
      max-width: 800px;
      max-height: 90%;
    `}

    ${media.desktopUp`
      max-width: 1200px;
    `}
  }
`

const StyledButton = styled(Button)`
  ${sharedButtonStyle}
  margin-top: 10px;
  padding: 10px;
`

const readFile = file => {
  return new Promise(resolve => {
    const reader = new FileReader()
    reader.onload = event => {
      if (event.target.readyState !== FileReader.DONE) return
      resolve(event.target.result)
    }
    reader.readAsDataURL(file)
  })
}

const sanitizeFilename = name => {
  return name.replace(/[\\/{}^%`[\]"'<>~#|?,;:=*]/g, '')
}

const scaleAndUploadImages = async file => {
  const { filenameWithoutExt } = getFilenameAndExt(file.name)
  const key = `${sanitizeFilename(filenameWithoutExt)}-${Date.now()}`
  file.key = `${S3_BASE_URL}${key}`
  file.urls = []

  await Promise.all(
    imageSizes.map(async size => {
      const width = size[0]
      const height = size[1]
      const blob = await scaleDataUrlToSizeAsBlob(
        file.dataUrl,
        file.cropArea.x,
        file.cropArea.y,
        file.cropArea.width,
        file.cropArea.height,
        width,
        height
      )
      const filename = `${key}-${width}x${height}.jpg`

      return uploadToS3({
        filename,
        body: blob,
        contentType: 'image/jpeg'
      })
        .then(() => file.urls.push(`${S3_BASE_URL}${filename}`))
        .catch(err => console.error(err))
    })
  )
  return file
}

const ImageThumb = ({
  url,
  index,
  isCoverImage,
  setCoverImage,
  deleteImage
}) => (
  <ImageContainer>
    <StyledImage src={url} alt="thumb" isCoverImage={isCoverImage} />
    {isCoverImage
      ? (
        <CoverImage>Kansikuva</CoverImage>
      )
      : (
        <SetCoverImage onClick={() => setCoverImage(index)}>
          Aseta kansikuvaksi
        </SetCoverImage>
      )}
    <DeleteImage isCoverImage={isCoverImage} onClick={() => deleteImage(index)}>
      x
    </DeleteImage>
  </ImageContainer>
)

ImageThumb.propTypes = {
  url: PropTypes.string,
  index: PropTypes.number,
  isCoverImage: PropTypes.bool,
  setCoverImage: PropTypes.func,
  deleteImage: PropTypes.func
}

const CropperImageThumb = ({ file, index, setCropperImage }) => {
  return (
    <ImageContainer>
      <StyledImage
        src={file}
        alt="thumb"
        onClick={() => setCropperImage(index)}
      />
    </ImageContainer>
  )
}

CropperImageThumb.propTypes = {
  file: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  index: PropTypes.number,
  setCropperImage: PropTypes.func
}

const getFileNameWithoutSizeExt = filename => {
  const { filenameWithoutExt } = getFilenameAndExt(filename)
  const urlSplit = filenameWithoutExt.split('-')
  const sizeExt = urlSplit[urlSplit.length - 1]
  return filenameWithoutExt.substr(
    0,
    filenameWithoutExt.length - sizeExt.length - 1
  )
}

const ImageInput = ({
  label,
  maxImages,
  isRequired,
  images,
  coverImage,
  onImagesChange
}) => {
  const emptyThumbFn = i => <div key={i} />
  const [isLoading, setIsLoading] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [showModal, setShowModal] = useState(false)

  const getInitialImageKeys = () => {
    if (images && images.length) {
      const imagesWithoutSizeExt = images.map(x => getFileNameWithoutSizeExt(x))
      return uniq(imagesWithoutSizeExt)
    }
    return []
  }

  const sizeExt = '-336x210.jpg'

  const [files, setFiles] = useState([])

  useEffect(() => {
    const getInitialFiles = async () => {
      const fetchImg = async key => {
        // Add query param because Chrome & S3 don't work nicely together
        // See: https://serverfault.com/questions/856904/chrome-s3-cloudfront-no-access-control-allow-origin-header-on-initial-xhr-req/856948#856948
        const response = await fetch(key + sizeExt + '?x-bust-browser-cache')
        const blob = await response.blob()
        const dataUrl = await readFile(blob)
        return {
          key,
          urls: images.filter(image => startsWith(image, key)),
          dataUrl,
          isCroppable: false
        }
      }
      const fetchedFiles = await Promise.all(
        getInitialImageKeys().map(fetchImg)
      )
      setFiles(fetchedFiles)
      setCoverImageIndex(getInitialCoverImageIndex(fetchedFiles))
    }
    if (images.length) getInitialFiles() // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getInitialCoverImageIndex = files => {
    if (files && files.length) {
      const index = files
        .map(file => file.key)
        .indexOf((files.find(file => file.key === coverImage) || {}).key)
      return index >= 0 ? index : 0
    }
    return 0
  }

  const [coverImageIndex, setCoverImageIndex] = useState(0)

  const isDisabled =
    isUploading || isLoading || images.length === maxImages * imageSizes.length

  const hasCroppableFiles = files.some(file => file.isCroppable)

  const fileInputRef = useRef(null)

  useEffect(() => {
    onImagesChange({
      images: flatten(files.map(file => file.urls)),
      coverImage: files.map(file => file.key)[coverImageIndex]
    }) // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files, coverImageIndex])

  const deleteImage = index => {
    setFiles(files.filter((file, i) => i !== index))
    if (index === coverImageIndex) setCoverImageIndex(0)
  }

  const uploadImages = async () => {
    if (isEmpty(files)) return
    setIsUploading(true)

    const newFiles = await Promise.all(
      files.filter(file => file.isCroppable).map(scaleAndUploadImages)
    )

    setFiles([...files.filter(file => !file.isCroppable), ...newFiles])

    setIsUploading(false)
    closeModal()
  }

  const makePreviewsAndShowModal = async e => {
    if (!e.target.files || !e.target.files[0]) return
    setIsLoading(true)

    const chosenFiles = Array.from(e.target.files).splice(0, maxImages)
    const orientation = await Promise.all(chosenFiles.map(getExifOrientation))
    const dataUrls = await Promise.all(chosenFiles.map(readFile))
    const mappedFiles = chosenFiles.map((file, i) => ({
      name: file.name,
      dataUrl: dataUrls[i],
      orientation: orientation[i],
      isCroppable: true
    }))
    const orientedFiles = await Promise.all(
      mappedFiles.map(transformFileByOrientation)
    )

    setFiles([...files, ...orientedFiles].splice(0, maxImages))

    setShowModal(true)
    setIsLoading(false)
    fileInputRef.current.value = null
  }

  const saveCropArea = async (cropData, fileIndex) => {
    const file = files[fileIndex]
    const thumb = await scaleDataUrlToSizeAsBlob(
      file.dataUrl,
      cropData.cropArea.x,
      cropData.cropArea.y,
      cropData.cropArea.width,
      cropData.cropArea.height,
      336,
      210,
      true
    )
    const updatedFiles = files.slice(0)
    updatedFiles[fileIndex] = { ...file, ...cropData, thumb }
    setFiles(updatedFiles)
  }

  const closeModal = () => {
    setShowModal(false)
  }

  const handleCloseModal = () => {
    if (!showModal) return
    // Remove files that have not been uploaded to S3
    if (Array.isArray(files)) setFiles(files.filter(file => !isEmpty(file.key)))
    closeModal()
  }

  // Croppers are all rendered and the correct cropper
  // is scrolled into view onClick of a CropperImageThumb.
  const cropperContainerRef = useRef(null)
  const scrollCropperIntoView = index => {
    cropperContainerRef.current.scrollTop = index * 400
  }

  return (
    <StyledImageInput>
      <Texts>
        <div>{label + (isRequired ? '*' : '')}</div>
        <div>
          {isRequired ? `1-${maxImages} kuvaa` : `0-${maxImages} kuvaa`}
        </div>
      </Texts>
      <div>
        <ThumbsContainer>
          {(images || [])
            .filter(url => endsWith(url, sizeExt))
            .map((url, i) => (
              <ImageThumb
                key={i}
                index={i}
                url={url}
                isCoverImage={coverImageIndex === i}
                setCoverImage={setCoverImageIndex}
                deleteImage={deleteImage}
              />
            ))}
          {range(files.length, maxImages).map(emptyThumbFn)}
        </ThumbsContainer>
        <StyledLabel isDisabled={!isUploading && isDisabled}>
          <span>{isLoading ? 'Ladataan...' : 'Lataa kuvia'}</span>
          <input
            type="file"
            accept="image/jpeg,image/png"
            id="image"
            name="image"
            multiple
            ref={fileInputRef}
            disabled={isDisabled}
            onChange={makePreviewsAndShowModal}
          />
        </StyledLabel>
        <StyledButton
          isSecondary
          isFullWidth
          disabled={files.length < 1}
          isDisabled={files.length < 1}
          onClick={() => setShowModal(true)}
        >
          Rajaa kuvia
        </StyledButton>

        <StyledModal showModal={showModal} closeModal={handleCloseModal}>
          {hasCroppableFiles
            ? (
              <p>Voit rajata kuvat ennen lähettämistä.</p>
            )
            : (
              <p>Kaikki kuvat on jo rajattu oikeaan kuvasuhteeseen.</p>
            )}
          <ThumbsContainer>
            {Array.isArray(files) &&
              files.map((file, i) => (
                <CropperImageThumb
                  key={i}
                  index={i}
                  file={file.thumb}
                  setCropperImage={scrollCropperIntoView}
                />
              ))}
            {range(files.length, maxImages).map(emptyThumbFn)}
          </ThumbsContainer>
          <CropperContainer ref={cropperContainerRef}>
            {Array.isArray(files) &&
              files.map((file, i) => (
                <ImageCropperContainer key={i}>
                  <ImageCropper
                    cropperImage={file.dataUrl}
                    cropXY={get(files, [i, 'cropXY'])}
                    onCrop={cropArea => saveCropArea(cropArea, i)}
                  />
                </ImageCropperContainer>
              ))}
          </CropperContainer>
          {hasCroppableFiles && (
            <Button isPrimary disabled={isUploading} onClick={uploadImages}>
              {isUploading ? 'Lähetetään...' : 'Lähetä'}
            </Button>
          )}
        </StyledModal>
      </div>
    </StyledImageInput>
  )
}

ImageInput.propTypes = {
  label: PropTypes.string,
  maxImages: PropTypes.number,
  isRequired: PropTypes.bool,
  images: PropTypes.array,
  coverImage: PropTypes.any,
  onImagesChange: PropTypes.func
}

export default ImageInput

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import InfoBlock from './InfoBlock'

const Info = styled.div``

const Description = styled.p`
  line-height: 2;
  margin: 0;
  padding-bottom: 60px;
`

const PostingInfo = ({ postingOverview, postingInfoBlocks, postingCreateDate }) => {
  const filteredPostingInfoBlocks = postingInfoBlocks.reduce((acc, block) => {
    const nonEmptyItemsInBlock = block.fields.filter(
      item => typeof item.value !== 'undefined'
    )
    if (nonEmptyItemsInBlock.length) {
      acc.push({ ...block, fields: nonEmptyItemsInBlock })
    }
    return acc
  }, [])

  const dateArr = postingCreateDate ? postingCreateDate.substring(0, 10).split('-') : ''
  const createdAt = dateArr.length === 3 ? `${dateArr[2]}.${dateArr[1]}.${dateArr[0]}` : null

  return (
    <Info>
      {postingOverview && <Description>{postingOverview}</Description>}
      {postingCreateDate && <Description>{`Ilmoitus luotu ${createdAt}`}</Description>}
      {filteredPostingInfoBlocks.map(block => (
        <InfoBlock
          key={block.heading}
          heading={block.heading}
          fields={block.fields}
        />
      ))}
    </Info>
  )
}

PostingInfo.propTypes = {
    postingOverview: PropTypes.string,
    postingInfoBlocks: PropTypes.any,
    postingCreateDate: PropTypes.string
}

export default PostingInfo

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

const Body = styled.div`
  box-sizing: border-box;
  width: 100%;
  max-width: 1200px;
  margin: auto;
  margin-bottom: 40px;
`

const PageBody = ({ children }) => <Body>{children}</Body>

PageBody.propTypes = {
    children: PropTypes.any
}

export default PageBody

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { negate, isEmpty } from 'lodash-es'

const StyledErrorMessage = styled.p`
  font-size: ${props => props.theme.fontSizeSmall};
  color: ${props => props.theme.colorDanger};
  padding: ${props => (props.hasErrorMessage ? '8px 0' : '0')};
  margin: 0;
  min-height: 20px;
  visibility: ${props => (props.hasErrorMessage ? 'visible' : 'hidden')};
`

const ErrorMessage = ({
  codes = [],
  payload = {},
  children,
  showError,
  className
}) => {
  if (!codes) return <StyledErrorMessage className={className} />

  // Displays first error message in payload
  const errorMessages = codes
    .map(field => payload[field])
    .filter(negate(isEmpty))
  return (
    <StyledErrorMessage
      hasErrorMessage={errorMessages.length > 0 || showError}
      className={className}
    >
      {errorMessages[0] || children}
    </StyledErrorMessage>
  )
}

ErrorMessage.propTypes = {
    codes: PropTypes.array,
    payload: PropTypes.object,
    children: PropTypes.any,
    showError: PropTypes.bool,
    className: PropTypes.string
}

export default ErrorMessage

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

const Background = styled.div`
  width: 40px;
  height: 24px;
  border-radius: 20px;
  background-color: ${props =>
    props.isOn ? '#06c05f' : props.theme.colorBackgroundSecondary};
  position: relative;
  transition: 200ms ease-in-out;
  cursor: pointer;
`

const Handle = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  left: ${props => (props.isOn ? '18px' : '2px')};
  background-color: ${props => props.theme.colorBackgroundPrimary};
  transition: 200ms ease-in-out;
`

const Toggle = ({ isOn, onClick }) => (
  <Background isOn={isOn} onClick={onClick}>
    <Handle isOn={isOn} />
  </Background>
)

Toggle.propTypes = {
    isOn: PropTypes.any,
    onClick: PropTypes.func
}

export default Toggle

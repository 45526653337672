import { get, patch, post, del, API_URL } from '../../api'
import {
  SET_OWN_POSTINGS,
  UPDATE_OWN_POSTING,
  DELETE_OWN_POSTING
} from '../constants'
import { camelCase } from 'lodash-es'

export const fetchOwnPostingsByType = postingType => async dispatch => {
  const res = await get(`${API_URL}/user/posting/${postingType}`)
  if (res.status === 200) {
    const newPostings = await res.json()
    return dispatch({
      type: SET_OWN_POSTINGS,
      payload: {
        postingType: camelCase(postingType),
        postings: newPostings
      }
    })
  }
}

export const setPostingIsPublic = (posting, postingType) => async dispatch => {
  const res = await post(`${API_URL}/user/posting/set-is-public`, {
    id: posting.id,
    type: postingType,
    isPublic: posting.isPublic
  })
  if (res.status === 200) {
    return dispatch({
      type: UPDATE_OWN_POSTING,
      payload: {
        postingType: camelCase(postingType),
        posting
      }
    })
  }
}

export const updateOwnPosting = (posting, postingType) => async dispatch => {
  const res = await patch(`${API_URL}/${postingType}/${posting.id}`, posting)
  if (res.status === 200) {
    return dispatch({
      type: UPDATE_OWN_POSTING,
      payload: {
        postingType: camelCase(postingType),
        posting
      }
    })
  }
}

export const deleteOwnPosting = (posting, postingType) => async dispatch => {
  const res = await del(`${API_URL}/${postingType}/${posting.id}`)
  if (res.status === 200) {
    return dispatch({
      type: DELETE_OWN_POSTING,
      payload: {
        postingType: camelCase(postingType),
        posting
      }
    })
  }
}

import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Auth from '@aws-amplify/auth'
import { Link } from 'react-router-dom'
import MainNav from '../components/MainNav'
import FormPage from '../components/FormPage'
import FormContainer from '../components/FormContainer'
import FormLabel from '../components/FormLabel'
import Button from '../components/Button'
import styled from 'styled-components/macro'
import ErrorMessage from '../components/ErrorMessage'
import Footer from '../components/Footer'
import FormValidator, { ERROR_CODE } from '../components/FormValidator'

const LinkContainer = styled.div`
  margin-top: 20px;

  > a {
    text-decoration: none;
    color: ${props => props.theme.colorText};
    font-size: ${props => props.theme.fontSizeSmall};
  }
`

const errorMessages = {
  [ERROR_CODE.UserNotFound]: 'Salasanan nollaus epäonnistui.',
  [ERROR_CODE.UserNotValidated]: 'Salasanaa ei voida nollata, koska käyttäjätunnusta ei ole vahvistettu tai sitä ei ole olemassa.'
}

const ResetAccountPassword = ({ history }) => {
  const [username, setUsername] = useState('')
  const [errorCodes, setErrorCodes] = useState([])
  const [errorPayload, setErrorPayload] = useState({})

  useEffect(() => {
    document.title = 'Business Joensuu -  Vaihda salasana'
  }, [])

  const resetPassword = async () => {
    setErrorCodes([])
    try {
      await Auth.forgotPassword(username)
      history.push('/confirm-account?isChangePassword=true')
    } catch (err) {
      setErrorCodes([err.code])
      console.error(err)
    }
  }

  return (
    <>
      <FormPage>
        <MainNav history={history} />
        <FormContainer>
          <FormValidator
            messages={errorMessages}
            codes={errorCodes}
            onChange={setErrorPayload}
          >
            <h1>Salasanan palautus</h1>
            <p>
              Kirjoita tunnuksesi sähköposti, johon lähetämme vahvistuskoodin
              uutta salasanaa varten.
            </p>
            <FormLabel hasErrorMessage>
              Sähköposti
              <input
                type="email"
                onChange={event => setUsername(event.target.value)}
              />
            </FormLabel>
            <ErrorMessage
              codes={[ERROR_CODE.UserNotFound, ERROR_CODE.UserNotValidated]}
              payload={errorPayload}
            />
            <Button isPrimary isFullWidth onClick={resetPassword} type="button">
              Lähetä
            </Button>
            <LinkContainer>
              <Link to="/login">Takaisin kirjautumiseen</Link>
            </LinkContainer>
          </FormValidator>
        </FormContainer>
      </FormPage>
      <Footer />
    </>
  )
}

ResetAccountPassword.propTypes = {
    history: PropTypes.object
}

export default ResetAccountPassword

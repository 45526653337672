import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import {
  getQueryArrayByName,
  getQueryParamByName,
  filterByQueryParams
} from '../utils/filters/filterFunctions'
import { postingTypeStrings } from '../utils/constants'
// only import Range directly from /lib/Range to reduce bundle size as we don't need slider
import Range from 'rc-slider/lib/Range'
import 'rc-slider/assets/index.css'
import SubNavDropDown from './SubNavDropDown'
import Checkbox from './Checkbox'
import Button from './Button'
import { media } from '../styles'
import { get, negate, isEmpty, isEqual, cloneDeep } from 'lodash-es'

const StyledDropDownItem = styled.div`
  display: flex;
  align-items: center;

  > * {
    padding: 10px 20px;

    ${media.tabletLandscapeUp`
      padding: 15px 20px;
    `}
  }
`

const CheckboxFilter = ({ items, name, buttonName, history }) => {
  const [isCheckedSet, setIsChecked] = useState(new Set())
  const [displayValue, setDisplayValue] = useState('')

  const getDisplayValue = checkedItems => {
    if (!checkedItems.size) return ''
    if (checkedItems.size > 1) {
      return `${buttonName} \u00B7 ${checkedItems.size}`
    } else {
      return Array.from(checkedItems)[0]
    }
  }

  useEffect(() => {
    const initialIsCheckedSet = new Set(getQueryArrayByName(name, history))
    setIsChecked(initialIsCheckedSet)
    setDisplayValue(getDisplayValue(initialIsCheckedSet)) // eslint-disable-next-line
  }, [history.location.search])

  return (
    <SubNavDropDown
      key={`${name}Filter`}
      buttonName={buttonName}
      selectedDisplayValue={displayValue}
    >
      {items.map(value => (
        <StyledDropDownItem key={`${name}${value}`}>
          <Checkbox
            onChange={event => {
              const newSet = cloneDeep(isCheckedSet)
              event.target.checked ? newSet.add(value) : newSet.delete(value)
              setIsChecked(newSet)
              setDisplayValue(getDisplayValue(newSet))
              filterByQueryParams({ [name]: Array.from(newSet) }, history)
            }}
            isChecked={isCheckedSet.has(value)}
            value={value}
            reverseOrderOnMobile={true}
          />
        </StyledDropDownItem>
      ))}
    </SubNavDropDown>
  )
}

CheckboxFilter.propTypes = {
    items: PropTypes.array,
    name: PropTypes.string,
    buttonName: PropTypes.string,
    history: PropTypes.any
}

const StyledRange = styled.div`
  box-sizing: border-box;
  padding: 20px;

  > .rc-slider {
    width: 200px;
    padding-bottom: 20px;

    > .rc-slider-track {
      background-color: ${props => props.theme.colorAccentPrimary};
    }
    > .rc-slider-rail {
      background-color: ${props => props.theme.colorBorder};
    }
    > .rc-slider-handle {
      border: ${props => `1px solid ${props.theme.colorBorder}`};
      width: 26px;
      height: 26px;
      top: 0;
      margin-left: -13px;
      box-shadow: none;
    }
  }
`

const RangeFilter = ({ items, name, buttonName, unit, history }) => {
  const initialQueryVal = getQueryParamByName(name, history)
    .split(',')
    .filter(negate(isEmpty))
    .map(v => parseInt(v, 10))
  const initialValue = initialQueryVal.length > 0 ? initialQueryVal : items
  const initialDisplayValue = isEqual(items, initialValue)
    ? ''
    : `${initialValue[0]}${unit}-${initialValue[1]}${unit}`

  const min = items[0]
  const max = items[1]

  const [value, setValue] = useState(initialValue)
  const [displayValue, setDisplayValue] = useState(initialDisplayValue)

  useEffect(() => {
    setValue(initialValue)
    setDisplayValue(initialDisplayValue) // eslint-disable-next-line
  }, [history.location.search])

  // The defaultValue can't be used here in Range, because it is only read once.
  // See: https://github.com/react-component/slider/issues/446
  //
  // Instead, value is used, and it gets updated onChange but
  // filtering gets triggered only in onAfterChange.
  // This prevents api spam.
  return (
    <SubNavDropDown
      key={`${name}Filter`}
      buttonName={buttonName}
      selectedDisplayValue={displayValue}
    >
      <StyledRange>
        <Range
          min={min}
          max={max}
          value={value}
          onChange={newValue => setValue(newValue)}
          onAfterChange={newValue => {
            if (isEqual(items, newValue)) {
              filterByQueryParams({ [name]: '' }, history)
              setDisplayValue('')
            } else {
              filterByQueryParams({ [name]: newValue }, history)
              setDisplayValue(`${newValue[0]}${unit}-${newValue[1]}${unit}`)
            }
          }}
        />
        <span>{`${value[0]} ${unit} - ${value[1]} ${unit}`}</span>
      </StyledRange>
    </SubNavDropDown>
  )
}

RangeFilter.propTypes = {
    items: PropTypes.array,
    name: PropTypes.string,
    buttonName: PropTypes.string,
    unit: PropTypes.string,
    history: PropTypes.any
}

const StyledClearButton = styled(Button)`
  padding: 20px 0;
  background-color: transparent;
  color: ${props => props.theme.colorText};
  outline: none;

  ${media.tabletLandscapeUp`
    padding: 0 30px 0 20px;
  `}
`

const ClearButton = ({ history }) => {
  return (
    <StyledClearButton
      onClick={() =>
        history.push({
          pathname: history.location.pathname,
          search: ''
        })
      }
    >
      Tyhjennä
    </StyledClearButton>
  )
}

ClearButton.propTypes = {
    history: PropTypes.shape({
      push: PropTypes.any,
      location: PropTypes.shape({
        pathname: PropTypes.string
    })
  })
}

const filterComponents = {
  municipality: (items, history, i) => (
    <CheckboxFilter
      key={i}
      items={items}
      name="municipality"
      buttonName="Kunta"
      history={history}
    />
  ),
  type: (items, history, i) => (
    <CheckboxFilter
      key={i}
      items={items}
      name="type"
      buttonName="Tilatyyppi"
      history={history}
    />
  ),
  plotType: (items, history, i) => (
    <CheckboxFilter
      key={i}
      items={items}
      name="type"
      buttonName="Kohdetyyppi"
      history={history}
    />
  ),
  suitability: (items, history, i) => (
    <CheckboxFilter
      key={i}
      items={items}
      name="suitability"
      buttonName="Soveltuvuus"
      history={history}
    />
  ),
  areaM2: (items, history, i) => (
    <RangeFilter
      key={i}
      items={items}
      name="areaM2"
      buttonName="Neliöt"
      unit="m2"
      history={history}
    />
  ),
  plotAreaM2: (items, history, i) => (
    <RangeFilter
      key={i}
      items={items}
      name="plotAreaM2"
      buttonName="Tontin neliöt"
      unit="m2"
      history={history}
    />
  ),
  price: (items, history, i) => (
    <RangeFilter
      key={i}
      items={items}
      name="price"
      buttonName="Hinta"
      unit="€"
      history={history}
    />
  ),
  capacity: (items, history, i) => (
    <RangeFilter
      key={i}
      items={items}
      name="capacity"
      buttonName="Kapasiteetti"
      unit="hlö"
      history={history}
    />
  ),
  clear: (_, history, i) => <ClearButton key={i} history={history} />
}

filterComponents.municipality.displayName = 'Municipality'
filterComponents.type.displayName = 'Type'
filterComponents.plotType.displayName = 'Plot type'
filterComponents.suitability.displayName = 'Suitability'
filterComponents.areaM2.displayName = 'Area'
filterComponents.plotAreaM2.displayName = 'Plot area'
filterComponents.price.displayName = 'Price'
filterComponents.capacity.displayName = 'Capacity'
filterComponents.clear.displayName = 'Clear'

const getFiltersByNames = (filterNames, filterItems, history) => {
  return filterNames.map((filterName, i) =>
    filterComponents[filterName](get(filterItems, [filterName], []), history, i)
  )
}

const Filter = ({ postingType, filterItems, history }) => {
  switch (postingType) {
    case postingTypeStrings.RENTAL:
    case postingTypeStrings.FOR_SALE:
      return getFiltersByNames(
        ['municipality', 'type', 'areaM2', 'price', 'clear'],
        filterItems,
        history
      )
    case postingTypeStrings.CONFERENCE_ROOM:
      return getFiltersByNames(
        ['municipality', 'type', 'suitability', 'capacity', 'clear'],
        filterItems,
        history
      )
    case postingTypeStrings.PLOT:
      return getFiltersByNames(
        ['municipality', 'plotType', 'plotAreaM2', 'clear'],
        filterItems,
        history
      )
    default:
      return null
  }
}

Filter.propTypes = {
  postingType: PropTypes.string.isRequired,
  filterItems: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}

export default Filter

export const sanitizeNumber = val => Number(val)

export const sanitizeBoolean = val => {
  let parsed = null
  try {
    parsed = JSON.parse(val)
  } catch (err) {
    console.error(err)
  }

  return parsed
}

export const sanitizeString = val => {
  if (typeof val === 'undefined' || val === null) {
    return ''
  }

  return typeof val === 'string' ? val : String(val)
}

export const sanitizeArrayItems = (itemsSanitizer, val = []) =>
  val.map(itemsSanitizer)

import { useEffect } from 'react'
import { useStateValue } from '../state'
import { fetchUnapprovedPostings } from '../state/actions'

const UnapprovedPostingsFetcher = () => {
  const [{ user }, dispatch] = useStateValue()

  useEffect(() => {
    if (user.isAdmin) dispatch(fetchUnapprovedPostings) // eslint-disable-next-line
  }, [user])
  return null
}

export default UnapprovedPostingsFetcher

import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'
import { media } from '../styles'

const DropdownButton = styled.span`
  color: ${props => props.theme.colorText};
  padding-right: 25px;
  position: relative;

  ${media.tabletPortraitUp`
    margin-top: 0;
  `}
`

const StyledDropdown = styled.div`
  position: relative;
  outline: none;
  cursor: pointer;
`

const Options = styled.div`
  width: 200px;
  background-color: ${props => props.theme.colorBackgroundPrimary};
  position: absolute;
  top: 30px;
  left: 0;
  border: 1px solid ${props => props.theme.colorBorder};
  z-index: 1;

  ${media.tabletPortraitUp`
    left: inherit;
    right: 0;
  `}

  > *:not(:first-child) {
    display: block;
  }
`

const LinkContainer = styled.div`
  padding: 15px 20px;

  &:not(:first-child) {
    border-top: 1px solid ${props => props.theme.colorBorder};
  }

  > a {
    text-decoration: none;
    color: ${props => props.theme.colorText};
  }
`

const Arrow = styled.span`
  position: absolute;
  right: 3px;
  top: ${props => (props.isOpen ? '5px' : '2px')};
  width: 4px;
  height: 4px;
  border-bottom: 2px solid ${props => props.theme.colorText};
  border-right: 2px solid ${props => props.theme.colorText};
  transform: ${props => (props.isOpen ? 'rotate(-135deg)' : 'rotate(45deg)')};

  ${media.tabletPortraitUp`
    width: 7px;
    height: 7px;
    left: inherit;
  `}
`

const OrderByDropdown = ({ heading, links }) => {
  const [isOpen, setIsOpen] = useState(false)
  const dropdownRef = useRef(null)

  const handleClick = e => {
    if (dropdownRef.current.contains(e.target)) return
    setIsOpen(false)
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClick)

    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
  }, [])

  return (
    <StyledDropdown ref={dropdownRef}>
      <DropdownButton onClick={() => setIsOpen(!isOpen)} isOpen={isOpen}>
        {heading}
        <Arrow isOpen={isOpen} />
      </DropdownButton>
      {isOpen && links && (
        <Options>
          {links.map((link, i) => (
            <LinkContainer key={i}>
              <Link to={link.linkTo} onClick={() => setIsOpen(false)}>
                {link.displayName}
              </Link>
            </LinkContainer>
          ))}
        </Options>
      )}
    </StyledDropdown>
  )
}

OrderByDropdown.propTypes = {
    heading: PropTypes.string,
    links: PropTypes.array
}

export default OrderByDropdown

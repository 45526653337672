export const createOption = (displayName, value) => {
  if (!displayName) throw new Error('createOption is missing: displayName')
  if (typeof value === 'undefined') {
    value = displayName
  }
  return {
    displayName,
    value
  }
}

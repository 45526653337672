import Auth from '@aws-amplify/auth'

export const API_URL = process.env.REACT_APP_API_URL

export async function get(url) {
  let options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  }
  let session
  try {
    session = await Auth.currentSession()
    options = {
      ...options,
      headers: {
        ...options.headers,
        Authorization: `JWT ${session.accessToken.jwtToken}`
      }
    }
  } catch (error) {
    // TODO 16.8.2019, vpeurala: Fix this exception swallowing!
    console.log('Error caught in /src/api/index.js:get: ', error)
  }
  return fetch(url, options)
}

export async function post(url, data) {
  let options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  }
  let session
  try {
    session = await Auth.currentSession()
    options = {
      ...options,
      headers: {
        ...options.headers,
        Authorization: `JWT ${session.accessToken.jwtToken}`
      }
    }
  } catch (error) {
    // TODO 16.8.2019, vpeurala: Fix this exception swallowing!
    console.log('Error caught in /src/api/index.js:post: ', error)
  }
  return fetch(url, options)
}

export async function patch(url, data) {
  let options = {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  }
  let session
  try {
    session = await Auth.currentSession()
    options = {
      ...options,
      headers: {
        ...options.headers,
        Authorization: `JWT ${session.accessToken.jwtToken}`
      }
    }
  } catch (error) {
    // TODO 16.8.2019, vpeurala: Fix this exception swallowing!
    console.log('Error caught in /src/api/index.js:patch: ', error)
  }
  return fetch(url, options)
}

export async function del(url, data) {
  let options = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  }
  let session
  try {
    session = await Auth.currentSession()
    options = {
      ...options,
      headers: {
        ...options.headers,
        Authorization: `JWT ${session.accessToken.jwtToken}`
      }
    }
  } catch (error) {
    // TODO 16.8.2019, vpeurala: Fix this exception swallowing!
    console.log('Error caught in /src/api/index.js:del: ', error)
  }
  return fetch(url, options)
}

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import AccountForm from '../components/AccountForm'
import ChangePasswordForm from '../components/ChangePasswordForm'
import MainNav from '../components/MainNav'
import FormPage from '../components/FormPage'
import FormContainer from '../components/FormContainer'
import Footer from '../components/Footer'
import styled from 'styled-components/macro'

const StyledMainNav = styled(MainNav)`
  z-index: 1;
`

const ModifyAccount = ({ history }) => {
  useEffect(() => {
    document.title = 'Business Joensuu - Omat tiedot'
  }, [])

  return (
    <>
      <FormPage>
        <StyledMainNav history={history}>
          <h3>Omat tiedot</h3>
        </StyledMainNav>
        <FormContainer>
          <h1>Muokkaa tietojasi</h1>
          <AccountForm />
        </FormContainer>
        <FormContainer>
          <h1>Vaihda salasana</h1>
          <ChangePasswordForm />
        </FormContainer>
      </FormPage>
      <Footer />
    </>
  )
}

ModifyAccount.propTypes = {
    history: PropTypes.object
}

export default ModifyAccount

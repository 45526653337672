import { keyframes } from 'styled-components/macro'

export const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

export const grow = keyframes`
  0% {
    transform: scale3d(0.98, 0.98, 0.98);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
`

export const moveUp = keyframes`
  0% {
    transform: translate3d(0, 20px, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
`

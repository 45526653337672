import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'
import MainNav from '../components/MainNav'
import FormPage from '../components/FormPage'
import FormContainer from '../components/FormContainer'
import DropdownInput from '../components/DropdownInput'
import PostingForm from '../components/PostingForm'
import Footer from '../components/Footer'
import Toaster, { ToasterContainer, ToasterButton } from '../components/Toaster'
import { postingTypes } from '../utils/mappings'
import { postingInputFields } from '../utils/postingInputFields'
import { post, API_URL } from '../api'
import { setIsSent } from '../state/actions'
import { useStateValue } from '../state'
import OwnPostingsFetcher from "./OwnPostingsFetcher"

const StyledMainNav = styled(MainNav)`
  z-index: 1;
`

const StyledFormContainer = styled(FormContainer)`
  max-width: 1000px;

  &:last-child {
    margin-bottom: 100px;
  }
`

const AddPosting = ({ history }) => {
  const [{ postings }, dispatch] = useStateValue()
  const [postingType, setPostingType] = useState(null)
  const [initialPosting, setInitialPosting] = useState(null)

  useEffect(() => {
    document.title = 'Business Joensuu - Jätä ilmoitus'
  }, [])

  const getFieldWithName = field =>
    postingInputFields(postingType).find(x => x.field === field)

  const sanitizePosting = posting =>
    Object.keys(posting).reduce((acc, key) => {
      const postingField = getFieldWithName(key)
      if (postingField && postingField.input.sanitize) {
        return { ...acc, [key]: postingField.input.sanitize(posting[key]) }
      }
      return { ...acc, [key]: posting[key] }
    }, {})

  const submitPosting = async (e, posting) => {
    const postingWithoutEmptyValues = Object.keys(posting).reduce(
      (acc, key) => {
        if (posting[key] !== '')
          return {
            ...acc,
            [key]: posting[key]
          }
        return acc
      },
      {}
    )

    const sanitizedPosting = sanitizePosting(postingWithoutEmptyValues)
    try {
      const res = await post(`${API_URL}/${postingType}`, sanitizedPosting)

      if (!res.ok) throw res.statusText

      dispatch(setIsSent(true))
      setInitialPosting(null)
      window.scrollTo({ top: 0 })
    } catch (e) {
      console.error(e)
    }
  }

  const initForm = postingType => {
    setPostingType(postingType)
    if (postingType.length) {
      const emptyPosting = postingInputFields(postingType).reduce(
        (acc, current) => {
          acc[current.field] = ''
          return acc
        },
        {}
      )
      setInitialPosting(emptyPosting)
    } else {
      setInitialPosting(null)
    }
  }

  const toastMessage = <span>
    Kiitos ilmoituksestasi! <br/><br/>
    Voit hallinnoida ilmoituksiesi näkyvyyttä &#34;Omat ilmoitukset&#34; -sivulla.
  </span>

  return (
    <>
      <FormPage>
        <StyledMainNav history={history} />
        {postings.isSent
          ? (
            <ToasterContainer>
              <Toaster message={toastMessage}>
                <Link to="/own-postings/rental">
                  <ToasterButton isPrimary>Omat ilmoitukset</ToasterButton>
                </Link>
                { /* TODO: Should be able to add created posting to the context */}
                <OwnPostingsFetcher />
              </Toaster>
            </ToasterContainer>
          )
          : (
            <>
              <StyledFormContainer>
                <h2>Valitse ilmoituksen kategoria</h2>
                <DropdownInput
                  onChange={postingType => initForm(postingType)}
                  options={postingTypes.map(x => ({ value: x.type, ...x }))}
                />
              </StyledFormContainer>
              {initialPosting && (
                <PostingForm
                  initialPosting={initialPosting}
                  postingType={postingType}
                  onSubmit={submitPosting}
                />
              )}
            </>
          )}
      </FormPage>
      <Footer />
    </>
  )
}

AddPosting.propTypes = {
  history: PropTypes.object
}

export default AddPosting

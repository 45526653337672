import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Toggle from './Toggle'
import Modal, { ModalMain } from './Modal'
import Button from './Button'
import styled from 'styled-components/macro'
import { setPostingIsPublic, deleteOwnPosting } from '../state/actions'
import { useStateValue } from '../state'

export const CardControlsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
  margin-top: 10px;
  border-top: 1px solid ${props => props.theme.colorBorder};
`

const CardLinks = styled.div`
  > :first-child {
    margin-right: 20px;
  }
  > span {
    cursor: pointer;
  }
  > a {
    color: #555;
    text-decoration: none;
  }
`

const PublicToggle = styled.div`
  display: flex;
  align-items: center;

  > :first-child {
    margin-right: 10px;
  }
`

const StyledModal = styled(Modal)`
  > ${ModalMain} {
    text-align: center;
    max-width: 300px;

    > p {
      padding-bottom: 20px;
    }
  }
`

const defaultModalData = {
  posting: null
}

const UserTools = ({ posting, postingType, history, className }) => {
  const [, dispatch] = useStateValue()
  const [showModal, setShowModal] = useState(false)
  const [modalData, setModalData] = useState(defaultModalData)
  const [isToggleOn, setIsToggleOn] = useState(posting.isPublic)

  return (
    <div className={className}>
      <StyledModal showModal={showModal} closeModal={() => setShowModal(false)}>
        <p>Haluatko varmasti poistaa ilmoituksen?</p>
        <Button
          isSecondary
          onClick={() => {
            dispatch(deleteOwnPosting(modalData.posting, postingType))
            setShowModal(false)
            history && history.goBack()
          }}
        >
          Poista ilmoitus
        </Button>
      </StyledModal>
      <CardControlsContainer>
        <CardLinks>
          <a href={`/edit/${postingType}/${posting.id}`}>Muokkaa</a>
          <span
            onClick={e => {
              e.preventDefault()
              setModalData({ posting })
              setShowModal(true)
            }}
          >
            Poista
          </span>
        </CardLinks>
        <PublicToggle>
          <div>Julki</div>
          <Toggle
            isOn={isToggleOn}
            onClick={e => {
              e.preventDefault()
              setIsToggleOn(!isToggleOn)
              const newPosting = {
                ...posting,
                isPublic: !isToggleOn
              }
              dispatch(setPostingIsPublic(newPosting, postingType))
            }}
          />
        </PublicToggle>
      </CardControlsContainer>
    </div>
  )
}

UserTools.propTypes = {
    posting: PropTypes.object,
    postingType: PropTypes.string,
    history: PropTypes.object,
    className: PropTypes.string
}

export default UserTools

import { SET_CURRENT_POSTING } from '../constants'

const currentPostingReducer = (state, action) => {
  switch (action.type) {
    case SET_CURRENT_POSTING:
      return action.payload
    default:
      return state
  }
}

export default currentPostingReducer

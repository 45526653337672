import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

const StyledPage = styled.div`
  width: 100%;
  min-height: 100%;
  background-color: ${props => props.theme.colorBackgroundSecondary};
  margin: 0;
  padding: 0 0 80px;
`

const FormPage = ({ children }) => <StyledPage>{children}</StyledPage>

FormPage.propTypes = {
    children: PropTypes.any
}

export default FormPage

import { postingTypeStrings } from '../constants'

export const postingTypes = [
  {
    type: postingTypeStrings.RENTAL,
    displayName: 'Vuokrattavat toimitilat'
  },
  {
    type: postingTypeStrings.FOR_SALE,
    displayName: 'Myytävät toimitilat'
  },
  {
    type: postingTypeStrings.CONFERENCE_ROOM,
    displayName: 'Kokoustilat'
  },
  {
    type: postingTypeStrings.PLOT,
    displayName: 'Tontit'
  }
]

import React from 'react'
import PropTypes from 'prop-types'
import Auth from '@aws-amplify/auth'
import { mapUserAttributes } from '../utils/user.js'
import { setEmptyUser, setUser } from '../state/actions'
import { useStateValue } from '../state'
import logger from '../logger'

const AuthGuard = ({ children }) => {
  const [{ user }, dispatch] = useStateValue()

  if (!user.isAuthenticated) {
    Auth.currentAuthenticatedUser({
      bypassCache: true
    })
      .then(currentUser => {
        // User has signed in, update the Store with user attributes.
        dispatch(setUser(mapUserAttributes(currentUser)))
      })
      .catch(err => {
        // User has not signed in. Set empty user to Store
        // so that Private Routes can reject this user.
        logger.error('Error caught', err)
        dispatch(setEmptyUser())
      })
  }
  return <>{children}</>
}

AuthGuard.propTypes = {
  children: PropTypes.any
}

export default AuthGuard

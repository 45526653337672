import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components/macro'
import Button from './Button'
import Toaster from './Toaster'
import { post, API_URL } from '../api'
import FormValidator, {
  ERROR_CODE,
  isValidEmail,
  isValidPhone
} from './FormValidator'
import FormLabel from './FormLabel'
import ErrorMessage from './ErrorMessage'
import { isEmpty } from 'lodash-es'

const StyledContactForm = styled.div`
  align-self: flex-start;

  > :first-child {
    margin-top: 0;
  }

  > * {
    font-size: ${props => props.theme.fontSize};
  }
`

const sharedContactStyle = css`
  background-color: ${props => props.theme.colorBackgroundSecondary};
  padding: 30px;

  > h3 {
    margin-bottom: 30px;
  }
`

const ContactInfo = styled.div`
  ${sharedContactStyle}
  margin-bottom: 20px;
`

const ContactInfoItem = styled.div`
  padding-bottom: 10px;

  &:last-of-type {
    padding-bottom: 30px;
  }
`

const Form = styled.form`
  ${sharedContactStyle}

  > label {
    display: block;
  }

  > p:nth-of-type(3) {
    margin-bottom: 50px;
  }

  > p:last-of-type {
    margin-bottom: 30px;
  }

  > label textarea,
  label input[type='text'] {
    box-sizing: border-box;
    width: 100%;
    display: block;
  }

  > label textarea {
    margin-top: 10px;
    font-size: ${props => props.theme.fontSize};
    border: 2px solid ${props => props.theme.colorBorder};
    height: 100px;
  }
`

const CUSTOM_ERROR_CODE = {
  isRequiredMsg: 'isRequiredMsg',
  isRequiredName: 'isRequiredName'
}

const errorMessages = {
  [CUSTOM_ERROR_CODE.isRequiredMsg]: 'Viesti on pakollinen.',
  [CUSTOM_ERROR_CODE.isRequiredName]: 'Nimi on pakollinen.'
}

const PostingContactForm = ({ posting, location }) => {
  const [message, setMessage] = useState('')
  const [name, setName] = useState('')
  const [phoneOrEmail, setPhoneOrEmail] = useState('')
  const [showToast, setShowToast] = useState(false)
  const [errorCodes, setErrorCodes] = useState([])
  const [errorPayload, setErrorPayload] = useState({})
  const postingType = location.pathname.split('/')[1]

  const isValidForm = () => {
    const newErrorCodes = []
    if (isEmpty(message)) newErrorCodes.push(CUSTOM_ERROR_CODE.isRequiredMsg)
    if (isEmpty(name)) newErrorCodes.push(CUSTOM_ERROR_CODE.isRequiredName)
    if (!isValidEmail(phoneOrEmail) && !isValidPhone(phoneOrEmail))
      newErrorCodes.push(ERROR_CODE.InvalidPhoneOrEmail)
    setErrorCodes(newErrorCodes)
    return newErrorCodes.length < 1
  }

  const sendMessage = async () => {
    if (!isValidForm()) return
    try {
      const res = await post(
        `${API_URL}/posting/send-message/${postingType}/${posting.id}`,
        {
          name,
          phoneOrEmail,
          message
        }
      )
      if (!res.ok) throw res.statusText
      setShowToast(true)
      setTimeout(() => {
        setShowToast(false)
      }, 3000)
    } catch (e) {
      console.error(e)
    }
  }

  // TODO: Add link to terms page
  return (
    <StyledContactForm id="contact">
      <ContactInfo>
        <h3>Kohdetta välittää</h3>
        <ContactInfoItem>{`${posting.userInfo.firstname} ${posting.userInfo.lastname}`}</ContactInfoItem>
        <ContactInfoItem>{posting.userInfo.phone}</ContactInfoItem>
        <ContactInfoItem>{posting.userInfo.email}</ContactInfoItem>
        {posting.userInfo.businessName && (
          <ContactInfoItem>{posting.userInfo.businessName}</ContactInfoItem>
        )}
        {posting.userInfo.businessId && (
          <ContactInfoItem>{posting.userInfo.businessId}</ContactInfoItem>
        )}
      </ContactInfo>
      {showToast
        ? (
          <Toaster message="Viesti lähetetty!" />
        )
        : (
          <FormValidator
            codes={errorCodes}
            messages={errorMessages}
            onChange={setErrorPayload}
          >
            <Form>
              <h3>Ota yhteyttä</h3>
              <FormLabel hasErrorMessage>
                Viesti*
              <textarea onChange={e => setMessage(e.target.value)} />
              </FormLabel>
              <ErrorMessage
                codes={[CUSTOM_ERROR_CODE.isRequiredMsg]}
                payload={errorPayload}
              />
              <FormLabel hasErrorMessage>
                Nimesi*
              <input onChange={e => setName(e.target.value)} type="text" />
              </FormLabel>
              <ErrorMessage
                codes={[CUSTOM_ERROR_CODE.isRequiredName]}
                payload={errorPayload}
              />
              <FormLabel hasErrorMessage>
                Puhelinnumero tai sähköposti*
              <input
                  onChange={e => setPhoneOrEmail(e.target.value)}
                  type="text"
                />
              </FormLabel>
              <ErrorMessage
                codes={[ERROR_CODE.InvalidPhoneOrEmail]}
                payload={errorPayload}
              />
              <p>
                Lähettämällä viestin hyväksyt palvelun{' '}
                <a
                  href="https://www.businessjoensuu.fi/files/business-joensuu-oy-tietosuojaseloste-16012019.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ehdot
              </a>
              </p>
              <Button isPrimary isFullWidth onClick={sendMessage} type="button">
                Lähetä viesti välittäjälle
            </Button>
            </Form>
          </FormValidator>
        )}
    </StyledContactForm>
  )
}

PostingContactForm.propTypes = {
  posting: PropTypes.object,
  location: PropTypes.object
}

export default PostingContactForm

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { media } from '../styles'
import { Link } from 'react-router-dom'

const StyledSubNav = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: ${props => props.theme.subNavHeight};
  position: fixed;
  top: ${props => props.theme.mainNavHeightMobile};
  left: 0;
  background-color: #f7f7f7;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow-x: scroll;

  ${media.tabletLandscapeUp`
    padding: 15px 30px;
    top: ${props => props.theme.mainNavHeight};
    overflow-x: inherit;
    z-index: 1;
  `}

  > * {
    border: 1px solid #ddd;
    flex: 1;
    text-align: center;
    text-decoration: none;

    &:last-child {
      margin-right: 0;
    }

    ${media.tabletLandscapeUp`
      flex: 0;
      margin-right: 10px;
      border: none;
      display: block;
    `}
  }
`

export const SubNavLink = styled(Link)`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props =>
    props.selected ? props.theme.colorBackgroundPrimary : 'transparent'};
  border: ${props =>
    props.selected
      ? `1px solid ${props.theme.colorBackgroundSecondary}`
      : 'none'};
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
  user-select: none;
  font-size: 14px;

  ${media.tabletLandscapeUp`
    height: auto;
    padding: 10px 20px;
  `}
`

const SubNav = React.forwardRef(({ children, className }, ref) => (
  <StyledSubNav className={className} ref={ref}>
    {children}
  </StyledSubNav>
))

SubNav.displayName = 'SubNav'

SubNav.propTypes = {
    children: PropTypes.any,
    className: PropTypes.string
}

export default SubNav

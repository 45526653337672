import React from 'react'
import PropTypes from 'prop-types'
import Logo from '../assets/logo.svg'
import LogoWithJoe from '../assets/logo_withjoe.svg'
import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'
import { media } from '../styles'

export const StyledNavLogo = styled.div`
  width: 75px;
  height: 40px;

  ${media.tabletLandscapeUp`
  width: 170px;
`}

  > a > img {
    &:first-child {
      ${media.tabletLandscapeUp`
      display: none;
    `}
    }
    &:nth-child(2) {
      display: none;

      ${media.tabletLandscapeUp`
      display: block;
    `}
    }
  }
`

const NavLogo = ({ className }) => (
  <StyledNavLogo className={className}>
    <Link to="/">
      <img src={Logo} alt="logo" />
      <img src={LogoWithJoe} alt="logo" />
    </Link>
  </StyledNavLogo>
)

NavLogo.propTypes = {
    className: PropTypes.string
}

export default NavLogo

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import InputWithLabel from './InputWithLabel'
import Button from './Button'
import { inputTypes } from '../utils/constants'
import { media } from '../styles'
import moment from 'moment'
import { isEqual } from 'lodash-es'

const ShowingButtonContainer = styled.div`
  padding-top: 30px;
  display: flex;
  flex-direction: column;

  ${media.tabletLandscapeUp`
    flex-direction: row;
  `}

  > :first-child {
    flex: 1;
    margin-right: 40px;
  }
  > :nth-child(2) {
    flex: 2;
    > * {
      width: 100%;
    }
  }
`

const ShowingListItem = styled.div`
  box-sizing: border-box;
  width: 100%;
  margin-top: 30px;
  padding: 10px;
  background-color: ${props => props.theme.colorBackgroundSecondary};
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:not(:first-of-type) {
    margin-top: 10px;
  }

  > :nth-child(2) {
    cursor: pointer;
  }
`

const Showings = ({ onChange, currentShowings }) => {
  const [date, setDate] = useState(null)
  const [time, setTime] = useState({ startTime: '', endTime: '' })
  return (
    <>
      <h2>Kohteen esittely</h2>
      <InputWithLabel
        inputType={inputTypes.DATE}
        label="Päivämäärä"
        onChange={newDate => setDate(newDate)}
        initialDate={new Date()}
      />
      <InputWithLabel
        inputType={inputTypes.TIME}
        label="Kellonaika"
        onChange={(event, field) => setTime({ ...time, [field]: event.target.value })}
        value={time}
      />
      <ShowingButtonContainer>
        <div />
        <div>
          <Button
            onClick={e => {
              e.preventDefault()
              const startTime = moment(time.startTime, 'HH:mm')
              const endTime = moment(time.endTime, 'HH:mm')
              if (!date) return
              const showing = {
                startTime: date
                  .set({
                    hour: startTime.get('hour'),
                    minute: startTime.get('minute')
                  })
                  .toDate(),
                endTime: date
                  .set({
                    hour: endTime.get('hour'),
                    minute: endTime.get('minute')
                  })
                  .toDate()
              }
              onChange([...currentShowings, showing])
            }}
          >
            Lisää esittely
          </Button>
          {currentShowings &&
            currentShowings.map((item, i) => (
              <ShowingListItem key={i}>
                <span>
                  {`${moment(item.startTime).format('D.M.YY HH:mm')} - ${moment(
                    item.endTime
                  ).format('HH:mm')}`}
                </span>
                <span
                  onClick={() =>
                    onChange(currentShowings.filter(x => !isEqual(x, item)))
                  }
                >
                  Poista
                </span>
              </ShowingListItem>
            ))}
        </div>
      </ShowingButtonContainer>
    </>
  )
}

Showings.propTypes = {
    onChange: PropTypes.func,
    currentShowings: PropTypes.array
}

export default Showings

export const SET_POSTINGS = 'SET_POSTINGS'
export const SET_IS_SENT = 'SET_IS_SENT'

export const SET_OWN_POSTINGS = 'SET_OWN_POSTINGS'
export const UPDATE_OWN_POSTING = 'UPDATE_OWN_POSTING'
export const DELETE_OWN_POSTING = 'DELETE_OWN_POSTING'

export const SET_USER = 'SET_USER'
export const SET_EMPTY_USER = 'SET_EMPTY_USER'

export const SET_UNAPPROVED_POSTINGS = 'SET_UNAPPROVED_POSTINGS'
export const REMOVE_UNAPPROVED_POSTING = 'REMOVE_UNAPPROVED_POSTING'

export const SET_REJECTED_POSTINGS = 'SET_REJECTED_POSTINGS'
export const REMOVE_REJECTED_POSTING = 'REMOVE_REJECTED_POSTING'

export const SET_CURRENT_POSTING = 'SET_CURRENT_POSTING'

export const SET_FILTER_ITEMS = 'SET_FILTER_ITEMS'

export const MUNICIPALITIES = ['Ilomantsi', 'Joensuu', 'Outokumpu', 'Polvijärvi', 'Liperi', 'Kontiolahti', 'Heinävesi', 'Juuka', 'Tohmajärvi', 'Rääkkylä', 'Kitee'].sort()


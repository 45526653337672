import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import InputWithLabel from './InputWithLabel'
import DescriptionInput from './DescriptionInput'
import ImageInput from './ImageInput'
import FormContainer from './FormContainer'
import Button from './Button'
import Showings from './Showings'
import { mapPostingToInfoBlocks } from '../utils/mappings'
import { postingInputFields } from '../utils/postingInputFields'
import ErrorMessage from '../components/ErrorMessage'
import { get, isEmpty, isFunction } from 'lodash-es'
import { media } from '../styles'
import { Link } from 'react-router-dom'

const Block = styled.div`
  padding-bottom: 60px;
`

const SubmitButton = styled(Button)`
  padding: 10px 50px;
`

const CancelButton = styled(Link)`
  font-size: 16px;
  margin-right: 20px;
  cursor: pointer;
  text-decoration: none;
`

const ButtonsContainer = styled.div`
  position: absolute;
  bottom: -60px;
  right: 0;
`

const StyledFormContainer = styled(FormContainer)`
  max-width: 1000px;

  &:last-child {
    margin-bottom: 100px;
  }
`

const StyledErrorNotification = styled.p`
  display: inline-block;
  color: ${props => props.theme.colorDanger};
`

const ErrorMessageContainer = styled.div`
  display: flex;
`

const ErrorMessageSpacer = styled.div`
  flex: 0;

  ${media.tabletLandscapeUp`
    flex: 1;
    display: inline-block;
    margin-right: 40px;
  `}
`

const StyledErrorMessage = styled(ErrorMessage)`
  display: inline-block;
  flex: 2;
`

const PostingForm = ({
  postingType,
  initialPosting,
  onSubmit,
  isEditPosting
}) => {
  const [posting, setPosting] = useState(initialPosting)
  const [errorPayload, setErrorPayload] = useState({})
  const [showValidationErrors, setShowValidationErrors] = useState(false)

  const validateField = (field, value) => {
    if (!showValidationErrors) return
    let newErrorPayload = errorPayload
    const fieldErrors = loopThroughValidators(getFieldWithName(field), value)

    if (isEmpty(fieldErrors)) delete newErrorPayload[field]
    else newErrorPayload[field] = fieldErrors[field]

    setErrorPayload(newErrorPayload)
  }

  const onChangeHandler = (field, value) => {
    setPosting({
      ...posting,
      [field]: value
    })
    validateField(field, value)
  }

  useEffect(() => {
    setPosting(initialPosting)
  }, [initialPosting])

  const getFieldWithName = field =>
    postingInputFields(postingType).find(x => x.field === field)

  const getFieldWithLabel = label =>
    postingInputFields(postingType).find(x => x.displayName === label)

  const onImagesChange = ({ images, coverImage }) => {
    setPosting({ ...posting, images, coverImage })
    validateField('images', images)
  }

  const renderImageBlock = () => {
    const { displayName, input, validation, field } = getFieldWithName('images')
    return (
      <Block>
        <ImageInput
          label={displayName}
          maxImages={input.maxImages}
          isRequired={input.isRequired}
          images={posting.images || []}
          coverImage={posting.coverImage}
          onImagesChange={onImagesChange}
        />
        <ErrorMessageContainer>
          <ErrorMessageSpacer />
          <StyledErrorMessage
            codes={get(validation, 'validators', []).map(
              validator => validator[0]
            )}
            payload={errorPayload[field]}
          />
        </ErrorMessageContainer>
      </Block>
    )
  }

  const renderOverviewBlock = () => {
    const { displayName, input, validation, field } = getFieldWithName(
      'overview'
    )
    return (
      <Block>
        <DescriptionInput
          label={displayName}
          value={posting.overview}
          onChange={e => onChangeHandler('overview', e.target.value)}
          isRequired={input.isRequired}
        />
        <ErrorMessageContainer>
          <ErrorMessageSpacer />
          <StyledErrorMessage
            codes={get(validation, 'validators', []).map(
              validator => validator[0]
            )}
            payload={errorPayload[field]}
          />
        </ErrorMessageContainer>
      </Block>
    )
  }

  const postingInfoBlocks = mapPostingToInfoBlocks(posting, postingType)

  const getTransformedField = (fieldInfo, value) => {
    return isFunction(get(fieldInfo, 'input.transform'))
      ? fieldInfo.input.transform(value)
      : value
  }

  const transformFields = () => {
    let transformedFields = {}

    postingInfoBlocks.forEach(block => {
      block.fields.forEach(field => {
        const fieldInfo = getFieldWithLabel(field.label)
        if (isEmpty(fieldInfo)) return

        const value = getTransformedField(fieldInfo, posting[fieldInfo.field])

        transformedFields[fieldInfo.field] = value
      })
    })
    setPosting({
      ...posting,
      ...transformedFields
    })
  }

  const loopThroughValidators = (fieldInfo, value) => {
    let payload = {}

    get(fieldInfo, 'validation.validators', []).forEach(validator => {
      const field = getTransformedField(fieldInfo, value)
      const errorCode = validator[0]
      const validatorFn = validator[1]
      const errorMsg = validatorFn(field)
        ? fieldInfo.validation.messages[errorCode]
        : ''

      if (isEmpty(errorMsg)) return
      payload[fieldInfo.field] = {
        [errorCode]: errorMsg
      }
    })
    return payload
  }

  const isValidForm = () => {
    let newErrorPayload = {
      ...loopThroughValidators(getFieldWithName('images'), posting.images),
      ...loopThroughValidators(getFieldWithName('overview'), posting.overview)
    }

    postingInfoBlocks.forEach(block => {
      block.fields.forEach(field => {
        const fieldInfo = getFieldWithLabel(field.label)
        if (isEmpty(fieldInfo)) return

        newErrorPayload = {
          ...newErrorPayload,
          ...loopThroughValidators(fieldInfo, posting[fieldInfo.field])
        }
      })
    })
    setErrorPayload(newErrorPayload)
    return isEmpty(newErrorPayload)
  }

  const validateFormAndSubmit = e => {
    setShowValidationErrors(true)
    transformFields()
    if (!isValidForm()) return
    onSubmit(e, posting)
  }

  return (
    <StyledFormContainer
      onSubmit={e => {
        e.preventDefault()
        onSubmit(e, posting)
      }}
    >
      {Object.prototype.hasOwnProperty.call(posting, 'images') && renderImageBlock()}
      {Object.prototype.hasOwnProperty.call(posting, 'overview') && renderOverviewBlock()}
      {!isEmpty(posting) &&
        postingInfoBlocks.map(block => {
          return (
            <Block key={block.heading}>
              <h2>{block.heading}</h2>
              {block.fields.map(field => {
                const fieldInfo = getFieldWithLabel(field.label)
                if (!(fieldInfo && fieldInfo.input)) return null
                return (
                  <div key={field.label}>
                    <InputWithLabel
                      label={fieldInfo.displayName}
                      value={posting[fieldInfo.field]}
                      onChange={val => onChangeHandler(fieldInfo.field, val)}
                      {...fieldInfo.input}
                    />
                    <ErrorMessageContainer>
                      <ErrorMessageSpacer />
                      <StyledErrorMessage
                        codes={get(fieldInfo, 'validation.validators', []).map(
                          validator => validator[0]
                        )}
                        payload={errorPayload[fieldInfo.field]}
                      />
                    </ErrorMessageContainer>
                  </div>
                )
              })}
            </Block>
          )
        })}
      <ButtonsContainer>
        <CancelButton
          to={
            isEditPosting ? `/own-postings/${postingType}` : `/${postingType}`
          }
        >
          Peruuta
        </CancelButton>
        <SubmitButton onClick={validateFormAndSubmit} isPrimary>
          Lähetä
        </SubmitButton>
      </ButtonsContainer>
      {!!getFieldWithName('metadata') && (
        <Showings
          onChange={val => onChangeHandler('metadata', { showings: val })}
          currentShowings={
            posting.metadata && posting.metadata.showings
              ? posting.metadata.showings
              : []
          }
        />
      )}
      {!isEmpty(errorPayload) && (
        <ErrorMessageContainer>
          <ErrorMessageSpacer />
          <StyledErrorNotification>
            Lomakkeessa on virheitä.
          </StyledErrorNotification>
        </ErrorMessageContainer>
      )}
    </StyledFormContainer>
  )
}

PostingForm.propTypes = {
    postingType: PropTypes.string,
    initialPosting: PropTypes.any,
    onSubmit: PropTypes.func,
    isEditPosting: PropTypes.bool
}

export default PostingForm

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { useStateValue } from '../state'
import Auth from '@aws-amplify/auth'
import { setIsSent, setUser } from '../state/actions'
import { Link } from 'react-router-dom'
import HamburgerMenu from './HamburgerMenu'
import NavDropdown from './NavDropdown'
import MobileMenu from './MobileMenu'
import NavLogo, { StyledNavLogo } from './NavLogo'
import { media, sizes } from '../styles'
import { postingTypes } from '../utils/mappings'
import { get, camelCase, isEmpty } from 'lodash-es'

const StyledMainNav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  height: ${props => props.theme.mainNavHeightMobile};
  padding: 10px 20px;
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${props => props.theme.colorBackgroundPrimary};
  z-index: 1;

  ${media.tabletLandscapeUp`
    height: ${props => props.theme.mainNavHeight};
    padding: 15px 30px;
    border: ${props =>
      props.hasBorder ? `1px solid ${props.theme.colorBorder}` : 'none'};
  `}

  ${media.desktopUp`
    z-index: 2;
  `}
`

const StyledHamburgerMenu = styled(HamburgerMenu)`
  ${media.desktopUp`
    display: none;
  `}
`

const StyledNav = styled.nav`
  display: none;
  ${media.desktopUp`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    > * {
      margin-right: 20px;
    }
  `}

  > * {
    cursor: pointer;
  }
`

const NavButton = styled.button`
  border: none;
  padding: 0;
  color: ${props => props.theme.colorText};
  background-color: ${props => props.theme.colorBackgroundPrimary};
  font-size: ${props => props.theme.fontSize};
  cursor: pointer;
  outline: none;

  ${media.desktopUp`
    background-color: ${props => props.theme.colorAccentPrimary};
    color: #fff;
    padding: 15px 30px;
  `}
`

const NavItem = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  font-size: ${props => props.theme.fontSize};

  ${media.desktopUp`
    border: none;
    width: auto;
  `}

  > span {
    width: 100%;
    cursor: pointer;
  }
`

const StyledMobileMenu = styled(MobileMenu)`
  > ${StyledNavLogo} {
    padding-top: 0;
    padding-bottom: 40px;
  }
`

const NavLink = styled(Link)`
  width: 100%;
  color: ${props => props.theme.colorText};
  font-size: ${props => props.theme.fontSize};
  text-decoration: none;
`

const NavLeftSide = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 0;

  > * {
    padding-right: 20px;
  }
`

const ShowOnDesktop = styled.div`
  display: none;
  ${media.desktopUp`
    display: block;
  `}
`

const ShowOnMobile = styled.div`
  display: block;
  width: 100%;
  ${media.desktopUp`
    display: none;
  `}
`

const NavItemNoMargin = styled(NavItem)`
  margin-right: 0;
`

const customNavStyles = {
  customMarginRight: {
    marginRight: "auto"
  },
  customFlexGrow: {
    flexGrow: 1
  }
}

const MainNav = React.forwardRef(
  ({ history, children, className, hasBorder }, ref) => {
    const [
      { user, unapprovedPostings, ownPostings },
      dispatch
    ] = useStateValue()
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const path = get(history, 'location.pathname')

    const navLinks = postingTypes.map(postingType => ({
      linkTo: '/' + postingType.type,
      displayName: postingType.displayName
    }))

    const keys = Object.keys(unapprovedPostings)

    const countUnapprovedPostings = () => {
      let count = 0
      keys.forEach(key => (count += get(unapprovedPostings, key, []).length))
      return count === 0 || !user.isAdmin ? '' : count
    }

    if (user.isAdmin) {
      navLinks.push({
        linkTo: '/unapproved',
        displayName: `Hyväksyttävät`,
        number: countUnapprovedPostings()
      })
    }

    const getOwnPostingsLinks = () =>
      Object.keys(ownPostings)
        .filter(x => !isEmpty(ownPostings[x]))
        .map(postingTypeKey => {
          const postingType = postingTypes.find(
            x => camelCase(x.type) === postingTypeKey
          )
          return {
            linkTo: '/own-postings/' + postingType.type,
            displayName: postingType.displayName
          }
        })

    const isMobileMenuSize = () => window.innerWidth < sizes.desktopUp

    const renderMenuLinks = () => (
      <>
      <div
        className={"customMarginRight"}
        style={customNavStyles.customMarginRight}>
        <NavDropdown
          heading="Ilmoituksen tyyppi"
          links={navLinks}
          unapprovedCount={countUnapprovedPostings()}
          onClick={() => {
            if (isMobileMenuSize()) setIsMenuOpen(!isMenuOpen)
          }}
        />
      </div>
        {!user.attributes
          ? (
            <NavItem>
              <NavLink to={!path ? '/login' : `/login?redirectTo=${path}`}>
                Kirjaudu sisään
            </NavLink>
            </NavItem>
          )
          : (
            <>
              {Object.keys(ownPostings).some(
                key => !isEmpty(ownPostings[key])
              ) && (
                  <NavDropdown
                    heading="Omat ilmoitukset"
                    links={getOwnPostingsLinks()}
                    onClick={() => {
                      if (isMobileMenuSize()) setIsMenuOpen(!isMenuOpen)
                    }}
                  />
                )}
              <NavItem>
                <NavLink to="/modify-account">Omat tiedot</NavLink>
              </NavItem>
              <ShowOnDesktop>
                <NavItem>
                  <span
                    onClick={async () => {
                      await Auth.signOut()
                      dispatch(setUser(undefined))
                      history.push('/')
                    }}
                  >
                    Kirjaudu ulos
                </span>
                </NavItem>
              </ShowOnDesktop>
            </>
          )}
        <NavItemNoMargin>
          <NavLink to="/post" onClick={() => dispatch(setIsSent(false))}>
            <NavButton isPrimary>Jätä ilmoitus</NavButton>
          </NavLink>
        </NavItemNoMargin>
        {user.attributes && (
          <ShowOnMobile>
            <NavItem>
              <span
                onClick={async () => {
                  await Auth.signOut()
                  dispatch(setUser(undefined))
                  history.push('/')
                }}
              >
                Kirjaudu ulos
              </span>
            </NavItem>
          </ShowOnMobile>
        )}
      </>
    )

    return (
      <StyledMainNav className={className} hasBorder={hasBorder} ref={ref}>
        <NavLeftSide>
          <NavLogo />
          {children}
        </NavLeftSide>
        <div
          className={"customFlexGrow"}
          style={customNavStyles.customFlexGrow}>
          <StyledNav>{renderMenuLinks()}</StyledNav>
          <StyledMobileMenu
            isMenuOpen={isMenuOpen}
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            {renderMenuLinks()}
          </StyledMobileMenu>
          <StyledHamburgerMenu
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            isOpen={isMenuOpen}
          />
        </div>
      </StyledMainNav>
    )
  }
)

MainNav.propTypes = {
  history: PropTypes.object,
  children: PropTypes.any,
  className: PropTypes.string,
  hasBorder: PropTypes.bool
}

export default MainNav

import React from 'react'
import styled from 'styled-components/macro'
import { media } from '../styles'
import { fadeIn, grow } from '../styles/animations'

const StyledBasePage = styled.div`
  box-sizing: border-box;
  min-height: 100vh;
  padding: ${props => props.theme.contentSpacingMobile};
  padding-top: ${props =>
    parseInt(props.theme.mainNavHeightMobile) +
    parseInt(props.theme.subNavHeight) +
    parseInt(props.theme.contentSpacingMobile) +
    'px'};
  animation: ${fadeIn} 400ms, ${grow} 400ms;

  ${media.tabletLandscapeUp`
    padding: ${props => props.theme.contentSpacing};
    padding-top: ${props =>
      parseInt(props.theme.mainNavHeight) +
      parseInt(props.theme.subNavHeight) +
      parseInt(props.theme.contentSpacing) +
      'px'};
  `}
`

const BasePage = props => <StyledBasePage {...props} />

export default BasePage

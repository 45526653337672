import {
  SET_UNAPPROVED_POSTINGS,
  REMOVE_UNAPPROVED_POSTING
} from '../constants'

const unapprovedPostingsReducer = (state, action) => {
  switch (action.type) {
    case SET_UNAPPROVED_POSTINGS:
      return action.payload
    case REMOVE_UNAPPROVED_POSTING: {
      const type = action.payload.type
      const id = action.payload.id
      return {
        ...state,
        [type]: state[type].filter(p => p.id !== id)
      }
    }
    default:
      return state
  }
}

export default unapprovedPostingsReducer

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { getFileNameFromUrl } from '../utils/getFileNameFromUrl'

const Block = styled.div`
  padding-bottom: 60px;

  > h2 {
    padding-bottom: 30px;
  }
`

const Item = styled.div`
  display: flex;
  overflow-wrap: break-word;
  word-break: break-word;

  > * {
    flex: 1;
  }

  &:not(:last-child) {
    padding-bottom: 30px;
  }

  > :first-child {
    font-weight: bold;
    color: ${props => props.theme.colorAccentSecondary};
  }
`

const InfoBlock = ({ heading, fields }) => (
  <Block>
    <h2>{heading}</h2>
    {fields.map(field => {
      if (field.label === 'Liite') {
        return (
          <Item key={field.label}>
            <span>{field.label}</span>
            <a href={field.value} target="_blank" rel="noopener noreferrer">
              {getFileNameFromUrl(field.value)}
            </a>
          </Item>
        )
      }
      if (field.label === 'Linkki') {
        return (
          <Item key={field.label}>
            <span>{field.label}</span>
            <a href={field.value} target="_blank" rel="noopener noreferrer">
              {field.value}
            </a>
          </Item>
        )
      }
      return (
        <Item key={field.label}>
          <span>{field.label}</span>
          <span>{field.value}</span>
        </Item>
      )
    })}
  </Block>
)

InfoBlock.propTypes = {
    heading: PropTypes.string,
    fields: PropTypes.array
}

export default InfoBlock

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import AccountForm from '../components/AccountForm'
import MainNav from '../components/MainNav'
import FormPage from '../components/FormPage'
import FormContainer from '../components/FormContainer'
import Footer from '../components/Footer'
import styled from 'styled-components/macro'

const Footnote = styled.p`
  font-size: ${props => props.theme.fontSizeSmall};
  margin-top: 40px;
`

const CreateAccount = ({ history }) => {
  useEffect(() => {
    document.title = 'Business Joensuu -  Luo tunnus'
  }, [])

  // TODO: Add link to terms page
  return (
    <>
      <FormPage>
        <MainNav />
        <FormContainer>
          <h1>Luo tunnus</h1>
          <AccountForm isCreate history={history} />
          <Footnote>
            Pääset hyväksymään rekisteriselosteen ja käyttöehdot tilin luomisen
            jälkeen. Voit tutustua näihin etukäteen{' '}
            <a
              href="https://www.businessjoensuu.fi/files/business-joensuu-oy-tietosuojaseloste-16012019.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              täältä
            </a>
            .
          </Footnote>
        </FormContainer>
      </FormPage>
      <Footer />
    </>
  )
}

CreateAccount.propTypes = {
    history: PropTypes.object
}

export default CreateAccount

import { imageSizes } from './constants'
import { take, takeRight, isNumber, get } from 'lodash-es'
import * as loadImage from 'blueimp-load-image'

export async function scaleDataUrlToSizeAsBlob(
  dataUrl,
  sx,
  sy,
  sWidth,
  sHeight,
  width,
  height,
  asDataURL = false
) {
  return new Promise(resolve => {
    const image = new Image()

    image.onload = () => {
      const canvas = document.createElement('canvas')
      canvas.width = width
      canvas.height = height

      canvas
        .getContext('2d')
        .drawImage(image, sx, sy, sWidth, sHeight, 0, 0, width, height)

      const croppedFileDataUrl = canvas.toDataURL('image/jpeg', 0.7)
      return resolve(
        asDataURL ? croppedFileDataUrl : dataUrlToBlob(croppedFileDataUrl)
      )
    }

    image.src = dataUrl
  })
}

function dataUrlToBlob(dataUrl) {
  const binary = atob(dataUrl.split(',')[1])
  const arr = []
  for (let i = 0; i < binary.length; i++) arr.push(binary.charCodeAt(i))
  return new Blob([new Uint8Array(arr)], { type: 'image/jpeg' })
}

export function getExifOrientation(file) {
  const orientation = 0x0112
  return new Promise(resolve => {
    loadImage.parseMetaData(file, data =>
      resolve(get(data, ['exif', orientation]))
    )
  })
}

export function transformFileByOrientation(file) {
  const hasOrientation = isNumber(file.orientation) && file.orientation > 1
  if (!hasOrientation) return file

  return new Promise(resolve => {
    const image = new Image()

    image.onload = async () => {
      const canvas = document.createElement('canvas')
      const width = image.width
      const height = image.height
      canvas.width = width
      canvas.height = height

      loadImage.transformCoordinates(canvas, { orientation: file.orientation })

      canvas.getContext('2d').drawImage(image, 0, 0)

      const dataUrl = canvas.toDataURL('image/jpeg', 0.8)

      return resolve({ ...file, dataUrl })
    }

    image.src = file.dataUrl
  })
}

export function buildSrcSet(url, amount = 5, isRight = false) {
  const imgSizes = isRight
    ? takeRight(imageSizes, amount)
    : take(imageSizes, amount)
  return imgSizes.reduce((srcSet, size) => {
    const width = size[0]
    const height = size[1]
    const ending = `-${width}x${height}.jpg ${width}w,`
    if (srcSet === '') return `${encodeURI(url)}${ending}`
    return `${srcSet}${encodeURI(url)}${ending}`
  }, '')
}

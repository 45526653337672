import queryString from 'query-string'
import { isString, defaultTo, omitBy, isEmpty } from 'lodash-es'

export const getQueryArrayByName = (name, history) => {
  const query = queryString.parse(history.location.search, {
    arrayFormat: 'comma'
  })
  let existingArray = defaultTo(query[name], [])
  if (!Array.isArray(existingArray) && isString(existingArray))
    existingArray = [existingArray]
  return existingArray
}

export const getQueryParamByName = (name, history) => {
  const query = queryString.parse(history.location.search)
  return defaultTo(query[name], '')
}

export const filterByQueryParams = (params, history) => {
  const query = queryString.parse(history.location.search, {
    arrayFormat: 'comma'
  })
  history.push({
    pathname: history.location.pathname,
    search: buildQueryString(query, params)
  })
}

export const buildQueryString = (query, params = {}) => {
  return queryString.stringify(
    omitBy(
      {
        ...query,
        ...params
      },
      isEmpty
    ),
    { arrayFormat: 'comma' }
  )
}

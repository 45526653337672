import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers"
import { PutObjectCommand, S3Client } from '@aws-sdk/client-s3'

export const S3_BASE_URL =
  'https://s3.eu-north-1.amazonaws.com/' +
  process.env.REACT_APP_UPLOAD_BUCKET +
  '/'

let s3Client
export function initS3Client() {
  s3Client = new S3Client({
    region: process.env.REACT_APP_UPLOAD_REGION,
    credentials: fromCognitoIdentityPool({
      identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
      clientConfig: { region: 'eu-west-1' }
    })
  })
}

export async function uploadToS3({ filename, body, contentType }) {
  if (!s3Client)
    throw new Error('S3 client not initialized. Call initS3Client first.')

  const uploadCommand = new PutObjectCommand({
    Body: body,
    Bucket: process.env.REACT_APP_UPLOAD_BUCKET,
    ContentType: contentType,
    Key: filename
  })

  return s3Client.send(uploadCommand)
}

export const getFilenameAndExt = name => {
  const ext = name.split('.').pop()
  return {
    filenameWithoutExt: name.substring(0, name.length - ext.length - 1),
    ext
  }
}

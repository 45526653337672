import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import MainNav from '../components/MainNav'
import Footer from '../components/Footer'
import SubNav, { SubNavLink } from '../components/SubNav'
import BasePage from '../components/BasePage'
import { useStateValue } from '../state'
import styled from 'styled-components/macro'
import { media } from '../styles'
import UnapprovedListItem from '../components/UnapprovedListItem'
import { mapPostingToCard } from '../utils/mappings'
import { kebabCase, defaultTo } from 'lodash-es'
import {
  fetchUnapprovedPostings,
  fetchRejectedPostings
} from '../state/actions'

const PostingsContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  max-width: 1000px;
  margin: auto;

  ${media.largeDesktopUp`
    max-width: 1200px;
  `}
`

const StyledNoPostingsMsg = styled.p`
  text-align: center;
`

const UnapprovedPostings = ({ location, history }) => {
  const [{ unapprovedPostings, rejectedPostings }, dispatch] = useStateValue()
  const splitUrl = location.pathname.split('/')
  const unapprovedOrRejected = splitUrl[splitUrl.length - 1]
  const isUnapproved = unapprovedOrRejected === 'unapproved'
  const postings = isUnapproved ? unapprovedPostings : rejectedPostings
  const noPostingsMsg = isUnapproved
    ? 'Ei hyväksyttäviä ilmoituksia.'
    : 'Ei hylättyjä ilmoituksia.'

  useEffect(() => {
    if (isUnapproved) {
      dispatch(fetchUnapprovedPostings)
      document.title = 'Business Joensuu -  Hyväksyttävät ilmoitukset'
    } else {
      dispatch(fetchRejectedPostings)
      document.title = 'Business Joensuu -  Hylätyt ilmoitukset'
    } // eslint-disable-next-line
  }, [isUnapproved])

  const keys = Object.keys(postings)

  const renderPostings = () => {
    return keys.map(key => {
      if (!Array.isArray(postings[key])) return null
      return postings[key].map(posting => {
        const postingType = kebabCase(key)
        const cardInfo = mapPostingToCard(posting, postingType)
        return (
          cardInfo && (
            <UnapprovedListItem
              key={`${posting.id}-${Date.now()}`}
              cardInfo={mapPostingToCard(posting, postingType)}
              type={postingType}
              isRejected={posting.isRejected}
              postingMessage={posting.postingMessage}
            />
          )
        )
      })
    })
  }

  if (!postings) return null

  const hasPostings = keys.some(key => defaultTo(postings[key], []).length > 0)

  return (
    <>
      <MainNav history={history}>
        <h3>Hyväksyttävät</h3>
      </MainNav>
      <SubNav>
        <SubNavLink to="/unapproved" selected={isUnapproved}>
          Hyväksyttävät
        </SubNavLink>
        <SubNavLink to="/rejected" selected={!isUnapproved}>
          Hylätyt
        </SubNavLink>
      </SubNav>
      <BasePage>
        <PostingsContainer>
          {hasPostings
            ? (
              renderPostings()
            )
            : (
              <StyledNoPostingsMsg>{noPostingsMsg}</StyledNoPostingsMsg>
            )}
        </PostingsContainer>
      </BasePage>
      <Footer />
    </>
  )
}

UnapprovedPostings.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object
}

export default UnapprovedPostings

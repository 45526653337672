import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import AdminTools from './AdminTools'
import { Link } from 'react-router-dom'
import { buildSrcSet } from '../utils/image'
import { media } from '../styles'
import { get } from 'lodash-es'
import moment from 'moment'

const StyledListItem = styled.div`
  display: flex;
  flex-direction: column;
  text-decoration: none;
  margin-bottom: 30px;
  color: ${props => props.theme.colorHeadings};

  > span {
    margin-top: 10px;
  }

  ${media.tabletLandscapeUp`
    flex-direction: row;
  `}
`

const ItemLink = styled(Link)`
  flex: 1;
  display: flex;
  flex-direction: column;
  text-decoration: none;

  ${media.tabletLandscapeUp`
    width: 100%;
    flex-direction: row;
  `}
`

export const ItemImageContainer = styled.div``

const ItemInfoContainer = styled.div`
  flex: 3;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 10px;

  ${media.tabletLandscapeUp`
    padding-top: 0;
    padding-left: 40px;
  `}
`

const UpperRow = styled.div`
  display: flex;
  flex-direction: column;

  > :first-child {
    padding-bottom: 10px;
  }

  ${media.tabletPortraitUp`
    flex-direction: row;

    > :first-child {
      padding-bottom: 0;
    }
  `}
`

export const ItemInfo = styled.div`
  flex: 1;
  display: flex;
`

const ItemInfoLeftColumn = styled.div`
  flex: 2 1 50%;

  > :first-child {
    font-weight: bold;
    font-size: ${props => props.theme.fontSize};
  }
  > :not(:first-child) {
    font-size: ${props => props.theme.fontSizeSmall};
    color: ${props => props.theme.colorText};
  }
`

const ItemInfoRightColumn = styled.div`
  flex: 1 1 50%;

  > * {
    font-weight: bold;
    font-size: ${props => props.theme.fontSize};
  }

  > :not(:first-child) {
    font-size: ${props => props.theme.fontSizeSmall};
  }
`

const InfoItem = styled.div`
  line-height: 1.5;
`

const RejectionMessage = styled.div`
  box-sizing: border-box;
  max-width: 100%;
  white-space: pre-wrap;
  font-style: italic;
`

const RejectionMessageInfo = styled.div`
  > span {
    font-style: normal;

    &:first-child {
      font-weight: bold;
    }

    &:last-child {
      font-size: ${props => props.theme.fontSizeMobile};
    }
  }
`

const UnapprovedListItem = ({ cardInfo, type, isRejected, postingMessage }) => {
  const renderItemInfoItem = item => {
    return typeof item !== 'undefined'
      ? (
        <InfoItem key={item}>{item}</InfoItem>
      )
      : null
  }

  return (
    <StyledListItem>
      <ItemLink to={`/${type}/${cardInfo.id}`}>
        <ItemImageContainer>
          <img
            src={cardInfo.image + '-432x270.jpg'}
            srcSet={buildSrcSet(cardInfo.image, 2, true)}
            sizes="
              (max-width: 600px) 100px,
              400px
            "
            alt=""
          />
        </ItemImageContainer>
      </ItemLink>
      <ItemInfoContainer>
        <UpperRow>
          <ItemInfo>
            {cardInfo.leftColumnItems && (
              <ItemInfoLeftColumn>
                {cardInfo.leftColumnItems.map(item => renderItemInfoItem(item))}
              </ItemInfoLeftColumn>
            )}
            {cardInfo.rightColumnItems && (
              <ItemInfoRightColumn>
                {cardInfo.rightColumnItems.map(item =>
                  renderItemInfoItem(item)
                )}
              </ItemInfoRightColumn>
            )}
          </ItemInfo>
          <AdminTools
            type={type}
            postingInfo={{
              id: cardInfo.id,
              data: [
                ...get(cardInfo, 'leftColumnItems', []),
                ...get(cardInfo, 'rightColumnItems', [])
              ].join(', ')
            }}
            isRejected={isRejected}
          />
        </UpperRow>
        {postingMessage && (
          <RejectionMessage>
            <p>{get(postingMessage, 'message', '')}</p>
            <RejectionMessageInfo>
              <span>{get(postingMessage, 'userName', '')}</span>
              <span> </span>
              <span>
                {moment(get(postingMessage, 'updatedAt', '')).format(
                  'D.M.YYYY'
                )}
              </span>
            </RejectionMessageInfo>
          </RejectionMessage>
        )}
      </ItemInfoContainer>
    </StyledListItem>
  )
}

UnapprovedListItem.propTypes = {
  cardInfo: PropTypes.object,
  type: PropTypes.string,
  isRejected: PropTypes.bool,
  postingMessage: PropTypes.string
}

export default UnapprovedListItem

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { media } from '../styles'

const StyledToggle = styled.div`
  padding: 15px 20px;
  border-radius: 30px;
  position: fixed;
  bottom: 60px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  background-color: ${props => props.theme.colorBackgroundPrimary};
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  font-size: 16px;
  cursor: pointer;
  z-index: 1;

  ${media.tabletLandscapeUp`
    display: none;
  `}
`

const MobileContentToggle = React.forwardRef(({ children, onClick }, ref) => (
  <StyledToggle onClick={onClick} ref={ref}>
    {children}
  </StyledToggle>
))

MobileContentToggle.displayName = 'MobileContentToggle'

MobileContentToggle.propTypes = {
    children: PropTypes.any,
    onClick: PropTypes.func
}

export default MobileContentToggle

import { get, API_URL } from '../../api'
import { SET_FILTER_ITEMS } from '../constants'

export const fetchFilterItemsByType = postingType => async dispatch => {
  const res = await get(`${API_URL}/posting/filter-items/${postingType}`)
  if (res.status === 200) {
    const filterItems = await res.json()
    return dispatch({
      type: SET_FILTER_ITEMS,
      payload: filterItems
    })
  }
}

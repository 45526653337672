import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import Checkmark from '../assets/checkmark.png'
import { media } from '../styles'

const CheckboxLabel = styled.label`
  width: 100%;
  display: flex;
  flex-direction: ${props =>
    props.reverseOrderOnMobile ? 'row-reverse' : 'row'};
  justify-content: ${props =>
    props.reverseOrderOnMobile ? 'space-between' : 'flex-start'};
  font-size: ${props => props.theme.fontSizeSmall};
  cursor: pointer;

  ${media.tabletLandscapeUp`
    flex-direction: row;
    justify-content: flex-start;
    font-size: ${props => props.theme.fontSize};
  `}
`

const StyledCheckbox = styled.span`
  width: 16px;
  height: 16px;
  background-color: ${props => props.theme.colorBackgroundSecondary};
  margin-right: 15px;

  > img {
    display: none;
    fill: #fff;
    width: 10px;
    height: 10px;
    margin: 0 auto;
    padding-top: 3px;
  }
`

const CheckboxInput = styled.input`
  opacity: 0;
  position: absolute;

  &:checked + ${StyledCheckbox} {
    background-color: ${props => props.theme.colorAccentPrimary};
    > img {
      display: block;
    }
  }
`

const Checkbox = ({
  isChecked,
  value,
  onChange,
  className,
  reverseOrderOnMobile
}) => (
  <CheckboxLabel
    className={className}
    reverseOrderOnMobile={reverseOrderOnMobile}
  >
    <CheckboxInput type="checkbox" onChange={onChange} checked={isChecked} />
    <StyledCheckbox>
      <img src={Checkmark} alt="" />
    </StyledCheckbox>
    {value}
  </CheckboxLabel>
)

Checkbox.propTypes = {
    isChecked: PropTypes.bool,
    value: PropTypes.string,
    onChange: PropTypes.func,
    className: PropTypes.string,
    reverseOrderOnMobile: PropTypes.bool
}

export default Checkbox

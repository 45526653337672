import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

const StyledLabel = styled.label`
  margin: 0 auto;

  > input {
    box-sizing: border-box;
    width: 100%;
    margin: ${props => (props.hasErrorMessage ? '10px 0 0' : '10px 0 20px')};
    padding: 10px;
    display: block;
    border: 2px solid ${props => props.theme.colorBorder};
    font-size: ${props => props.theme.fontSize};
  }
`

const FormLabel = ({ children, ...rest }) => (
  <StyledLabel {...rest}>{children}</StyledLabel>
)

FormLabel.propTypes = {
    children: PropTypes.any
}

export default FormLabel

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { media } from '../styles'

const TabContainer = styled.div`
  display: flex;
  flex-direction: row;
  border: none;

  ${media.tabletLandscapeUp`
    display: none;
  `}
`

const Tab = styled.div`
  flex: 1;
  height: ${props => props.theme.subNavHeight};
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0px 0px 0px 1px
    ${props => props.theme.colorBackgroundSecondary};
  background-color: ${props =>
    props.selected
      ? props.theme.colorBackgroundPrimary
      : props.theme.colorBackgroundSecondary};
  cursor: pointer;
  user-select: none;
  font-size: ${props => props.theme.fontSize};
`

const NavTabs = ({ tabs, selectedTab, onClick }) => (
  <TabContainer>
    {tabs &&
      tabs.map((tab, i) => (
        <Tab
          key={i}
          selected={selectedTab === i}
          onClick={() => (onClick ? onClick(i) : null)}
        >
          {tab}
        </Tab>
      ))}
  </TabContainer>
)

NavTabs.propTypes = {
    tabs: PropTypes.array,
    selectedTab: PropTypes.any,
    onClick: PropTypes.func
}

export default NavTabs

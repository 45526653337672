const ASC = 'ASC'
const PRICE_ASC = 'PRICE_ASC'
const PRICE_DESC = 'PRICE_DESC'
const AREA_ASC = 'AREA_ASC'
const AREA_DESC = 'AREA_DESC'

export const orderBys = [
  undefined,
  ASC,
  PRICE_ASC,
  PRICE_DESC,
  AREA_ASC,
  AREA_DESC
]

export const getOrderByHeading = orderBy => {
  switch (orderBy) {
    case ASC:
      return 'Vanhin ilmoitus ensin'
    case PRICE_ASC:
      return 'Halvin ilmoitus ensin'
    case PRICE_DESC:
      return 'Kallein ilmoitus ensin'
    case AREA_ASC:
      return 'Pienin ilmoitus ensin'
    case AREA_DESC:
      return 'Suurin ilmoitus ensin'
    default:
      return 'Uusin ilmoitus ensin'
  }
}

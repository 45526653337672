import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { media } from '../styles'

const StyledDropDown = styled.div`
  position: relative;
  width: 100%;

  > button {
    padding: 10px 0;
    border: none;
    font-size: ${props => props.theme.fontSize};
    cursor: pointer;
    background-color: ${props => props.theme.colorBackgroundPrimary};
    color: ${props => props.theme.colorText};
    transition: 200ms;

    ${media.tabletLandscapeUp`
      padding: 10px 40px 10px 20px;
      background-color: ${props =>
        props.hasSelectedValue
          ? props.theme.colorAccentSecondary
          : props.theme.colorBackgroundPrimary};
      color: ${props =>
        props.hasSelectedValue
          ? props.theme.colorBackgroundPrimary
          : props.theme.colorText};
    `}

    &:focus {
      outline: none;
    }
  }
`

const StyledDropDownChildren = styled.div`
  > * {
    cursor: pointer;
    user-select: none;
  }

  ${media.tabletLandscapeUp`
    display: ${props => (props.isOpen ? 'block' : 'none')};
    position: absolute;
    top: 45px;
    left: 0;
    background-color: ${props => props.theme.colorBackgroundPrimary};
    border: 1px solid ${props => props.theme.colorBorder};
  `}
`

const Arrow = styled.span`
  display: none;
  position: absolute;
  right: 25px;
  top: ${props => (props.isOpen ? '18px' : '16px')};
  width: 4px;
  height: 4px;
  border-bottom: 1px solid;
  border-right: 1px solid;
  border-color: ${props =>
    props.hasSelectedValue
      ? props.theme.colorBackgroundPrimary
      : props.theme.colorText};
  transform: ${props => (props.isOpen ? 'rotate(-135deg)' : 'rotate(45deg)')};

  ${media.tabletLandscapeUp`
    display: inline;
  `}
`

const SelectedDisplayValue = styled.div`
  display: none;
  ${media.tabletLandscapeUp`
    display: ${props => (props.hasSelectedValue ? 'block' : 'none')};
  `}
`

const ButtonName = styled.div`
  ${media.tabletLandscapeUp`
    display: ${props => (props.hasSelectedValue ? 'none' : 'block')};
  `}
`

const SubNavDropDown = ({
  buttonName,
  children,
  isOpen,
  selectedDisplayValue
}) => {
  const [isOpenSubmenu, setIsOpen] = useState(isOpen)
  const dropdownRef = useRef(null)

  const handleClick = e => {
    if (dropdownRef.current.contains(e.target)) return
    setIsOpen(false)
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClick)

    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
  }, [])

  return (
    <StyledDropDown ref={dropdownRef} hasSelectedValue={selectedDisplayValue}>
      <button onClick={() => setIsOpen(!isOpenSubmenu)}>
        <SelectedDisplayValue hasSelectedValue={selectedDisplayValue}>
          {selectedDisplayValue}
        </SelectedDisplayValue>
        <ButtonName hasSelectedValue={selectedDisplayValue}>
          {buttonName}
        </ButtonName>
        <Arrow isOpen={isOpenSubmenu} hasSelectedValue={selectedDisplayValue} />
      </button>
      <StyledDropDownChildren isOpen={isOpenSubmenu}>
        {children}
      </StyledDropDownChildren>
    </StyledDropDown>
  )
}

SubNavDropDown.propTypes = {
    buttonName: PropTypes.string,
    children: PropTypes.any,
    isOpen: PropTypes.bool,
    selectedDisplayValue: PropTypes.any
}

export default SubNavDropDown

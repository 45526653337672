import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled, { css, createGlobalStyle } from 'styled-components/macro'
import { inputTypes } from '../utils/constants'
import { S3_BASE_URL, uploadToS3, getFilenameAndExt } from '../utils/awsUpload'
import { isEmpty } from 'lodash-es'
import Checkbox from './Checkbox'
import DropdownInput from './DropdownInput'
import { media } from '../styles'
import TimeField from 'react-simple-timefield'
import 'moment/locale/fi'
import { getFileNameFromUrl } from '../utils/getFileNameFromUrl'
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import moment from 'moment'
import fi from 'date-fns/locale/fi'

const StyledLabel = styled.label`
  display: flex;
  flex-direction: column;

  > :first-child {
    padding-bottom: 10px;
  }

  > :nth-child(2) {
    position: relative;
  }

  ${media.tabletLandscapeUp`
    flex-direction: row;

    > :first-child {
      flex: 1;
      font-weight: bold;
      margin-right: 40px;
    }

    > :nth-child(2) {
      flex: 2;
      > :first-child {
        width: 100%;
      }
    }
  `}

  &:not(:first-of-type) {
    margin-top: 30px;
  }
`

const AttachmentInput = styled.input`
  width: 0.1px;
  height: 0.1px;
  z-index: -1;
  margin-bottom: 40px;

  &::before {
    content: 'Lataa tiedosto (pdf)';
    padding: 10px 20px;
    position: absolute;
    font-size: ${props => props.theme.fontSize};
    display: block;
    background-color: ${props => props.theme.colorAccentSecondary};
    color: #fff;
    white-space: nowrap;
    text-align: center;
  }
`

const AttachmentValue = styled.div`
  margin-top: 20px;
  padding: 10px 20px;
  position: relative;
  background-color: ${props => props.theme.colorBackgroundSecondary};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;

  > span {
    font-size: ${props => props.theme.fontSize};
  }
`

const sharedInputStyle = css`
  box-sizing: border-box;
  padding: 10px;
  width: 100%;
  border: 1px solid ${props => props.theme.colorBorder};
  font-size: ${props => props.theme.fontSize};
`

const TextOrNumberInput = styled.input`
  ${sharedInputStyle}
`

const TextArea = styled.textarea`
  ${sharedInputStyle}
`

const TimeInputContainer = styled.div`
  display: flex;
  align-items: center;

  > :not(:last-child) {
    margin-right: 10px;
  }
`

const StyledTimeInput = styled(TimeField)`
  ${sharedInputStyle}
  flex: 1;
  font-family: ${props => props.theme.fontFamily};
`

const Unit = styled.span`
  position: absolute;
  right: 20px;
  top: 10px;
`

const StyledCheckbox = styled(Checkbox)`
  padding: 10px 0;
  &:first-child {
    padding-top: 0;
  }
`

const CheckboxList = ({ options, onChange, value }) => {
  const [selectedCheckboxes, setSelectedCheckboxes] = useState(value || [])

  const handleChange = option => {
    let newSelected
    if (selectedCheckboxes.some(x => x === option)) {
      newSelected = selectedCheckboxes.filter(x => x !== option)
    } else {
      newSelected = [...selectedCheckboxes, option]
    }
    onChange(newSelected)
    setSelectedCheckboxes(newSelected)
  }

  return (
    <div>
      {options.map((option, i) => (
        <StyledCheckbox
          key={i}
          onChange={() => handleChange(option.displayName)}
          value={option.displayName}
          isChecked={selectedCheckboxes.some(x => x === option.displayName)}
        />
      ))}
    </div>
  )
}

CheckboxList.propTypes = {
  options: PropTypes.array,
  onChange: PropTypes.func,
  value: PropTypes.string
}

const Attachment = ({ onChange, value }) => {
  const [attachmentName, setAttachmentName] = useState(value || '')
  return (
    <>
      <AttachmentInput
        type="file"
        id="attachment"
        name="attachment"
        accept="application/pdf"
        onChange={e => {
          if (isEmpty(e.target.files)) return
          const file = e.target.files[0]
          const { filenameWithoutExt, ext } = getFilenameAndExt(file.name)
          const filename = `${filenameWithoutExt}-${Date.now()}.${ext}`
          const url = `${S3_BASE_URL}${filename}`
          uploadToS3({ filename, body: file, contentType: file.type })
          onChange(url)
          setAttachmentName(file.name)
        }}
      />
      {attachmentName && (
        <AttachmentValue onClick={e => e.preventDefault()}>
          <span>{getFileNameFromUrl(attachmentName)}</span>
          <span
            onClick={e => {
              e.preventDefault()
              onChange('')
              setAttachmentName('')
            }}
          >
            Poista
          </span>
        </AttachmentValue>
      )}
    </>
  )
}

Attachment.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string
}

const DatePickerWrapperStyles = createGlobalStyle`
    width: 100%;
    box-sizing: border-box;
    * {
      box-sizing: border-box;
    }
    .date_picker {
      border: 1px solid ${props => props.theme.colorBorder};
      font-size: ${props => props.theme.fontSize};
      font-family: ${props => props.theme.fontFamily};
      padding: 10px;
      width: 100%;
    }
`

const SingleDatePicker = ({ updateShowingDate, initialDate }) => {
  registerLocale('fi', fi)
  setDefaultLocale('fi')
  const [date, setDate] = useState(initialDate)
  const updateDate = newDate => {
    setDate(newDate)
  }

  useEffect(() => {
    updateShowingDate(moment(date))
  }, [date])

  return (
    <>
      <DatePicker
        dateFormat="dd.MM.yyy"
        selected={date}
        onChange={updateDate}
        closeOnScroll={true}
        className="date_picker"
      />
      <DatePickerWrapperStyles />
    </>
  );
}

SingleDatePicker.propTypes = {
  updateShowingDate: PropTypes.func,
  date: PropTypes.object,
  initialDate: PropTypes.object
}

const InputWithLabel = ({
  label,
  value,
  onChange,
  isRequired,
  maxLength,
  min,
  inputType,
  unit,
  options,
  initialDate
}) => {
  const renderInput = inputType => {
    switch (inputType) {
      case inputTypes.TEXT:
        return (
          <TextOrNumberInput
            type="text"
            maxLength={maxLength}
            value={value || ''}
            onChange={e => onChange(e.target.value)}
          />
        )
      case inputTypes.TEXTAREA:
        return (
          <TextArea
            rows="3"
            maxLength={maxLength}
            value={value || ''}
            onChange={e => onChange(e.target.value)}
          />
        )
      case inputTypes.NUMBER:
        return (
          <>
            <TextOrNumberInput
              type="number"
              min={min}
              value={value || ''}
              onChange={e => onChange(e.target.value)}
            />
            <Unit>{unit}</Unit>
          </>
        )
      case inputTypes.DROPDOWN:
        return (
          <DropdownInput onChange={onChange} options={options} value={value} />
        )
      case inputTypes.CHECKBOX_LIST:
        return (
          <CheckboxList options={options} onChange={onChange} value={value} />
        )
      case inputTypes.ATTACHMENT:
        return <Attachment onChange={onChange} value={value || ''} />
      case inputTypes.DATE:
        return <SingleDatePicker updateShowingDate={onChange} initialDate={initialDate} />
      case inputTypes.TIME:
        return (
          <TimeInputContainer>
            <StyledTimeInput
              value={value.startTime}
              onChange={val => onChange(val, 'startTime')}
            />

            <span>-</span>
            <StyledTimeInput
              value={value.endTime}
              onChange={val => onChange(val, 'endTime')}
            />
          </TimeInputContainer>
        )
      default:
        return null
    }
  }

  return (
    <StyledLabel>
      <div>
        <span>{label}</span>
        {isRequired && <span>*</span>}
      </div>
      <div>{renderInput(inputType)}</div>
    </StyledLabel>
  )
}

InputWithLabel.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.bool
  ]),
  onChange: PropTypes.func,
  isRequired: PropTypes.bool,
  maxLength: PropTypes.number,
  min: PropTypes.number,
  inputType: PropTypes.any,
  unit: PropTypes.string,
  options: PropTypes.array,
  initialDate: PropTypes.object
}

export default InputWithLabel

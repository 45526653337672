import {
  ERROR_CODE,
  isNotOneOf,
  isInvalidZipCode,
  isBelowMin,
  isInvalidLink,
  isTooLong,
  isInteger,
  isNumber,
  isNotBoolean
} from '../components/FormValidator'
import { isEmpty } from 'lodash-es'

export const genIsRequiredField = displayName => ({
  messages: {
    [ERROR_CODE.IsRequired]: `${displayName} ei saa olla tyhjä.`
  },
  validators: [[ERROR_CODE.IsRequired, isEmpty]]
})

export const genPickOneField = displayName => ({
  messages: {
    [ERROR_CODE.IsRequired]: `Valitse ${displayName.toLowerCase()}.`
  },
  validators: [[ERROR_CODE.IsRequired, isEmpty]]
})

export const genRequiredBooleanField = displayName => {
  return {
    messages: {
      [ERROR_CODE.IsRequired]: `Valitse ${displayName.toLowerCase()}.`
    },
    validators: [[ERROR_CODE.IsRequired, isNotBoolean]]
  }
}

export const genPickAtleastOneField = displayName => ({
  messages: {
    [ERROR_CODE.IsRequired]: `Valitse vähintään yksi ${displayName.toLowerCase()}.`
  },
  validators: [[ERROR_CODE.IsRequired, isEmpty]]
})

export const genMinField = (min, displayName, unit = '') => ({
  messages: {
    [ERROR_CODE.IsBelowMinimum]: `${displayName} pitää olla vähintään ${min}${unit}.`
  },
  validators: [[ERROR_CODE.IsBelowMinimum, isBelowMin(min)]]
})

export const genLink = () => ({
  messages: {
    [ERROR_CODE.FailedPattern]:
      'Kirjoita linkki muodossa: http(s)://www.example.com.'
  },
  validators: [[ERROR_CODE.FailedPattern, isInvalidLink]]
})

export const genRequiredMaxLengthField = (max, displayName) => ({
  messages: {
    [ERROR_CODE.IsTooLong]: `${displayName} ei saa olla yli ${max} merkkiä.`,
    [ERROR_CODE.IsRequired]: `${displayName} ei saa olla tyhjä.`
  },
  validators: [
    [ERROR_CODE.IsTooLong, isTooLong(max)],
    [ERROR_CODE.IsRequired, isEmpty]
  ]
})

export const genRequiredMinField = (
  min,
  displayName,
  minDisplayName,
  unit = ''
) => ({
  messages: {
    [ERROR_CODE.IsBelowMinimum]: `${minDisplayName} pitää olla vähintään ${min}${unit}.`,
    [ERROR_CODE.IsRequired]: `${displayName} ei saa olla tyhjä.`
  },
  validators: [
    [ERROR_CODE.IsBelowMinimum, isBelowMin(min)],
    [ERROR_CODE.IsRequired, value => isEmpty(`${value}`)]
  ]
})

export const genMinIntegerField = (min, displayName, unit = '') => ({
  messages: {
    [ERROR_CODE.IsBelowMinimum]: `${displayName} pitää olla vähintään ${min}${unit}.`,
    [ERROR_CODE.InvalidInteger]: `${displayName} pitää olla kokonaisluku.`
  },
  validators: [
    [ERROR_CODE.IsBelowMinimum, isBelowMin(min)],
    [ERROR_CODE.InvalidInteger, val => val ? isInteger(val) : false]
  ]
})

export const genRequiredMinIntegerField = (
  min,
  displayName,
  minDisplayName,
  unit = ''
) => ({
  messages: {
    [ERROR_CODE.IsBelowMinimum]: `${minDisplayName} pitää olla vähintään ${min}${unit}.`,
    [ERROR_CODE.InvalidInteger]: `${minDisplayName} pitää olla kokonaisluku.`,
    [ERROR_CODE.IsRequired]: `${displayName} ei saa olla tyhjä.`
  },
  validators: [
    [ERROR_CODE.IsBelowMinimum, isBelowMin(min)],
    [ERROR_CODE.InvalidInteger, isInteger],
    [ERROR_CODE.IsRequired, isNumber]
  ]
})

export const baseValidationFields = {
  images: {
    messages: {
      [ERROR_CODE.IsRequired]: 'Lataa vähintään yksi kuva.'
    },
    validators: [[ERROR_CODE.IsRequired, isEmpty]]
  },
  zipCode: {
    messages: {
      [ERROR_CODE.FailedPattern]:
        'Postinumeron pitää olla väliltä 79700 - 83900.',
      [ERROR_CODE.IsRequired]: 'Postinumero ei saa olla tyhjä.'
    },
    validators: [
      [ERROR_CODE.FailedPattern, isInvalidZipCode],
      [ERROR_CODE.IsRequired, isEmpty]
    ]
  },
  municipality: {
    messages: {
      [ERROR_CODE.IsNotOneOf]:
        'Kaupungin / kunnan pitää olla Ilomantsi, Joensuu, Outokumpu, Polvijärvi, Liperi, Kontiolahti, Heinävesi, Juuka, Tohmajärvi, Rääkkylä, tai Kitee.',
      [ERROR_CODE.IsRequired]: 'Kaupunki / kunta ei saa olla tyhjä.'
    },
    validators: [
      [
        ERROR_CODE.IsNotOneOf,
        isNotOneOf(['Ilomantsi', 'Joensuu', 'Outokumpu', 'Polvijärvi', 'Liperi', 'Kontiolahti', 'Heinävesi', 'Juuka', 'Tohmajärvi', 'Rääkkylä', 'Kitee'])
      ],
      [ERROR_CODE.IsRequired, isEmpty]
    ]
  }
}

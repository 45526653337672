import {
  SET_OWN_POSTINGS,
  UPDATE_OWN_POSTING,
  DELETE_OWN_POSTING
} from '../constants'

const ownPostingsReducer = (state, action) => {
  switch (action.type) {
    case SET_OWN_POSTINGS:
      return { ...state, [action.payload.postingType]: action.payload.postings }
    case UPDATE_OWN_POSTING: {
      const arrToUpdate = [...state[action.payload.postingType]]
      const indexOfPostingToUpdate = arrToUpdate.indexOf(
        arrToUpdate.find(x => x.id === action.payload.posting.id)
      )
      arrToUpdate.splice(indexOfPostingToUpdate, 1, action.payload.posting)
      return { ...state, [action.payload.postingType]: arrToUpdate }
    }
    case DELETE_OWN_POSTING:
      return {
        ...state,
        [action.payload.postingType]: state[action.payload.postingType].filter(
          x => x.id !== action.payload.posting.id
        )
      }
    default:
      return state
  }
}

export default ownPostingsReducer

/* eslint react/display-name: 0 */
/* eslint react/prop-types: 0 */
import React from 'react'
import styled from 'styled-components/macro'

const Centered = styled.p`
  text-align: center;
`

export default props => {
  switch (props.statusCode) {
    case 404:
      return <Centered>Hakemaasi kohdetta ei löytynyt</Centered>
    default:
      return <Centered>Pahoittelut, jotakin meni pieleen</Centered>
  }
}

import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components/macro'
import MainNav from '../components/MainNav'
import SubNav from '../components/SubNav'
import BasePage from '../components/BasePage'
import Filter from '../components/Filter'
import { LinkCard } from '../components/Card'
import AreaMap, { MapContainer } from '../components/AreaMap'
import OrderByDropdown from '../components/OrderByDropdown'
import MobileMenu from '../components/MobileMenu'
import NavTabs from '../components/NavTabs'
import MobileContentToggle from '../components/MobileContentToggle'
import Footer from '../components/Footer'
import { useStateValue } from '../state'
import { fetchFilterItemsByType, fetchPostingsByType } from '../state/actions'
import { mapPostingToCard, postingTypes } from '../utils/mappings'
import { media, sizes } from '../styles'
import { fadeIn, grow, moveUp } from '../styles/animations'
import { buildQueryString } from '../utils/filters/filterFunctions'
import { getOrderByHeading, orderBys } from '../utils/orderings'
import { camelCase, range } from 'lodash-es'
import queryString from 'query-string'
import LoadingSpinner from '../assets/loading.svg'

const MapListContainer = styled.div``

const MapBackground = styled.div`
  flex: 1;
  height: ${props =>
    `calc(100vh - (${props.theme.mainNavHeightMobile} + ${props.theme.subNavHeight}))`};
  padding-top: ${props =>
    parseInt(props.theme.mainNavHeightMobile) +
    parseInt(props.theme.subNavHeight) +
    'px'};

  ${media.tabletLandscapeUp`
    padding-top: 0;
    height: ${props =>
      `calc(100vh - (${props.theme.mainNavHeight} + ${props.theme.subNavHeight}))`};
  `}

  ${MapContainer} {
    overflow: hidden;

    #map-container {
      display: block;
      background-color: ${props => props.theme.colorBackgroundSecondary};
      width: 100vw;
      height: ${props =>
    `calc(100vh - (${props.theme.mainNavHeightMobile} + ${props.theme.subNavHeight}))`};

      ${media.tabletLandscapeUp`
        position: fixed;
        top: ${props =>
      `calc(${props.theme.mainNavHeight} + ${props.theme.subNavHeight})`};
        left: 50vw;
        width: 50vw;
        height: ${props =>
      `calc(100vh - (${props.theme.mainNavHeight} + ${props.theme.subNavHeight}))`};
        padding-top: 0;
      `}
    }
  }
`

const StyledSubNav = styled(SubNav)`
  z-index: 1;
`

const RenderTabletLandscapeUp = styled.div`
  width: 0;
  height: 0;
  overflow: hidden;

  ${media.tabletLandscapeUp`
    width: auto;
    height: auto;
    display: flex;
    flex-direction: row;
  `}

  > :first-child {
    width: 100%;

    ${media.tabletLandscapeUp`
      width: 50vw;
    `}
  }
`

const RenderMobile = styled.div`
  ${media.tabletLandscapeUp`
    width: 0;
    height: 0;
    overflow: hidden;
  `}
`

const StyledToolsAndListContainer = styled(BasePage)``

const ToolsContainer = styled.div`
  padding-bottom: 30px;

  ${media.tabletPortraitUp`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  `}

  ${media.tabletLandscapeUp`
    padding-bottom: 40px;
  `}

  > :first-child {
    padding-bottom: 10px;

    ${media.tabletPortraitUp`
      padding-bottom: 0;
    `}
  }
`

const PostingsAmount = styled.span`
  font-weight: bold;
  padding-right: 5px;
`

export const StyledPostingList = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-gap: ${props => props.theme.contentSpacingMobile};
  grid-auto-rows: 300px;

  ${media.tabletPortraitUp`
    grid-template-columns: 1fr 1fr;
  `}

  ${media.tabletLandscapeUp`
    grid-template-columns: 1fr;
    grid-auto-rows: 350px;
    grid-gap: ${props => props.theme.contentSpacing};
  `}

  ${media.desktopUp`
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 300px;
  `}

  ${media.largeDesktopUp`
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 400px;
  `}
`

const FilterContainer = styled.div`
  display: none;
  animation: ${fadeIn} 400ms;

  > * {
    padding-right: 10px;
    white-space: nowrap;
  }

  ${media.tabletLandscapeUp`
    display: flex;
  `}
`

const LoadingContainer = styled(BasePage)`
  background-color: ${props => props.theme.colorBackgroundPrimary};
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;

  > img {
    width: 50px;
    height: 50px;
  }
`

export const StyledCard = styled(LinkCard)`
  animation-name: ${fadeIn}, ${moveUp};
  animation-duration: 200ms;
  animation-timing-function: cubic-bezier(0.18, 0.22, 0.33, 0.77);
  animation-fill-mode: backwards;

  ${range(1, 40).map(
  i => css`
      &:nth-child(${i}) {
        animation-delay: ${i * 100}ms;
      }
    `
)}
`

const FadeIn = styled.div`
  animation: ${fadeIn} 400ms, ${grow} 400ms;
`

const MapListView = ({ location, history }) => {
  const footerRef = useRef()
  const mapRef = useRef()
  const mainNavRef = useRef()
  const subNavRef = useRef()
  const mobileContentToggleRef = useRef()
  const [{ postings, filterItems }, dispatch] = useStateValue()
  const [hoveredPosting, setHoveredPosting] = useState(null)
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false)
  const [loading, setLoading] = useState(true)
  const [loadingFilters, setLoadingFilters] = useState(true)
  const [selectedTab, setSelectedTab] = useState(0)

  const splitUrl = location.pathname.split('/')
  const postingType = splitUrl[1]
  const query = location.search
  const queryParams = queryString.parse(history.location.search)
  const orderByHeading = getOrderByHeading(queryParams.orderBy)
  const postingTypePostings = postings[camelCase(postingType)]

  /*
    This function moves the fixedly positioned elements on the page when the
    footer becomes visible, so that they don't go on top of each other. We use
    refs to accomplish this since accessing the DOM through refs is pretty fast.
    The transforms move the elements the amount of pixels that the footer is
    visible on the screen.
  */
  const handleScroll = () => {
    if (!footerRef || !footerRef.current) return
    const { top } = footerRef.current.getBoundingClientRect()
    const pos = top - window.innerHeight
    const offset = pos < 0 ? pos : 0

    if (
      mapRef &&
      mapRef.current &&
      window.innerWidth >= sizes.tabletLandscapeUp
    ) {
      mapRef.current.style.transform = `translate3d(0, ${offset}px, 0)`
    }

    if (mobileContentToggleRef && mobileContentToggleRef.current) {
      mobileContentToggleRef.current.style.transform = `translate3d(-50%, ${offset}px, 0)`
    }

    if (mainNavRef && mainNavRef.current) {
      mainNavRef.current.style.transform = `translate3d(0, ${offset}px, 0)`
    }

    if (subNavRef && subNavRef.current) {
      subNavRef.current.style.transform = `translate3d(0, ${offset}px, 0)`
    }
  }

  const fetchPostings = async () => {
    await dispatch(fetchPostingsByType(postingType, query))
    setLoading(false)
    handleScroll()
  }

  const fetchFilterItems = async () => {
    await dispatch(fetchFilterItemsByType(postingType))
    setLoadingFilters(false)
  }

  useEffect(() => {
    const type = postingTypes.find(x => x.type === postingType)
    document.title = `Business Joensuu - ${type ? type.displayName : null}`
    handleScroll()
    window.addEventListener('scroll', handleScroll)
    window.addEventListener('resize', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
      window.removeEventListener('resize', handleScroll)
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  useEffect(() => {
    fetchPostings() // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  useEffect(() => {
    setLoading(true)
    setLoadingFilters(true)
    fetchPostings()
    fetchFilterItems() // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postingType])

  const renderPostingList = () => {
    if (loading)
      return (
        <LoadingContainer key="PostingList-loading">
          <img src={LoadingSpinner} alt="loading" />
        </LoadingContainer>
      )
    if (!postingTypePostings || !postingTypePostings.length)
      return (
        <BasePage key="PostingList-empty">
          <div>Hakuehdoillasi ei löytynyt ilmoituksia.</div>
        </BasePage>
      )

    return (
      <StyledToolsAndListContainer key="PostingList">
        <ToolsContainer>
          <div>
            <PostingsAmount>{postingTypePostings.length}</PostingsAmount>
            <span>kohdetta</span>
          </div>
          <OrderByDropdown
            heading={orderByHeading}
            links={orderBys.map(orderBy => ({
              linkTo: {
                pathname: history.location.pathname,
                search: buildQueryString(queryParams, { orderBy })
              },
              displayName: getOrderByHeading(orderBy)
            }))}
          />
        </ToolsContainer>
        <StyledPostingList>
          {postingTypePostings.map(posting => {
            const cardInfo = mapPostingToCard(posting, postingType)
            return cardInfo
              ? (
                <StyledCard
                  key={posting.id}
                  cardInfo={mapPostingToCard(posting, postingType)}
                  type={postingType}
                  linkTo={`/${postingType}/${posting.id}`}
                  onMouseEnter={() => setHoveredPosting(posting)}
                  onMouseLeave={() => setHoveredPosting(null)}
                />
              )
              : null
          })}
        </StyledPostingList>
      </StyledToolsAndListContainer>
    )
  }

  const renderMap = () => {
    return (
      <MapBackground key="AreaMap">
        <AreaMap
          ref={mapRef}
          postings={postingTypePostings}
          postingType={postingType}
          hoveredPosting={hoveredPosting}
        />
      </MapBackground>
    )
  }

  const tabs = [renderPostingList(), renderMap()]

  const type = postingTypes.find(x => x.type === postingType)

  return (
    <FadeIn>
      <MapListContainer>
        <RenderTabletLandscapeUp>{tabs}</RenderTabletLandscapeUp>
        <RenderMobile>{tabs[selectedTab]}</RenderMobile>
        <MobileContentToggle
          onClick={() => setMobileFiltersOpen(true)}
          ref={mobileContentToggleRef}
        >
          Filtterit
        </MobileContentToggle>
        <MobileMenu
          isMenuOpen={mobileFiltersOpen}
          onClick={() => setMobileFiltersOpen(!mobileFiltersOpen)}
        >
          <Filter
            postingType={postingType}
            filterItems={filterItems}
            history={history}
          />
        </MobileMenu>
      </MapListContainer>
      <MainNav history={history} ref={mainNavRef}>
        <h3>{type ? type.displayName : null}</h3>
      </MainNav>
      <StyledSubNav ref={subNavRef}>
        {!loadingFilters && (
          <FilterContainer>
            <Filter
              postingType={postingType}
              filterItems={filterItems}
              history={history}
            />
          </FilterContainer>
        )}
        <NavTabs
          tabs={['Lista', 'Kartta']}
          onClick={tabIndex => setSelectedTab(tabIndex)}
          selectedTab={selectedTab}
        />
      </StyledSubNav>
      <Footer ref={footerRef} />
    </FadeIn>
  )
}

MapListView.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object
}

export default MapListView

import React from 'react'
import PropTypes from 'prop-types'
import { isEqual, isString, isEmpty, isBoolean } from 'lodash-es'
import { sanitizeBoolean } from '../utils/sanitizations'

export const isValidEmail = email => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
}

export const isValidPhone = phone => {
  return /^\+\d{10,}/.test(phone)
}

export const isNotOneOf = list => value => !list.includes(value)

export const isInvalidZipCode = value => !/^(8[0123]\d{3})|^(79[789]\d{2})$/.test(value)

export const isInvalidLink = link => {
  if (isEmpty(link)) return false
  return !/^(https?:\/\/.*\.+.*)/.test(link)
}

export const startsWithHttp = link => /^(https?:\/\/.*)/.test(link)

export const isBelowMin = min => value => {
  if (isString(value) && value.length < 1) return false
  return parseInt(value, 10) < parseInt(min, 10)
}

export const isTooLong = max => value => {
  return value.length > max
}

export const isNotBoolean = value => {
  const parsed = sanitizeBoolean(value)
  return !isBoolean(parsed)
}

export const isInteger = value => !Number.isInteger(Number(value))

export const isNumber = value => isNaN(Number(value)) || !value

export const addHttpToLink = link => {
  if (isEmpty(link) || startsWithHttp(link)) return link
  return 'http://' + link
}

export const ERROR_CODE = {
  InvalidEmail: 'InvalidEmail',
  EmptyFirstname: 'EmptyFirstname',
  EmptyLastname: 'EmptyLastname',
  InvalidPhone: 'InvalidPhone',
  InvalidPhoneOrEmail: 'InvalidPhoneOrEmail',
  InconsistentPasswords: 'InconsistentPasswords',
  IsRequired: 'IsRequired',
  IsNotOneOf: 'IsNotOneOf',
  FailedPattern: 'FailedPattern',
  IsBelowMinimum: 'IsBelowMinimum',
  IsTooLong: 'IsTooLong',
  UserNotValidated: 'InvalidParameterException',
  // Cognito errors
  InvalidPassword: 'InvalidPasswordException',
  InvalidParameter: 'InvalidParameterException',
  UsernameExists: 'UsernameExistsException',
  UserNotFound: 'UserNotFoundException',
  NotAuthorized: 'NotAuthorizedException',
  LimitExceeded: 'LimitExceededException',
  CodeMismatch: 'CodeMismatchException',
  ExpiredCode: 'ExpiredCodeException',
  InvalidInteger: 'InvalidIntegerException'
}

export const defaultErrorMessages = {
  [ERROR_CODE.InvalidEmail]: 'Sähköposti väärin.',
  [ERROR_CODE.EmptyFirstname]: 'Etunimi ei saa olla tyhjä.',
  [ERROR_CODE.EmptyLastname]: 'Sukunimi ei saa olla tyhjä.',
  [ERROR_CODE.InvalidPassword]: 'Salasanan täytyy olla vähintään 10 merkkiä.',
  [ERROR_CODE.InconsistentPasswords]: 'Uudet salasanat eivät ole samat.',
  [ERROR_CODE.InvalidPhone]:
    'Puhelinnumerossa pitää olla maakoodi (esim: +358).',
  [ERROR_CODE.InvalidPhoneOrEmail]:
    'Sähköposti tai puhelinnumero väärin. Muista maakoodi (esim: +358).',
  [ERROR_CODE.InvalidParameter]: 'Tarkista että tiedot ovat oikein.',
  [ERROR_CODE.UsernameExists]: 'Tunnus on jo olemassa.',
  [ERROR_CODE.UserNotFound]: 'Tunnusta ei löydy.',
  [ERROR_CODE.NotAuthorized]: 'Salasana tai vahvistuskoodi väärin.',
  [ERROR_CODE.LimitExceeded]: 'Liian monta yritystä. Yritä kohta uudelleen.',
  [ERROR_CODE.InvalidInteger]: 'Arvon täytyy olla kokonaisluku.'
}

let previousErrorCodes = []

const FormValidator = ({ children, messages = {}, codes = [], onChange }) => {
  if (codes.length && !isEqual(previousErrorCodes, codes)) {
    const payload = codes.reduce((acc, code) => {
      acc[code] = messages[code] || defaultErrorMessages[code]
      return acc
    }, {})
    onChange(payload)
    previousErrorCodes = codes
  }
  return <>{children}</>
}

FormValidator.propTypes = {
  children: PropTypes.any,
  messages: PropTypes.object,
  codes: PropTypes.array,
  onChange: PropTypes.func
}

export default FormValidator

import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import Button from './Button'
import Modal, { ModalMain } from './Modal'
import ErrorMessage from './ErrorMessage'
import styled from 'styled-components/macro'
import { media } from '../styles'
import { useStateValue } from '../state'
import {
  rejectPosting,
  approvePosting,
  adminDeletePosting,
  setCurrentPosting
} from '../state/actions'

export const ButtonContainer = styled.div`
  > button {
    font-size: ${props => props.theme.fontSizeMobile};
    padding: 10px 20px;
    max-height: 50px;

    &:not(:first-child) {
      margin-left: 20px;
    }
  }

  ${media.tabletPortraitUp`
    > button {
      font-size: ${props => props.theme.fontSize};
      padding: 15px 30px;
    }
  `}

  ${media.tabletLandscapeUp`
    flex: 1 1 25%;
  `}
`

const StyledTextarea = styled.textarea`
  width: 100%;
  font-size: ${props => props.theme.fontSizeMobile};

  ${media.tabletLandscapeUp`
    font-size: ${props => props.theme.fontSizeSmall};
  `}

  ${media.desktopUp`
    font-size: ${props => props.theme.fontSize};
  `};
`

const Description = styled.p`
  margin-top: 0;
  margin-bottom: ${props => (props.isPostingInfo ? '30px' : '20px')};
  font-weight: ${props => (props.isPostingInfo ? 'bold' : 'normal')};
`

const ActionButton = styled(Button)`
  background-color: ${props =>
    props.isDelete ? props.theme.colorDanger : props.theme.colorSecondary};
  display: block;
  margin: 20px auto 0;
`

const DeleteButton = styled(Button)`
  background-color: ${props => props.theme.colorDanger};
`

const InfoContainer = styled.div`
  display: flex;
  justify-content: space-between;

  > * {
    flex: 1;
    padding: 8px 0;
  }

  > span {
    text-align: right;
  }
`

const StyledModal = styled(Modal)`
  > ${ModalMain} {
    height: auto;
  }
`

const defaultModalData = {
  message: '',
  postingInfo: ''
}

const AdminTools = ({
  type,
  posting,
  postingInfo = {},
  isApproved,
  isRejected,
  history,
  className
}) => {
  const [, dispatch] = useStateValue()
  const [showModal, setShowModal] = useState(false)
  const [modalType, setModalType] = useState('')
  const [modalData, setModalData] = useState(defaultModalData)
  const [showError, setShowError] = useState(false)
  const messageRef = useRef(null)
  const messageMaxLength = 255

  useEffect(() => {
    messageRef.current.focus()
  }, [showModal])

  const openModal = modalType => {
    setShowError(false)
    setModalType(modalType)
    setShowModal(true)
    setModalData({
      ...modalData,
      postingInfo: postingInfo.data
    })
  }

  const updateMessage = e => {
    setShowError(e.target.value.length < 1)
    setModalData({
      ...modalData,
      message: e.target.value
    })
  }

  const approve = () => {
    dispatch(approvePosting(type, postingInfo.id, isRejected))
    posting &&
      dispatch(
        setCurrentPosting({
          ...posting,
          isApproved: true,
          isRejected: false
        })
      )
  }

  const reject = () => {
    if (modalData.message.length < 1) return setShowError(true)
    setShowModal(false)
    setModalData(defaultModalData)
    dispatch(
      rejectPosting(type, postingInfo.id, {
        message: modalData.message
      })
    )
    posting &&
      dispatch(
        setCurrentPosting({
          ...posting,
          isApproved: false,
          isRejected: true
        })
      )
    history && history.goBack()
  }

  const handleDelete = () => {
    if (modalData.message.length < 1) return setShowError(true)
    setShowModal(false)
    dispatch(
      adminDeletePosting(
        type,
        postingInfo.id,
        {
          message: modalData.message
        },
        isRejected
      )
    )
    history && history.goBack()
  }

  const renderTextArea = () => (
    <StyledTextarea
      rows="10"
      maxLength={messageMaxLength}
      onChange={updateMessage}
      value={modalData.message}
      ref={messageRef}
    />
  )

  const renderInfoBlock = () => (
    <InfoContainer>
      {<ErrorMessage showError={showError}>Viesti on pakollinen</ErrorMessage>}
      <span>{messageMaxLength - modalData.message.length}</span>
    </InfoContainer>
  )

  const renderModalContent = () => {
    switch (modalType) {
      case 'delete':
        return (
          <>
            <Description>Haluatko varmasti poistaa ilmoituksen?</Description>
            <Description isPostingInfo>{modalData.postingInfo}</Description>
            <Description>
              Kirjoita alle poistamisen syy, joka lähetetään ilmoituksen
              tekijälle:
            </Description>
            {renderTextArea()}
            {renderInfoBlock()}
            <ActionButton isDelete onClick={handleDelete}>
              Poista ilmoitus
            </ActionButton>
          </>
        )
      default:
        return (
          <>
            <Description>Olet hylkäämässä:</Description>
            <Description isPostingInfo>{modalData.postingInfo}</Description>
            <Description>
              Kirjoita alle hylkäämisen syy, joka lähetetään ilmoituksen
              tekijälle:
            </Description>
            {renderTextArea()}
            {renderInfoBlock()}
            <ActionButton onClick={reject}>Hylkää ilmoitus</ActionButton>
          </>
        )
    }
  }

  return (
    <div className={className}>
      <ButtonContainer>
        {!isApproved && !isRejected && (
          <Button isPrimary onClick={approve}>
            Hyväksy
          </Button>
        )}
        {(!isRejected || isApproved) && (
          <Button onClick={() => openModal('reject')}>Hylkää</Button>
        )}
        <DeleteButton onClick={() => openModal('delete')}>Poista</DeleteButton>
      </ButtonContainer>
      <StyledModal
        fullSize
        showModal={showModal}
        closeModal={() => {
          setShowModal(false)
        }}
      >
        {renderModalContent()}
      </StyledModal>
    </div>
  )
}

AdminTools.propTypes = {
    type: PropTypes.string,
    posting: PropTypes.object,
    postingInfo: PropTypes.object,
    isApproved: PropTypes.bool,
    isRejected: PropTypes.bool,
    history: {
        goBack: PropTypes.func
    },
    className: PropTypes.string
}

export default AdminTools

import React from 'react'
import PropTypes from 'prop-types'
import Lottie from 'lottie-react-with-react-17'
import animationData from '../assets/checkmarkSuccess.json'
import styled from 'styled-components/macro'
import FormContainer from './FormContainer'
import Button from './Button'
import { media } from '../styles'

const Description = styled.p`
  font-size: ${props => props.theme.fontSizeSmall};
  margin: 0 0 20px;
`

export const ToasterContainer = styled(FormContainer)`
  text-align: center;

  > form {
    > div {
      max-width: 300px;
    }
  }
`

export const ToasterButton = styled(Button)`
  font-size: ${props => props.theme.fontSizeSmall};
  padding: 10px;

  ${media.tabletPortraitUp`
    font-size: ${props => props.theme.fontSize};
    padding: 15px 30px;
  `}
`

const Toaster = ({ children, message }) => (
  <>
    <Description>{message}</Description>
    <Lottie animationData={ animationData} loop ={false } />
    {children}
  </>
)

Toaster.propTypes = {
    children: PropTypes.any,
    message: PropTypes.string || PropTypes.element
}

export default Toaster

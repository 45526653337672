import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Auth from '@aws-amplify/auth'
import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'
import { mapUserAttributes } from '../utils/user'
import { setUser } from '../state/actions'
import { useStateValue } from '../state'
import MainNav from '../components/MainNav'
import FormPage from '../components/FormPage'
import FormContainer from '../components/FormContainer'
import FormLabel from '../components/FormLabel'
import Button from '../components/Button'
import ErrorMessage from '../components/ErrorMessage'
import FormValidator, { ERROR_CODE } from '../components/FormValidator'
import queryString from 'query-string'
import { isEmpty, startsWith } from 'lodash-es'
import Footer from '../components/Footer'

const ResetOrCreateLinksContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px 0;

  > a {
    text-decoration: none;
    color: ${props => props.theme.colorText};
    font-size: ${props => props.theme.fontSizeSmall};
  }
`

const notAllowedRedirectTos = [
  '/confirm-account',
  '/reset-account-password',
  'create-account'
]

const getRedirectTo = query => {
  return isEmpty(query.redirectTo) ||
    notAllowedRedirectTos.some(to => startsWith(query.redirectTo, to))
    ? '/'
    : query.redirectTo
}

const Login = ({ history }) => {
  const dispatch = useStateValue()[1]
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [errorCodes, setErrorCodes] = useState([])
  const [errorPayload, setErrorPayload] = useState({})
  const query = queryString.parse(history.location.search)
  const redirectTo = getRedirectTo(query)

  useEffect(() => {
    document.title = 'Business Joensuu - Kirjaudu sisään'
  }, [])

  const login = async () => {
    try {
      setErrorCodes([])
      await Auth.signIn(username, password)
      const user = await Auth.currentAuthenticatedUser()
      dispatch(setUser(mapUserAttributes(user)))
      history.push(redirectTo)
    } catch (err) {
      setErrorCodes([err.code])
      // Handle Cognito errors that require resending of confirmation code.
      switch (err.code) {
        case 'UserNotConfirmedException': {
          // User has not been confirmed and the confirmation code has
          // (most likely) been deprecated. So we'll just send them a new one.
          try {
            await Auth.resendSignUp(username)
          } catch (e) {
            console.error(e)
          }
          history.push('/confirm-account')
          return
        }
        case 'PasswordResetRequiredException': {
          // Password has been reset in the Cognito console or
          // an Admin has created this user.
          // We'll reset their password (which sends them a new confirmation code)
          // and redirect them to the confirmation page.
          try {
            await Auth.forgotPassword(username)
          } catch (e) {
            console.error(e)
          }
          history.push('/confirm-account?isChangePassword=true')
          return
        }
        default:
          console.error('Error in src/views/Login.js:login(), error: ', err)
          console.error(
            'Error in src/views/Login.js:login(), error code: ',
            err.code
          )
          // TODO 19.8.2019, vpeurala: We try to swallow an unexpected exception, just try to see if this fixes a problem in production. Fix this as soon as you can!
          console.error('Continuing despite the exception...')
          return
      }
    }
  }

  return (
    <>
      <FormPage>
        <MainNav />
        <FormContainer>
          <FormValidator codes={errorCodes} onChange={setErrorPayload}>
            <h1>Kirjaudu sisään</h1>
            <FormLabel hasErrorMessage>
              Sähköposti
              <input
                type="email"
                onChange={event => setUsername(event.target.value)}
              />
            </FormLabel>
            <ErrorMessage
              codes={[ERROR_CODE.UserNotFound]}
              payload={errorPayload}
            />
            <FormLabel hasErrorMessage>
              Salasana
              <input
                type="password"
                onChange={event => setPassword(event.target.value)}
              />
            </FormLabel>
            <ErrorMessage
              codes={[ERROR_CODE.NotAuthorized]}
              payload={errorPayload}
            />
            <Button isPrimary isFullWidth onClick={login} type="button">
              Kirjaudu sisään
            </Button>
            <ResetOrCreateLinksContainer>
              <Link to="/reset-account-password">Unohditko salasanan?</Link>
              <Link to="/create-account">Luo tunnus</Link>
            </ResetOrCreateLinksContainer>
          </FormValidator>
        </FormContainer>
      </FormPage>
      <Footer />
    </>
  )
}

Login.propTypes = {
    history: PropTypes.object
}

export default Login

import { get, API_URL } from '../../api'
import { SET_IS_SENT, SET_POSTINGS } from '../constants'
import { camelCase } from 'lodash-es'

export const fetchPostingsByType = (postingType, query) => async dispatch => {
  const res = await get(`${API_URL}/${postingType}${query}`)
  if (res.status === 200) {
    const newPostings = await res.json()
    return dispatch({
      type: SET_POSTINGS,
      payload: {
        postingType: camelCase(postingType),
        postings: newPostings
      }
    })
  }
}

export const setIsSent = payload => async dispatch => {
  return dispatch({
    type: SET_IS_SENT,
    payload
  })
}

import React, { useState } from 'react'
import Auth from '@aws-amplify/auth'
import FormLabel from './FormLabel'
import Button from './Button'
import Toaster from './Toaster'
import ErrorMessage from './ErrorMessage'
import FormValidator, {
  ERROR_CODE,
  defaultErrorMessages
} from './FormValidator'

const errorMessages = {
  [ERROR_CODE.NotAuthorized]: 'Väärä vanha salasana.',
  [ERROR_CODE.InvalidParameter]:
    defaultErrorMessages[ERROR_CODE.InvalidPassword]
}

const ChangePasswordForm = () => {
  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [newPassword2, setNewPassword2] = useState('')
  const [showToast, setShowToast] = useState(false)
  const [errorCodes, setErrorCodes] = useState([])
  const [errorPayload, setErrorPayload] = useState({})

  const changePassword = async () => {
    if (newPassword !== newPassword2) {
      setErrorCodes([ERROR_CODE.InconsistentPasswords])
      return
    }
    try {
      setErrorCodes([])
      const user = await Auth.currentAuthenticatedUser()
      await Auth.changePassword(user, oldPassword, newPassword)
      setShowToast(true)
      setTimeout(() => {
        setShowToast(false)
      }, 3000)
    } catch (err) {
      setErrorCodes([err.code])
      console.error(err)
    }
  }

  return showToast
    ? (
      <Toaster message="Salasanan vaihto onnistui!" />
    )
    : (
      <FormValidator
        messages={errorMessages}
        codes={errorCodes}
        onChange={setErrorPayload}
      >
        <FormLabel hasErrorMessage>
          Vanha salasana
        <input
            type="password"
            value={oldPassword}
            onChange={event => setOldPassword(event.target.value)}
          />
        </FormLabel>
        <ErrorMessage codes={[ERROR_CODE.NotAuthorized]} payload={errorPayload} />
        <FormLabel>
          Uusi salasana
        <input
            type="password"
            value={newPassword}
            onChange={event => setNewPassword(event.target.value)}
          />
        </FormLabel>
        <FormLabel hasErrorMessage>
          Toista salasana
        <input
            type="password"
            value={newPassword2}
            onChange={event => setNewPassword2(event.target.value)}
          />
        </FormLabel>
        <ErrorMessage
          codes={[
            ERROR_CODE.InvalidParameter,
            ERROR_CODE.InvalidPassword,
            ERROR_CODE.LimitExceeded,
            ERROR_CODE.InconsistentPasswords
          ]}
          payload={errorPayload}
        />
        <Button isPrimary isFullWidth onClick={changePassword} type="button">
          Vaihda salasana
      </Button>
      </FormValidator>
    )
}

export default ChangePasswordForm

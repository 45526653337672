import React, { createContext, useContext, useReducer } from 'react'
import PropTypes from 'prop-types'
import postingsReducer from './reducers/postings'
import unapprovedPostingsReducer from './reducers/unapprovedPostings'
import rejectedPostingsReducer from './reducers/rejectedPostings'
import userReducer from './reducers/user'
import ownPostingsReducer from './reducers/ownPostings'
import filterItemsReducer from './reducers/filterItems'
import currentPostingReducer from './reducers/currentPosting'

export const StateContext = createContext()

const loadPreviousState = () => {
  if (Object.prototype.hasOwnProperty.call(localStorage, 'appState')) {
    const state = localStorage.getItem('appState')
    try {
      return JSON.parse(state)
    } catch (e) {}
  }
}

const initialState = loadPreviousState() || {
  postings: {
    rental: [],
    forSale: [],
    conferenceRoom: [],
    plot: [],
    isSent: false
  },
  unapprovedPostings: {
    rental: undefined,
    forSale: undefined,
    conferenceRoom: undefined,
    plot: undefined
  },
  rejectedPostings: {
    rental: undefined,
    forSale: undefined,
    conferenceRoom: undefined,
    plot: undefined
  },
  user: {
    attributes: undefined,
    isAuthenticated: undefined
  },
  ownPostings: {
    rental: undefined,
    forSale: undefined,
    conferenceRoom: undefined,
    plot: undefined
  },
  currentPosting: undefined,
  filterItems: {}
}

export const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(mainReducer, initialState)

  const thunkedDispatch = action => {
    if (typeof action === 'function') {
      return action(dispatch, state)
    }
    return dispatch(action)
  }

  return (
    <StateContext.Provider value={[state, thunkedDispatch]}>
      {children}
    </StateContext.Provider>
  )
}

StateProvider.propTypes = {
    children: PropTypes.any
}

export const useStateValue = () => useContext(StateContext)

export const mainReducer = (
  {
    postings,
    unapprovedPostings,
    rejectedPostings,
    user,
    ownPostings,
    currentPosting,
    filterItems
  },
  action
) => ({
  postings: postingsReducer(postings, action),
  unapprovedPostings: unapprovedPostingsReducer(unapprovedPostings, action),
  rejectedPostings: rejectedPostingsReducer(rejectedPostings, action),
  user: userReducer(user, action),
  ownPostings: ownPostingsReducer(ownPostings, action),
  currentPosting: currentPostingReducer(currentPosting, action),
  filterItems: filterItemsReducer(filterItems, action)
})

import moment from 'moment'

export const getFirstUpcomingShowingText = showings => {
  const upComingShowings = showings.filter(showing =>
    moment(showing.startTime).isAfter(moment())
  )
  if (!upComingShowings.length) return
  const firstUpcomingShowing = upComingShowings.sort(
    (a, b) => new Date(a.startTime) - new Date(b.startTime)
  )[0]
  return `Esittely ${moment(firstUpcomingShowing.startTime).format(
    'dd D.M.'
  )} klo ${moment(firstUpcomingShowing.startTime).format('HH:mm')} - ${moment(
    firstUpcomingShowing.endTime
  ).format('HH:mm')}`
}

export const getAllUpcomingShowingsTexts = showings => {
  const upComingShowings = showings.filter(showing =>
    moment(showing.startTime).isAfter(moment())
  )
  return upComingShowings.map(
    showing =>
      `Esittely ${moment(showing.startTime).format('dd D.M.')} klo ${moment(
        showing.startTime
      ).format('HH:mm')} - ${moment(showing.endTime).format('HH:mm')}`
  )
}

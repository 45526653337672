import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { media } from '../styles'
import { fadeIn, grow } from '../styles/animations'

const StyledFormContainer = styled.div`
  margin: 0 auto;
  width: 90%;
  max-width: 500px;
  padding-top: 30px;
  position: relative;
  animation: ${fadeIn} 400ms, ${grow} 400ms;

  &:first-of-type {
    padding-top: ${props => `calc(${props.theme.mainNavHeightMobile} + 30px)`};

    ${media.tabletLandscapeUp`
      padding-top: ${props => `calc(${props.theme.mainNavHeight} + 30px)`};
    `}
  }
`

const StyledForm = styled.form`
  background-color: ${props => props.theme.colorBackgroundPrimary};
  padding: 30px;
  ${media.tabletPortraitUp`
    padding: 60px 100px;
  `}

  > h1 {
    margin-bottom: 30px;
  }
`

const FormContainer = ({ children, className }) => (
  <StyledFormContainer className={className}>
    <StyledForm>{children}</StyledForm>
  </StyledFormContainer>
)

FormContainer.propTypes = {
    children: PropTypes.any,
    className: PropTypes.string
}

export default FormContainer

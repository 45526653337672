import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components/macro'
import { Link } from 'react-router-dom'
import { buildSrcSet } from '../utils/image'
import CapacityIcon from '../assets/icon_pax.svg'

const LinkingCard = styled(Link)`
  height: 100%;
  box-sizing: border-box;
  display: grid;
  grid-template-rows: minmax(0, 1fr) minmax(0, max-content);
  text-decoration: none;
  color: ${props => props.theme.colorHeadings};

  > span {
    margin-top: 10px;
  }
`

const NonLinkingCard = styled.div`
  height: 100%;
  box-sizing: border-box;
  display: grid;
  grid-template-rows: minmax(0, 1fr) minmax(0, max-content);
  text-decoration: none;
  color: ${props => props.theme.colorHeadings};

  > span {
    margin-top: 10px;
  }
`

export const CardImageContainer = styled.div`
  position: relative;
`

export const CardInfo = styled.div`
  display: flex;
  padding-top: 10px;
`

const CardInfoLeftColumn = styled.div`
  flex: 5;

  > :first-child {
    font-weight: bold;
    font-size: ${props => props.theme.fontSize};
  }
  > :not(:first-child) {
    font-size: ${props => props.theme.fontSizeSmall};
    color: ${props => props.theme.colorText};
  }
`

const CardInfoRightColumn = styled.div`
  flex: 3;
  text-align: right;
  > * {
    font-weight: bold;
    font-size: ${props => props.theme.fontSize};
  }

  > :not(:first-child) {
    font-size: ${props => props.theme.fontSizeSmall};
  }
`

const CardInfoItem = styled.div`
  line-height: 1.5;
`

const sharedCardOverlayStyle = css`
  width: 100%;
  height: 40px;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${props => props.theme.fontSize};
  color: #000;
`

const Highlight = styled.div`
  ${sharedCardOverlayStyle};
  background-color: ${props => props.theme.colorAccentPrimary};
`

const StatusIndicator = styled.div`
  ${sharedCardOverlayStyle};
  background-color: ${props => props.theme.colorBackgroundSecondary};
`

const SmallOverlay = styled.div`
  ${sharedCardOverlayStyle};
  background-color: #fff;
  width: 65px;
  right: -1px;
  bottom: -1px;
  left: auto;
  border-top-left-radius: 4px;
  font-weight: 600;

  > img {
    width: 15px;
    height: 15px;
    padding-right: 5px;
  }
`

const renderCardInfoItem = (item, i) => {
  return typeof item !== 'undefined'
    ? (
      <CardInfoItem key={i}>{item}</CardInfoItem>
    )
    : null
}

export const CardContent = ({ cardInfo, statusText, children }) => {
  return (
    <>
      <CardImageContainer>
        <img
          src={cardInfo.image + '-432x270.jpg'}
          srcSet={buildSrcSet(cardInfo.image, 2, true)}
          sizes="
            (max-width: 600px) 100px,
            400px
          "
          alt=""
        />
        {cardInfo.highlight && <Highlight>{cardInfo.highlight}</Highlight>}
        {cardInfo.capacityOverlay && (
          <SmallOverlay>
            <img src={CapacityIcon} alt="kapasiteetti" />
            {cardInfo.capacityOverlay}
          </SmallOverlay>
        )}
        {statusText && <StatusIndicator>{statusText}</StatusIndicator>}
      </CardImageContainer>
      <CardInfo>
        {cardInfo.leftColumnItems && (
          <CardInfoLeftColumn>
            {cardInfo.leftColumnItems.map((item, i) =>
              renderCardInfoItem(item, i)
            )}
          </CardInfoLeftColumn>
        )}
        {cardInfo.rightColumnItems && (
          <CardInfoRightColumn>
            {cardInfo.rightColumnItems.map((item, i) =>
              renderCardInfoItem(item, i)
            )}
          </CardInfoRightColumn>
        )}
      </CardInfo>
      {children && <>{children}</>}
    </>
  )
}

export const LinkCard = ({
  cardInfo,
  linkTo,
  onMouseEnter,
  onMouseLeave,
  statusText,
  children,
  className,
  target
}) => {
  return (
    <LinkingCard
      to={linkTo}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={className}
      target={target}
    >
      <CardContent
        cardInfo={cardInfo}
        statusText={statusText}
        children={children}
      />
    </LinkingCard>
  )
}

export const NonLinkCard = ({
  cardInfo,
  onMouseEnter,
  onMouseLeave,
  statusText,
  children,
  className
}) => {
  return (
    <NonLinkingCard
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={className}
    >
      <CardContent
        cardInfo={cardInfo}
        statusText={statusText}
        children={children}
      />
    </NonLinkingCard>
  )
}

CardContent.propTypes = {
  cardInfo: PropTypes.shape({
    image: PropTypes.string,
    highlight: PropTypes.string,
    capacityOverlay: PropTypes.string,
    leftColumnItems: PropTypes.array,
    rightColumnItems: PropTypes.array
  }),
  statusText: PropTypes.string,
  children: PropTypes.any
}

LinkCard.propTypes = {
  cardInfo: PropTypes.any,
  linkTo: PropTypes.string,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  statusText: PropTypes.string,
  children: PropTypes.any,
  className: PropTypes.string,
  target: PropTypes.string
}

NonLinkCard.propTypes = {
  cardInfo: PropTypes.any,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  statusText: PropTypes.string,
  children: PropTypes.any,
  className: PropTypes.string
}

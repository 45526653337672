import React from 'react'
import styled from 'styled-components/macro'
import { media } from '../styles'
import Logo from '../assets/logo_white.svg'
import LinkedInIcon from '../assets/linkedin_icon.svg'
import TwitterIcon from '../assets/twitter_icon.svg'
import FacebookIcon from '../assets/facebook_icon.svg'
import InstagramIcon from '../assets/instagram_icon.svg'

const StyledFooter = styled.div`
  padding: ${props => props.theme.contentSpacingMobile};
  display: flex;
  flex-direction: column;
  background-color: #000;
  color: #fff;
  text-align: center;

  ${media.tabletLandscapeUp`
    text-align: left;
    padding: ${props => props.theme.contentSpacing};
    flex-direction: row;
  `}
`

const FooterInfo = styled.div`
  > p {
    font-weight: 900;
    font-size: 24px;

    &:last-of-type {
      padding-bottom: 40px;
    }
  }

  > img {
    width: 250px;
    height: auto;
    padding-bottom: 20px;
    margin: 0 auto;

    ${media.tabletLandscapeUp`
    margin: 0;
  `}
  }
`

const SocialMediaIcons = styled.div`
  display: flex;
  margin: 0 auto;
  padding-bottom: 20px;

  ${media.tabletLandscapeUp`
    margin: 0;
    padding-left: 100px;
  `}

  > a {
    height: 32px;
    padding: 5px;
    > img {
      height: auto;
    }
  }
`

const Footer = React.forwardRef((props, ref) => {
  return (
    <StyledFooter ref={ref}>
      <FooterInfo>
        <img src={Logo} alt="Logo" />
        <p>info@businessjoensuu.fi</p>
        <p>
          Business Joensuu Oy
          <br />
          Länsikatu 15
          <br />
          80110 Joensuu
        </p>
      </FooterInfo>
      <SocialMediaIcons>
        <a
          href="https://www.linkedin.com/company/business-joensuu-oy/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={LinkedInIcon} alt="LinkedIn" />
        </a>
        <a
          href="https://twitter.com/businessjoensuu"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={TwitterIcon} alt="Twitter" />
        </a>
        <a
          href="https://www.facebook.com/BusinessJoensuu/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={FacebookIcon} alt="Facebook" />
        </a>
        <a
          href="https://www.instagram.com/businessjoensuu/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={InstagramIcon} alt="Instagram" />
        </a>
      </SocialMediaIcons>
    </StyledFooter>
  )
})

Footer.displayName = 'Footer'

export default Footer

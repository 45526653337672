import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Auth from '@aws-amplify/auth'
import queryString from 'query-string'
import MainNav from '../components/MainNav'
import FormPage from '../components/FormPage'
import FormContainer from '../components/FormContainer'
import FormLabel from '../components/FormLabel'
import Button from '../components/Button'
import ErrorMessage from '../components/ErrorMessage'
import Toaster from '../components/Toaster'
import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'
import FormValidator, { ERROR_CODE } from '../components/FormValidator'
import Footer from '../components/Footer'
import { useStateValue } from '../state'
import { get } from 'lodash-es'

const Description = styled.p`
  font-size: ${props => props.theme.fontSizeSmall};
  margin: 0 0 20px;
`

const errorMessages = {
  [ERROR_CODE.NotAuthorized]: 'Tunnusta ei voida vahvistaa.',
  [ERROR_CODE.CodeMismatch]: 'Väärä vahvistuskoodi.',
  [ERROR_CODE.ExpiredCode]: 'Vahvistuskoodi on vanhentunut.',
  [ERROR_CODE.InvalidParameter]:
    'Salasanan on oltava vähintään 10 merkkiä pitkä.'
}

const ConfirmAccount = ({ history }) => {
  const [{ user }] = useStateValue()
  const [username, setUsername] = useState(get(user, 'attributes.email', ''))
  const [confirmCode, setConfirmCode] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [showToast, setShowToast] = useState(false)
  const [errorCodes, setErrorCodes] = useState([])
  const [errorPayload, setErrorPayload] = useState({})
  const query = queryString.parse(history.location.search)
  const isChangePassword = query.isChangePassword === 'true'
  const title = isChangePassword ? 'Vaihda salasana' : 'Vahvista tili'
  const successMsg = isChangePassword
    ? 'Salasanan vaihto onnistui!'
    : 'Tilisi on nyt vahvistettu ja voit kirjautua sisään.'

  useEffect(() => {
    document.title = isChangePassword
      ? 'Business Joensuu -  Vaihda salasana'
      : 'Business Joensuu -  Vahvista tili' // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const confirmAccount = async () => {
    try {
      setErrorCodes([])
      const operationFn = isChangePassword
        ? Auth.forgotPasswordSubmit(username, confirmCode, newPassword)
        : Auth.confirmSignUp(username, confirmCode)

      await operationFn
      setShowToast(true)
    } catch (err) {
      setErrorCodes([err.code])
      console.error(err)
    }
  }

  return (
    <>
      <FormPage>
        <input type="hidden" value="empty" />
        <MainNav history={history} />
        <FormContainer>
          <h1>{title}</h1>
          {showToast
            ? (
              <Toaster message={successMsg}>
                <Link to="/login">
                  <Button isPrimary isFullWidth>
                    Kirjaudu sisään
                </Button>
                </Link>
              </Toaster>
            )
            : (
              <FormValidator
                messages={errorMessages}
                codes={errorCodes}
                onChange={setErrorPayload}
              >
                <Description>
                  Olemme lähettäneet vahvistuskoodin sähköpostiisi.
              </Description>
                <FormLabel hasErrorMessage>
                  Sähköposti
                <input
                    type="email"
                    value={username}
                    onChange={event => setUsername(event.target.value)}
                  />
                </FormLabel>
                <ErrorMessage
                  codes={[ERROR_CODE.NotAuthorized, ERROR_CODE.UserNotFound]}
                  payload={errorPayload}
                />
                <FormLabel hasErrorMessage>
                  Vahvistuskoodi
                <input
                    autoComplete="email"
                    onChange={event => setConfirmCode(event.target.value)}
                  />
                </FormLabel>
                <ErrorMessage
                  codes={[ERROR_CODE.CodeMismatch, ERROR_CODE.ExpiredCode]}
                  payload={errorPayload}
                />
                {isChangePassword
                  ? (
                    <FormLabel hasErrorMessage>
                      Uusi salasana
                      <input
                        type="password"
                        autoComplete="new-password"
                        onChange={event => setNewPassword(event.target.value)}
                      />
                    </FormLabel>
                  )
                  : null}
                <ErrorMessage
                  codes={[
                    ERROR_CODE.InvalidParameter,
                    ERROR_CODE.InvalidPassword
                  ]}
                  payload={errorPayload}
                />
                <Button isPrimary isFullWidth onClick={confirmAccount}>
                  {title}
                </Button>
              </FormValidator>
            )}
        </FormContainer>
      </FormPage>
      <Footer />
    </>
  )
}

ConfirmAccount.propTypes = {
  history: PropTypes.object
}

export default ConfirmAccount

import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'
import MainNav from '../components/MainNav'
import FormPage from '../components/FormPage'
import PostingForm from '../components/PostingForm'
import Footer from '../components/Footer'
import Toaster, { ToasterContainer, ToasterButton } from '../components/Toaster'
import { postingInputFields } from '../utils/postingInputFields'
import { useStateValue } from '../state'
import { updateOwnPosting } from '../state/actions'
import { camelCase } from 'lodash-es'

const StyledMainNav = styled(MainNav)`
  z-index: 1;
`

const EditPosting = ({ location, history }) => {
  const [{ ownPostings }, dispatch] = useStateValue()
  const [initialPosting, setInitialPosting] = useState(null)
  const [showToast, setShowToast] = useState(false)
  const splitUrl = location.pathname.split('/')
  const postingType = splitUrl[splitUrl.length - 2]
  const postingId = splitUrl[splitUrl.length - 1]

  useEffect(() => {
    document.title = 'Business Joensuu - Muokkaa ilmoitusta'
  }, [])

  useEffect(() => {
    const postingTypeInCamelCase = camelCase(postingType)
    if (ownPostings[postingTypeInCamelCase]) {
      setInitialPosting(
        ownPostings[postingTypeInCamelCase].find(
          x => x.id === parseInt(postingId)
        )
      )
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ownPostings])

  const getFieldWithName = field =>
    postingInputFields(postingType).find(x => x.field === field)

  const sanitizePosting = posting =>
    Object.keys(posting).reduce((acc, key) => {
      const postingField = getFieldWithName(key)
      if (postingField && postingField.input && postingField.input.sanitize) {
        return { ...acc, [key]: postingField.input.sanitize(posting[key]) }
      }
      return { ...acc, [key]: posting[key] }
    }, {})

  const updatePosting = async (e, posting) => {
    e.preventDefault()

    const sanitizedPosting = sanitizePosting(posting)
    dispatch(updateOwnPosting(sanitizedPosting, postingType))
    setShowToast(true)
    window.scrollTo({ top: 0 })
  }

  return (
    <>
      <FormPage>
        <StyledMainNav history={history} />
        {showToast
          ? (
            <ToasterContainer>
              <Toaster message="Ilmoitus päivitetty!">
                <Link to={`/own-postings/${postingType}`}>
                  <ToasterButton isPrimary>Selaa omia ilmoituksia</ToasterButton>
                </Link>
              </Toaster>
            </ToasterContainer>
          )
          : (
            <>
              {initialPosting && (
                <PostingForm
                  initialPosting={initialPosting}
                  postingType={postingType}
                  onSubmit={updatePosting}
                  isEditPosting
                />
              )}
            </>
          )}
      </FormPage>
      <Footer />
    </>
  )
}

EditPosting.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object
}

export default EditPosting

import {
  getFieldDisplayName,
  getFieldUnit,
  getFieldOption
} from '../postingInputFields'
import { postingTypeStrings } from '../constants'
import {
  createDisplayStringWithUnit,
  createDisplayStringFromArray,
  createDisplayStringForPricePerArea
} from './helpers'

export const mapPostingToInfoBlocks = (posting, type) => {
  if (!posting) {
    throw new Error('mapPostingToInfoBlocks: no posting specified')
  }
  if (!type) {
    throw new Error('mapPostingToInfoBlocks: no posting type specified')
  }
  switch (type) {
    case postingTypeStrings.RENTAL:
      return [
        {
          heading: 'Perustiedot',
          fields: [
            {
              label: getFieldDisplayName(type, 'address'),
              value: posting.address
            },
            {
              label: getFieldDisplayName(type, 'district'),
              value: posting.district
            },
            {
              label: getFieldDisplayName(type, 'zipCode'),
              value: posting.zipCode
            },
            {
              label: getFieldDisplayName(type, 'municipality'),
              value: posting.municipality
            },
            {
              label: 'Kohdenumero',
              value: posting.id
            },
            {
              label: getFieldDisplayName(type, 'type'),
              value: posting.type
            },
            {
              label: getFieldDisplayName(type, 'condition'),
              value: posting.condition
            },
            {
              label: getFieldDisplayName(type, 'areaM2'),
              value: createDisplayStringWithUnit(posting, type, 'areaM2')
            },
            {
              label: getFieldDisplayName(type, 'propertyAreaM2'),
              value: createDisplayStringWithUnit(
                posting,
                type,
                'propertyAreaM2'
              )
            },
            {
              label: getFieldDisplayName(type, 'numberOfRooms'),
              value: posting.numberOfRooms
            },
            {
              label: getFieldDisplayName(type, 'floor'),
              value: posting.floor
            },
            {
              label: getFieldDisplayName(type, 'hasElevator'),
              value: getFieldOption(type, 'hasElevator', posting)
            },
            {
              label: getFieldDisplayName(
                type,
                'additionalInformationOnRelease'
              ),
              value: posting.additionalInformationOnRelease
            },
            {
              label: getFieldDisplayName(type, 'additionalInformation'),
              value: posting.additionalInformation
            },
            {
              label: getFieldDisplayName(type, 'link'),
              value: posting.link
            },
            {
              label: getFieldDisplayName(type, 'attachment'),
              value: posting.attachment
            }
          ]
        },
        {
          heading: 'Hintatiedot',
          fields: [
            {
              label: getFieldDisplayName(type, 'price'),
              value: createDisplayStringWithUnit(posting, type, 'price')
            },
            {
              label: getFieldDisplayName(type, 'includedInPrice'),
              value: posting.includedInPrice
            },
            {
              label: getFieldDisplayName(type, 'otherExpenses'),
              value: posting.otherExpenses
            }
          ]
        },
        {
          heading: 'Palvelut ja liikenneyhteydet',
          fields: [
            {
              label: getFieldDisplayName(type, 'parking'),
              value: posting.parking
            },
            {
              label: getFieldDisplayName(type, 'services'),
              value: posting.services
            },
            {
              label: getFieldDisplayName(type, 'access'),
              value: posting.access
            }
          ]
        }
      ]
    case postingTypeStrings.FOR_SALE:
      return [
        {
          heading: 'Perustiedot',
          fields: [
            {
              label: getFieldDisplayName(type, 'type'),
              value: posting.type
            },
            {
              label: getFieldDisplayName(type, 'address'),
              value: posting.address
            },
            {
              label: getFieldDisplayName(type, 'district'),
              value: posting.district
            },
            {
              label: getFieldDisplayName(type, 'zipCode'),
              value: posting.zipCode
            },
            {
              label: getFieldDisplayName(type, 'municipality'),
              value: posting.municipality
            },
            {
              label: 'Kohdenumero',
              value: posting.id
            },
            {
              label: getFieldDisplayName(type, 'condition'),
              value: posting.condition
            },
            {
              label: getFieldDisplayName(type, 'areaM2'),
              value: createDisplayStringWithUnit(posting, type, 'areaM2')
            },
            {
              label: getFieldDisplayName(type, 'propertyAreaM2'),
              value: createDisplayStringWithUnit(
                posting,
                type,
                'propertyAreaM2'
              )
            },
            {
              label: getFieldDisplayName(type, 'condominium'),
              value: posting.condominium
            },
            {
              label: getFieldDisplayName(type, 'numberOfRooms'),
              value: posting.numberOfRooms
            },
            {
              label: getFieldDisplayName(type, 'floor'),
              value: posting.floor
            },
            {
              label: getFieldDisplayName(type, 'hasElevator'),
              value: getFieldOption(type, 'hasElevator', posting)
            },
            {
              label: getFieldDisplayName(
                type,
                'additionalInformationOnRelease'
              ),
              value: posting.additionalInformationOnRelease
            },
            {
              label: getFieldDisplayName(type, 'additionalInformation'),
              value: posting.additionalInformation
            },
            {
              label: getFieldDisplayName(type, 'link'),
              value: posting.link
            },
            {
              label: getFieldDisplayName(type, 'attachment'),
              value: posting.attachment
            }
          ]
        },
        {
          heading: 'Hintatiedot',
          fields: [
            {
              label: getFieldDisplayName(type, 'price'),
              value: createDisplayStringWithUnit(posting, type, 'price')
            },
            {
              label: getFieldDisplayName(type, 'loanCharge'),
              value: createDisplayStringWithUnit(posting, type, 'loanCharge')
            },
            {
              label: getFieldDisplayName(type, 'maintenanceCharge'),
              value: createDisplayStringWithUnit(
                posting,
                type,
                'maintenanceCharge'
              )
            },
            {
              label: 'Yhtiövastike yhteensä',
              value:
                posting.maintenanceCharge &&
                posting.loanCharge &&
                `${Number(posting.maintenanceCharge) +
                  Number(posting.loanCharge)} ${getFieldUnit(
                  type,
                  'maintenanceCharge'
                )}`
            },
            {
              label: 'Neliöhinta',
              value: createDisplayStringForPricePerArea(
                posting.price,
                posting.areaM2
              )
            },
            {
              label: getFieldDisplayName(type, 'includedInPrice'),
              value: posting.includedInPrice
            },
            {
              label: getFieldDisplayName(type, 'otherExpenses'),
              value: posting.otherExpenses
            }
          ]
        },
        {
          heading: 'Palvelut ja liikenneyhteydet',
          fields: [
            {
              label: getFieldDisplayName(type, 'parking'),
              value: posting.parking
            },
            {
              label: getFieldDisplayName(type, 'services'),
              value: posting.services
            },
            {
              label: getFieldDisplayName(type, 'access'),
              value: posting.access
            }
          ]
        }
      ]
    case postingTypeStrings.PLOT:
      return [
        {
          heading: 'Perustiedot',
          fields: [
            {
              label: getFieldDisplayName(type, 'address'),
              value: posting.address
            },
            {
              label: getFieldDisplayName(type, 'district'),
              value: posting.district
            },
            {
              label: getFieldDisplayName(type, 'zipCode'),
              value: posting.zipCode
            },
            {
              label: getFieldDisplayName(type, 'municipality'),
              value: posting.municipality
            },
            {
              label: 'Kohdenumero',
              value: posting.id
            },
            {
              label: getFieldDisplayName(type, 'type'),
              value: posting.type
            },
            {
              label: getFieldDisplayName(type, 'plotAreaM2'),
              value: createDisplayStringWithUnit(posting, type, 'plotAreaM2')
            },
            {
              label: getFieldDisplayName(type, 'locationInformation'),
              value: posting.locationInformation
            },
            {
              label: getFieldDisplayName(type, 'release'),
              value: posting.release
            }
          ]
        },
        {
          heading: 'Hintatiedot',
          fields: [
            {
              label: getFieldDisplayName(type, 'priceFreeOfDebt'),
              value: createDisplayStringWithUnit(
                posting,
                type,
                'priceFreeOfDebt'
              )
            },
            {
              label: getFieldDisplayName(type, 'priceToBePaid'),
              value: createDisplayStringWithUnit(posting, type, 'priceToBePaid')
            },
            {
              label: getFieldDisplayName(type, 'estateTax'),
              value: createDisplayStringWithUnit(posting, type, 'estateTax')
            },
            {
              label: getFieldDisplayName(type, 'otherExpenses'),
              value: posting.otherExpenses
            }
          ]
        },
        {
          heading: 'Kohteen lisätiedot',
          fields: [
            {
              label: getFieldDisplayName(type, 'additionalInformation'),
              value: posting.additionalInformation
            },
            {
              label: getFieldDisplayName(type, 'waterSupplyLine'),
              value: posting.waterSupplyLine
            },
            {
              label: getFieldDisplayName(type, 'sewerageLine'),
              value: posting.sewerageLine
            },
            {
              label: getFieldDisplayName(type, 'powerLine'),
              value: getFieldOption(type, 'powerLine', posting)
            },
            {
              label: getFieldDisplayName(type, 'linesToBeTransferred'),
              value: posting.linesToBeTransferred
            }
          ]
        },
        {
          heading: 'Tontti ja kaavoitus',
          fields: [
            {
              label: getFieldDisplayName(type, 'plotOwner'),
              value: posting.plotOwner
            },
            {
              label: getFieldDisplayName(type, 'leaser'),
              value: posting.leaser
            },
            {
              label: getFieldDisplayName(type, 'plotId'),
              value: posting.plotId
            },
            {
              label: getFieldDisplayName(type, 'plotName'),
              value: posting.plotName
            },
            {
              label: getFieldDisplayName(type, 'plotType'),
              value: posting.plotType
            },
            {
              label: getFieldDisplayName(type, 'plotInformation'),
              value: posting.plotInformation
            },
            {
              label: getFieldDisplayName(type, 'permittedBuildingVolumeM2'),
              value: createDisplayStringWithUnit(
                posting,
                type,
                'permittedBuildingVolumeM2'
              )
            },
            {
              label: getFieldDisplayName(type, 'areaToBuiltAreaRatio'),
              value: posting.areaToBuiltAreaRatio
            },
            {
              label: getFieldDisplayName(type, 'zoning'),
              value: posting.zoning
            },
            {
              label: getFieldDisplayName(type, 'buildings'),
              value: posting.buildings
            },
            {
              label: getFieldDisplayName(type, 'servicesAndAccess'),
              value: posting.servicesAndAccess
            }
          ]
        }
      ]
    case postingTypeStrings.CONFERENCE_ROOM:
      return [
        {
          heading: 'Perustiedot',
          fields: [
            {
              label: getFieldDisplayName(type, 'address'),
              value: posting.address
            },
            {
              label: getFieldDisplayName(type, 'district'),
              value: posting.district
            },
            {
              label: getFieldDisplayName(type, 'zipCode'),
              value: posting.zipCode
            },
            {
              label: getFieldDisplayName(type, 'municipality'),
              value: posting.municipality
            },
            {
              label: 'Kohdenumero',
              value: posting.id
            },
            {
              label: getFieldDisplayName(type, 'type'),
              value: posting.type
            },
            {
              label: getFieldDisplayName(type, 'name'),
              value: posting.name
            },
            {
              label: getFieldDisplayName(type, 'capacity'),
              value: createDisplayStringWithUnit(posting, type, 'capacity')
            },
            {
              label: getFieldDisplayName(type, 'suitability'),
              value: createDisplayStringFromArray(posting.suitability)
            },
            {
              label: getFieldDisplayName(type, 'services'),
              value: createDisplayStringFromArray(posting.services)
            },
            {
              label: getFieldDisplayName(type, 'equipment'),
              value: createDisplayStringFromArray(posting.equipment)
            },
            {
              label: getFieldDisplayName(type, 'areaM2'),
              value: createDisplayStringWithUnit(posting, type, 'areaM2')
            },
            {
              label: getFieldDisplayName(type, 'additionalInformation'),
              value: posting.additionalInformation
            },
            {
              label: getFieldDisplayName(type, 'attachment'),
              value: posting.attachment
            },
            {
              label: getFieldDisplayName(type, 'link'),
              value: posting.link
            }
          ]
        },
        {
          heading: 'Hintatiedot',
          fields: [
            {
              label: getFieldDisplayName(type, 'price'),
              value: posting.price
            },
            {
              label: getFieldDisplayName(type, 'includedInPrice'),
              value: posting.includedInPrice
            },
            {
              label: getFieldDisplayName(type, 'otherExpenses'),
              value: posting.otherExpenses
            }
          ]
        },
        {
          heading: 'Palvelut ja liikenneyhteydet',
          fields: [
            {
              label: getFieldDisplayName(type, 'parking'),
              value: posting.parking
            },
            {
              label: getFieldDisplayName(type, 'access'),
              value: posting.access
            }
          ]
        }
      ]
    default:
      return []
  }
}

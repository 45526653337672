import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { media } from '../styles'

const StyledLabel = styled.label`
  display: flex;
  flex-direction: column;

  ${media.tabletLandscapeUp`
    flex-direction: row;

    > :first-child {
      flex: 1;
    }

    > :nth-child(2) {
      flex: 2;
    }
  `}
`

const Texts = styled.div`
  margin-right: 40px;

  > :first-child {
    font-weight: bold;
    padding-bottom: 10px;
  }

  > :nth-child(2) {
    color: ${props => props.theme.colorText};
    font-size: ${props => props.theme.fontSizeSmall};
    padding-bottom: 10px;
  }
`

const TextArea = styled.textarea`
  box-sizing: border-box;
  width: 100%;
  padding: 10px;
  border: 1px solid ${props => props.theme.colorBorder};
  font-size: ${props => props.theme.fontSize};
`

const DescriptionInput = ({ label, value, onChange, isRequired }) => (
  <StyledLabel>
    <Texts>
      <div>{label + (isRequired ? '*' : '')}</div>
      <div>1300 merkkiä</div>
    </Texts>
    <div>
      <TextArea rows={10} value={value} onChange={onChange} />
    </div>
  </StyledLabel>
)

DescriptionInput.propTypes = {
    label: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    isRequired: PropTypes.bool
}

export default DescriptionInput

import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import ReactMapGL, { NavigationControl, FlyToInterpolator } from 'react-map-gl'
import { get } from '../api'
import bbox from '@turf/bbox'
import { lineString } from '@turf/helpers'
import WebMercatorViewport from 'viewport-mercator-project'
import { isEqual } from 'lodash-es'
import { PostingOnMap, ClusterOnMap } from './PostingsOnMap'

const NavigationContainer = styled.div`
  position: absolute;
  left: 10px;
  top: 10px;
`

export const MapContainer = styled.div``

const ShowAfterLoading = styled.div`
  width: 100%;
  height: 100%;
  transition: opacity 400ms;
  opacity: ${props => props.loading};
`

const joensuuCoords = {
  latitude: 62.6,
  longitude: 29.76389
}

const AreaMap = React.forwardRef(
  ({ postings, postingType, hoveredPosting }, ref) => {
    const [postingsWithCoords, setPostingsWithCoords] = useState([])
    const [postingPopupToShow, setPostingPopupToShow] = useState(null)
    const [loading, setLoading] = useState(true)
    const [mapLoading, setMapLoading] = useState(true)
    let postingClusters = []
    let individualPostings = []
    const isMounted = useRef(false)

    const initialViewport = {
      width: '100%',
      height: '100%',
      latitude: joensuuCoords.latitude,
      longitude: joensuuCoords.longitude,
      zoom: 7
    }

    const [viewport, setViewport] = useState(initialViewport)

    const getMapContainerSizeInPx = () => {
      const mapContainer = document.getElementById('map-container')
      const { width, height } = mapContainer.getBoundingClientRect()
      return {
        width,
        height
      }
    }

    const getPostingWithCoords = async posting => {
      const res = await get(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
          posting.address + ' ' + posting.municipality
        )}.json?autocomplete=false&access_token=${
          process.env.REACT_APP_MAPBOX_ACCESS_TOKEN
        }&country=fi&proximity=${encodeURIComponent(
          joensuuCoords.longitude + ', ' + joensuuCoords.latitude
        )}}`
      )
      if (res.status === 200) {
        const response = await res.json()
        const bestMatchesForAddress = response.features
        if (bestMatchesForAddress && bestMatchesForAddress[0]) {
          return {
            ...posting,
            coords: bestMatchesForAddress[0].geometry.coordinates
          }
        }
      }
    }

    const getAndSetPostingCoords = async () => {
      const withCoords = await Promise.all(
        postings.map(posting => getPostingWithCoords(posting))
      )
      const removeUndefined = withCoords.filter(x => typeof x !== 'undefined')
      if (isMounted.current) {
        setPostingsWithCoords(removeUndefined)
        setLoading(false)
        zoomToPostings(removeUndefined.map(x => x.coords))
      }
    }

    useEffect(() => {
      isMounted.current = true
      getAndSetPostingCoords()
      return () => {
        isMounted.current = false
      } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [postings])

    const updateViewPort = viewport => {
      if (isMounted.current) {
        setViewport({ ...viewport, width: '100%', height: '100%' })
      }
    }

    const zoomToPostings = coordsArr => {
      let feature
      const allPostingsInTheSameCoords = coordsArr.every(x =>
        isEqual(x, coordsArr[0])
      )
      if (coordsArr.length < 1) return
      if (coordsArr.length === 1 || allPostingsInTheSameCoords) {
        // if there is only one point to show, we have to set coordinates on which
        // to base the bounding box manually, otherwise we get a bbox with width and
        // height of 0, which we cannot zoom into
        const [pointLng, pointLat] = coordsArr[0]
        const coordsAroundPointArr = [
          [pointLng - 0.01, pointLat - 0.01],
          [pointLng + 0.01, pointLat + 0.01]
        ]
        feature = lineString(coordsAroundPointArr)
      } else {
        feature = lineString(coordsArr)
      }
      const [minLng, minLat, maxLng, maxLat] = bbox(feature)
      const { width, height } = getMapContainerSizeInPx()
      const viewportWithPxSizes = {
        ...viewport,
        width,
        height
      }
      const mercatorViewport = new WebMercatorViewport(viewportWithPxSizes)
      const { longitude, latitude, zoom } = mercatorViewport.fitBounds(
        [
          [minLng, minLat],
          [maxLng, maxLat]
        ],
        { padding: 100 }
      )
      setViewport({
        ...viewport,
        longitude,
        latitude,
        zoom,
        transitionInterpolator: new FlyToInterpolator(),
        transitionDuration: 600
      })
    }

    const postingsHaveSameFullAddress = (a, b) =>
      a.address.toLowerCase() === b.address.toLowerCase() &&
      a.municipality === b.municipality &&
      a.zipCode === b.zipCode

    const postingsInSameCluster = (posting, allPostings) =>
      allPostings.filter(x => postingsHaveSameFullAddress(x, posting))

    const clusterizePostings = withCoords => {
      withCoords.forEach(posting => {
        const postingAlreadyInCluster = postingClusters.some(cluster =>
          cluster.some(post => postingsHaveSameFullAddress(post, posting))
        )
        if (postingAlreadyInCluster) {
          return
        }
        const clusterOfPostings = postingsInSameCluster(posting, withCoords)
        if (clusterOfPostings.length > 1) {
          postingClusters.push(clusterOfPostings)
        } else {
          individualPostings.push(posting)
        }
      })
    }

    if (!loading) {
      clusterizePostings(postingsWithCoords)
      return (
        <MapContainer>
          <div id="map-container" ref={ref}>
            <ShowAfterLoading loading={mapLoading ? '0' : '1'}>
              <ReactMapGL
                {...viewport}
                onLoad={() => setMapLoading(false)}
                mapStyle="mapbox://styles/business-joensuu/cjttqmbib15kb1frwm7l6eswh"
                mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
                onViewportChange={viewport => updateViewPort(viewport)}
              >
                <NavigationContainer>
                  <NavigationControl
                    onViewportChange={viewport => updateViewPort(viewport)}
                    showCompass={false}
                  />
                </NavigationContainer>
                {individualPostings
                  .filter(item =>
                    postings.some(posting => posting.id === item.id)
                  )
                  .map(posting => (
                    <PostingOnMap
                      key={posting.id}
                      posting={posting}
                      postingType={postingType}
                      showPopup={postingPopupToShow === posting}
                      setPostingPopupToShow={post =>
                        setPostingPopupToShow(post)
                      }
                      isHovered={
                        hoveredPosting && hoveredPosting.id === posting.id
                      }
                    />
                  ))}
                {postingClusters.map((cluster, i) => (
                  <ClusterOnMap
                    key={i}
                    postings={cluster}
                    postingType={postingType}
                    popupToShow={postingPopupToShow}
                    setPostingPopupToShow={post => setPostingPopupToShow(post)}
                    hoveredPosting={hoveredPosting ? hoveredPosting.id : null}
                  />
                ))}
              </ReactMapGL>
            </ShowAfterLoading>
          </div>
        </MapContainer>
      )
    }
    return null
  }
)

AreaMap.propTypes = {
  postings: PropTypes.array,
  postingType: PropTypes.string,
  hoveredPosting: PropTypes.object
}

export default AreaMap

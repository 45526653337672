import rentalInputFields from './rentalInputFields'
import forSaleInputFields from './forSaleInputFields'
import plotInputFields from './plotInputFields'
import conferenceRoomInputFields from './conferenceRoomInputFields'
import { postingTypeStrings } from '../constants'

export const getFieldDisplayName = (type, field) => {
  return postingInputFields(type).find(x => x.field === field).displayName
}

export const getFieldUnit = (type, field) => {
  return postingInputFields(type).find(x => x.field === field).input.unit
}

export const getFieldOptions = (type, field) => {
  return postingInputFields(type).find(x => x.field === field).input.options
}

export const getFieldOption = (type, field, posting) => {
  const optionToDisplay = getFieldOptions(type, field).find(
    option => option.value === posting[field]
  )
  if (optionToDisplay) return optionToDisplay.displayName
}

export const postingInputFields = type => {
  switch (type) {
    case postingTypeStrings.RENTAL:
      return rentalInputFields
    case postingTypeStrings.FOR_SALE:
      return forSaleInputFields
    case postingTypeStrings.PLOT:
      return plotInputFields
    case postingTypeStrings.CONFERENCE_ROOM:
      return conferenceRoomInputFields
    default:
      return []
  }
}

import { postingTypeStrings } from '../constants'
import { createDisplayStringWithUnit } from './helpers'

export const mapPostingToHeroInfo = (posting, type) => {
  if (!posting) {
    throw new Error('mapPostingToHeroInfo: no posting specified')
  }
  if (!type) {
    throw new Error('mapPostingToHeroInfo: no posting type specified')
  }
  switch (type) {
    case postingTypeStrings.RENTAL:
      return {
        type: 'Vuokrataan',
        headingFirstLine: posting.address,
        headingSecondLine: posting.zipCode + ' ' + posting.municipality,
        listItems: [
          createDisplayStringWithUnit(posting, type, 'price'),
          createDisplayStringWithUnit(posting, type, 'areaM2'),
          posting.type
        ],
        image: posting.coverImage
      }
    case postingTypeStrings.FOR_SALE:
      return {
        type: 'Myydään',
        headingFirstLine: posting.address,
        headingSecondLine: posting.zipCode + ' ' + posting.municipality,
        listItems: [
          createDisplayStringWithUnit(posting, type, 'price'),
          createDisplayStringWithUnit(posting, type, 'areaM2'),
          posting.type
        ],
        image: posting.coverImage
      }
    case postingTypeStrings.PLOT:
      return {
        type: posting.type,
        headingFirstLine: posting.address,
        headingSecondLine: posting.zipCode + ' ' + posting.municipality,
        listItems: [
          createDisplayStringWithUnit(posting, type, 'priceToBePaid'),
          createDisplayStringWithUnit(posting, type, 'plotAreaM2')
        ],
        image: posting.coverImage
      }
    case postingTypeStrings.CONFERENCE_ROOM:
      return {
        type: posting.type,
        headingFirstLine: posting.name,
        listItems: [
          posting.address + ', ' + posting.zipCode + ' ' + posting.municipality,
          posting.price,
          'Soveltuu ' + posting.capacity + ' henkilölle'
        ],
        image: posting.coverImage
      }
    default:
      return null
  }
}

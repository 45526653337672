import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { media } from '../styles'
import { buildSrcSet } from '../utils/image'

const StyledPostingHero = styled.div`
  display: flex;
  flex-direction: column;

  ${media.tabletLandscapeUp`
    flex-direction: row;
  `}
`

const PostingImageContainer = styled.div`
  height: 350px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  ${media.tabletPortraitUp`
    height: 450px;
  `}

  ${media.tabletLandscapeUp`
    height: 600px;
    flex: 2;
  `}
`

const PostingMainInfo = styled.div`
  padding: 40px;
  margin-bottom: 20px;
  background-color: ${props => props.theme.colorBackgroundSecondary};

  ${media.tabletLandscapeUp`
    flex: 1;
    margin-right: 20px;
    margin-bottom: 0;
  `}

  > div {
    text-transform: uppercase;
    font-size: ${props => props.theme.fontSizeMobile};
  }

  > h1 {
    &:first-of-type {
      margin-top: 30px;
    }
    &:nth-of-type(2) {
      margin: 10px 0 40px 0;
    }
  }

  > ul {
    padding-inline-start: 20px;
    margin: 0;

    > li:not(:first-child) {
      padding-top: 20px;
    }
  }
`

const PostingHero = React.forwardRef(({ heroInfo }, ref) => {
  if (heroInfo) {
    return (
      <StyledPostingHero ref={ref}>
        <PostingMainInfo>
          <div>{heroInfo.type}</div>
          <h1>{heroInfo.headingFirstLine}</h1>
          <h1>{heroInfo.headingSecondLine}</h1>
          {heroInfo.listItems && (
            <ul>
              {heroInfo.listItems.map(item => (
                <li key={item}>
                  <h3>{item}</h3>
                </li>
              ))}
            </ul>
          )}
        </PostingMainInfo>
        <PostingImageContainer>
          <img
            src={heroInfo.image + '-900x563.jpg'}
            srcSet={buildSrcSet(heroInfo.image, 4)}
            sizes="
              (max-width: 600px) 100px,
              (max-width: 900px) 300px,
              (max-width: 1200px) 500px,
              1200px
            "
            alt="Tilan kansikuva"
          />
        </PostingImageContainer>
      </StyledPostingHero>
    )
  }
  return null
})

PostingHero.displayName = 'PostingHero'

PostingHero.propTypes = {
    heroInfo: PropTypes.object
}

export default PostingHero

import React, { useState, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import Cropper from 'react-easy-crop'

const ImageCropper = ({ cropperImage, cropXY = { x: 0, y: 0 }, onCrop }) => {
  const [crop, setCrop] = useState(cropXY)
  const onCropComplete = useCallback(
    (croppedArea, croppedAreaPixels) => {
      onCrop({
        cropXY: ref.current.props.crop,
        cropArea: croppedAreaPixels
      })
    },
    [onCrop]
  )

  const ref = useRef(null)

  return (
    <Cropper
      ref={ref}
      image={cropperImage}
      crop={crop}
      aspect={16 / 10}
      onCropChange={setCrop}
      onCropComplete={onCropComplete}
      maxZoom={1}
      style={{
        mediaStyle: {
          width: 'unset',
          height: 'unset'
        }
      }}
    />
  )
}

ImageCropper.propTypes = {
  cropperImage: PropTypes.any,
  cropXY: PropTypes.object,
  onCrop: PropTypes.func
}

export default ImageCropper

import { useEffect } from 'react'
import { useStateValue } from '../state'
import { fetchOwnPostingsByType } from '../state/actions'
import { postingTypes } from '../utils/mappings'
import { isEmpty } from 'lodash-es'

const OwnPostingsFetcher = () => {
  const [{ user }, dispatch] = useStateValue()

  useEffect(() => {
    if (user.isAuthenticated && !isEmpty(user.attributes)) {
      postingTypes.forEach(postingType => {
        dispatch(fetchOwnPostingsByType(postingType.type))
      })
    } // eslint-disable-next-line
  }, [user])
  return null
}

export default OwnPostingsFetcher

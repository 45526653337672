import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Auth from '@aws-amplify/auth'
import { isEmpty, defaultTo } from 'lodash-es'
import { useStateValue } from '../state'
import { setUser } from '../state/actions'
import FormLabel from './FormLabel'
import Button from './Button'
import Checkmark from '../assets/checkmark.png'
import Toaster from './Toaster'
import styled from 'styled-components/macro'
import { media } from '../styles'
import ErrorMessage from './ErrorMessage'
import FormValidator, {
  ERROR_CODE,
  isValidEmail,
  isValidPhone
} from './FormValidator'
import { mapUserAttributes } from '../utils/user'

const RadioButtonContainer = styled.div`
  display: flex;
  margin-bottom: 30px;
  flex-direction: column;

  > label:not(:last-child) {
    padding-bottom: 20px;
  }

  ${media.tabletLandscapeUp`
    flex-direction: row;

    > label:not(:last-child) {
      padding-bottom: 0;
    }
  `}
`

const StyledRadioButton = styled.span`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: ${props => props.theme.colorBackgroundSecondary};
  margin-right: 15px;

  > img {
    display: none;
    fill: #fff;
    width: 10px;
    height: 10px;
    margin: 0 auto;
    padding-top: 4px;
  }
`

const RadioButton = styled.input`
  opacity: 0;
  position: absolute;

  &:checked + ${StyledRadioButton} {
    background-color: ${props => props.theme.colorAccentPrimary};
    > img {
      display: block;
    }
  }
`

const RadioButtonLabel = styled.label`
  width: 100%;
  display: flex;
  align-items: center;
  font-size: ${props => props.theme.fontSizeSmall};
  user-select: none;
  cursor: pointer;

  ${media.tabletLandscapeUp`
    font-size: ${props => props.theme.fontSize};
  `}

  > input {
    cursor: pointer;
  }
`

const AccountForm = ({ isCreate = false, history }) => {
  const [{ user }, dispatch] = useStateValue()
  const [isPatchedForm, setIsPatchedForm] = useState(false)
  const [isBusinessUser, setIsBusinessUser] = useState(false)
  const [email, setEmail] = useState('')
  const [firstname, setFirstname] = useState('')
  const [lastname, setLastname] = useState('')
  const [password, setPassword] = useState('')
  const [phone, setPhone] = useState('')
  const [businessId, setBusinessId] = useState('')
  const [businessName, setBusinessName] = useState('')
  const [showToast, setShowToast] = useState(false)
  const [errorCodes, setErrorCodes] = useState([])
  const [errorPayload, setErrorPayload] = useState({})
  const attributes = {
    email,
    password,
    firstname,
    lastname,
    phone,
    businessId
  }

  // Modify mode engage!
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!isCreate && user.attributes && !isPatchedForm) {
      setEmail(defaultTo(user.attributes.email, ''))
      setFirstname(defaultTo(user.attributes.firstname, ''))
      setLastname(defaultTo(user.attributes.lastname, ''))
      setPhone(defaultTo(user.attributes.phoneNumber, ''))
      setBusinessId(defaultTo(user.attributes.customBusinessId, ''))
      setBusinessName(defaultTo(user.attributes.customBusinessName, ''))
      setIsBusinessUser(
        !isEmpty(user.attributes.customBusinessId) ||
        !isEmpty(user.attributes.customBusinessName)
      )

      return () => {
        setIsPatchedForm(true)
      }
    }
  })

  const isValidForm = () => {
    const newErrorCodes = []
    if (!isValidEmail(email)) newErrorCodes.push(ERROR_CODE.InvalidEmail)
    if (isEmpty(firstname)) newErrorCodes.push(ERROR_CODE.EmptyFirstname)
    if (isEmpty(lastname)) newErrorCodes.push(ERROR_CODE.EmptyLastname)
    if (isCreate && (isEmpty(password) || password.length < 10))
      newErrorCodes.push(ERROR_CODE.InvalidPassword)
    if (!isValidPhone(phone)) newErrorCodes.push(ERROR_CODE.InvalidPhone)
    setErrorCodes(newErrorCodes)
    return newErrorCodes.length < 1
  }

  const getAttrsInCognitoFormat = () => ({
    username: email,
    password: password,
    attributes: {
      email,
      phone_number: phone,
      given_name: firstname,
      family_name: lastname,
      'custom:business_id': isBusinessUser ? businessId : '',
      'custom:business_name': isBusinessUser ? businessName : ''
    }
  })

  const createOrUpdateAccount = async () => {
    if (!isValidForm()) return
    try {
      if (isCreate) {
        const attrs = getAttrsInCognitoFormat(attributes)
        await Auth.signUp(attrs)
        dispatch(
          setUser(
            mapUserAttributes({
              attributes: attrs.attributes
            }),
            false
          )
        )
        history.push('/confirm-account')
        return
      }
      const currentUser = await Auth.currentAuthenticatedUser()
      await Auth.updateUserAttributes(
        currentUser,
        getAttrsInCognitoFormat(attributes).attributes
      )
      setShowToast(true)
      // Hide toast after timeout
      setTimeout(() => {
        setShowToast(false)
      }, 3000)
      dispatch(
        setUser(
          mapUserAttributes({
            ...currentUser,
            attributes: getAttrsInCognitoFormat(attributes).attributes
          })
        )
      )
    } catch (err) {
      setErrorCodes([err.code])
      console.error(err)
    }
  }

  return showToast
    ? (
      <Toaster message="Tietojen päivitys onnistui!" />
    )
    : (
      <FormValidator codes={errorCodes} onChange={setErrorPayload}>
        <RadioButtonContainer>
          <RadioButtonLabel>
            <RadioButton
              type="radio"
              checked={!isBusinessUser}
              onChange={() => setIsBusinessUser(false)}
            />
            <StyledRadioButton>
              <img src={Checkmark} alt="" />
            </StyledRadioButton>
          Yksityinen
        </RadioButtonLabel>
          <RadioButtonLabel>
            <RadioButton
              type="radio"
              checked={isBusinessUser}
              onChange={() => setIsBusinessUser(true)}
            />
            <StyledRadioButton>
              <img src={Checkmark} alt="" />
            </StyledRadioButton>
          Yritys
        </RadioButtonLabel>
        </RadioButtonContainer>
        <FormLabel hasErrorMessage>
          Sähköposti
        <input
            type="email"
            maxLength="255"
            value={email}
            onChange={event => setEmail(event.target.value)}
          />
        </FormLabel>
        <ErrorMessage
          codes={[ERROR_CODE.InvalidEmail, ERROR_CODE.UsernameExists]}
          payload={errorPayload}
        />
        <FormLabel hasErrorMessage>
          Etunimi
        <input
            maxLength="255"
            value={firstname}
            onChange={event => setFirstname(event.target.value)}
          />
        </FormLabel>
        <ErrorMessage
          codes={[ERROR_CODE.EmptyFirstname]}
          payload={errorPayload}
        />
        <FormLabel hasErrorMessage>
          Sukunimi
        <input
            maxLength="255"
            value={lastname}
            onChange={event => setLastname(event.target.value)}
          />
        </FormLabel>
        <ErrorMessage codes={[ERROR_CODE.EmptyLastname]} payload={errorPayload} />
        {isCreate && (
          <>
            <FormLabel hasErrorMessage>
              Salasana
            <input
                maxLength="99"
                type="password"
                value={password}
                onChange={event => setPassword(event.target.value)}
              />
            </FormLabel>
            <ErrorMessage
              codes={[ERROR_CODE.InvalidPassword]}
              payload={errorPayload}
            />
          </>
        )}
        <FormLabel hasErrorMessage>
          Puhelinnumero
        <input
            type="tel"
            maxLength="20"
            value={phone}
            placeholder="+358401234567"
            onChange={event => setPhone(event.target.value.replace(/[^+\d]/, ''))}
          />
        </FormLabel>
        <ErrorMessage codes={[ERROR_CODE.InvalidPhone]} payload={errorPayload} />
        {isBusinessUser && (
          <>
            <FormLabel>
              Yrityksen nimi
            <input
                maxLength="255"
                value={businessName}
                onChange={event => setBusinessName(event.target.value)}
              />
            </FormLabel>
            <FormLabel>
              Y-tunnus
            <input
                maxLength="255"
                value={businessId}
                onChange={event => setBusinessId(event.target.value)}
              />
            </FormLabel>
          </>
        )}
        <Button
          isPrimary
          isFullWidth
          onClick={createOrUpdateAccount}
          type="button"
        >
          {isCreate ? 'Luo tunnus' : 'Päivitä tiedot'}
        </Button>
      </FormValidator>
    )
}

AccountForm.propTypes = {
  isCreate: PropTypes.bool,
  history: {
    push: PropTypes.func
  }
}

export default AccountForm

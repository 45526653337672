import { useEffect } from 'react'
import { useStateValue } from '../state'
import { fetchRejectedPostings } from '../state/actions'

const RejectedPostingsFetcher = () => {
  const [{ user }, dispatch] = useStateValue()
  useEffect(() => {
    if (user.isAdmin) dispatch(fetchRejectedPostings) // eslint-disable-next-line
  }, [user])
  return null
}

export default RejectedPostingsFetcher

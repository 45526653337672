import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled, { withTheme } from 'styled-components/macro'
import MainNav from '../components/MainNav'
import SubNav from '../components/SubNav'
import BasePage from '../components/BasePage'
import PostingHero from '../components/PostingHero'
import PostingInfo from '../components/PostingInfo'
import PostingContactForm from '../components/PostingContactForm'
import PostingImages from '../components/PostingImages'
import PageBody from '../components/PageBody'
import SectionWithAside from '../components/SectionWithAside'
import NavTabs from '../components/NavTabs'
import AreaMap from '../components/AreaMap'
import MobileContentToggle from '../components/MobileContentToggle'
import Modal from '../components/Modal'
import AdminTools, { ButtonContainer } from '../components/AdminTools'
import UserTools, { CardControlsContainer } from '../components/UserTools'
import Footer from '../components/Footer'
import HTTPError from '../components/HTTPError'
import { API_URL, get } from '../api'
import {
  mapPostingToCard,
  mapPostingToHeroInfo,
  mapPostingToInfoBlocks,
  postingTypes
} from '../utils/mappings'
import { getAllUpcomingShowingsTexts } from '../utils/showings'
import { useStateValue } from '../state'
import { setCurrentPosting } from '../state/actions'
import { media } from '../styles'
import smoothscroll from 'smoothscroll-polyfill'
import { get as _get } from 'lodash-es'

smoothscroll.polyfill()

const LinksContainer = styled.div`
  display: none;

  ${media.tabletLandscapeUp`
    display: flex;
    flex-direction: row;
  `}

  > * {
    padding: 10px 20px;
    text-decoration: none;
    cursor: pointer;
  }
`

const SubNavLink = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
`

const StyledMap = styled.div`
  padding-top: 60px;

  #map-container {
    width: 100%;
    height: 450px;
  }
`

const Showing = styled.div`
  padding: 20px;
  background-color: ${props => props.theme.colorAccentPrimary};
  text-align: center;
  &:not(:first-child) {
    margin-top: 10px;
  }
`

const ShowingsContainer = styled.div`
  margin-top: 40px;
`

const ToolsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: column;

  > div:first-child {
    margin-bottom: 20px;
  }

  ${media.tabletLandscapeUp`
    flex-direction: row;

    > div:first-child {
      margin-bottom: 0;
    }
  `}
`

const StyledUserTools = styled(UserTools)`
  padding-bottom: 40px;

  > ${CardControlsContainer} {
    flex: 1;
    border-top: 0;
    justify-content: flex-end;

    > div:first-child {
      margin-right: 40px;
    }
  }
`

const StyledAdminTools = styled(AdminTools)`
  padding-bottom: 40px;

  > ${ButtonContainer} {
    flex: 1;
    justify-content: flex-end;

    > button {
      margin-left: 20px;
    }
  }
`

const PostingDetails = ({ location, history, theme }) => {
  const [{ user, currentPosting: posting }, dispatch] = useStateValue()
  const [selectedTab, setSelectedTab] = useState(0)
  const [showModal, setShowModal] = useState(false)
  const [statusCode, setStatusCode] = useState(null)
  const infoRef = useRef(null)
  const imagesRef = useRef(null)
  const mapRef = useRef(null)

  const splitUrl = location.pathname.split('/')
  const postingType = splitUrl[1]
  const postingId = splitUrl[2]

  const getPosting = async () => {
    const res = await get(`${API_URL}/${postingType}/${postingId}`)
    if (res.status === 200) {
      const posting = await res.json()
      dispatch(setCurrentPosting(posting))
      document.title = `Business Joensuu - ${postingTypes.find(x => x.type === postingType).displayName
        } - ${posting.address}`
    } else {
      setStatusCode(res.status)
    }
  }

  useEffect(() => {
    getPosting() // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const links = () => [
    <SubNavLink key="info" onClick={() => scrollToRef(infoRef)}>
      Tiedot
    </SubNavLink>,
    <SubNavLink key="images" onClick={() => scrollToRef(imagesRef)}>
      Kuvat
    </SubNavLink>,
    <SubNavLink key="map" onClick={() => scrollToRef(mapRef)}>
      Sijainti
    </SubNavLink>
  ]

  const showAdminTools = () => posting && user.isAdmin

  const renderAdminTools = () => {
    const cardInfo = mapPostingToCard(posting, postingType)
    return (
      <StyledAdminTools
        type={postingType}
        posting={posting}
        postingInfo={{
          id: posting.id,
          data: [
            ..._get(cardInfo, 'leftColumnItems', []),
            ..._get(cardInfo, 'rightColumnItems', [])
          ].join(', ')
        }}
        isApproved={posting.isApproved}
        isRejected={posting.isRejected}
        history={history}
      />
    )
  }

  const showUserTools = () => {
    return (
      posting && _get(user, 'attributes.id') === _get(posting, 'userInfo.id')
    )
  }

  const renderUserTools = () => {
    return (
      <StyledUserTools
        posting={posting}
        postingType={postingType}
        history={history}
      />
    )
  }

  const scrollToRef = ref =>
    ref &&
    ref.current &&
    window.scrollTo({
      top:
        ref.current.offsetTop -
        parseInt(theme.mainNavHeight) -
        parseInt(theme.subNavHeight),
      behavior: 'smooth'
    })

  // TODO 14.8.2019, vpeurala: This comparison of the current posting id with the id part of path is a really ugly hack which fixes [BJR-19] in a quick and dirty way. To fix this properly would require re-thinking this whole component.
  /* eslint eqeqeq: "off" */
  if (posting && posting.id == window.location.pathname.split('/')[2]) {
    const upComingShowings =
      posting.metadata && posting.metadata.showings
        ? getAllUpcomingShowingsTexts(posting.metadata.showings)
        : undefined
    return (
      <>
        <MainNav history={history} />
        <SubNav>
          <LinksContainer>{links()}</LinksContainer>
          <NavTabs
            tabs={links()}
            onClick={tabIndex => setSelectedTab(tabIndex)}
            selectedTab={selectedTab}
          />
        </SubNav>
        <BasePage>
          <ToolsContainer>
            {showUserTools() && renderUserTools()}
            {showAdminTools() && renderAdminTools()}
          </ToolsContainer>
          <PostingHero
            ref={infoRef}
            heroInfo={mapPostingToHeroInfo(posting, postingType)}
          />
          <PageBody>
            {upComingShowings && upComingShowings.length !== 0 && (
              <ShowingsContainer>
                {upComingShowings.map((showing, i) => (
                  <Showing key={i}>{showing}</Showing>
                ))}
              </ShowingsContainer>
            )}
            <SectionWithAside>
              <PostingInfo
                postingOverview={posting.overview}
                postingInfoBlocks={mapPostingToInfoBlocks(posting, postingType)}
                postingCreateDate={posting.createdAt}
                postingId={posting.id}
              />
              <PostingContactForm posting={posting} location={location} />
            </SectionWithAside>
            <PostingImages images={posting.images} ref={imagesRef} />
            <StyledMap ref={mapRef}>
              <h2>Sijainti</h2>
              <AreaMap postings={[posting]} postingType={postingType} />
            </StyledMap>
            <MobileContentToggle onClick={() => setShowModal(true)}>
              Ota yhteyttä
            </MobileContentToggle>
            <Modal
              showModal={showModal}
              closeModal={() => setShowModal(false)}
              fullSize
            >
              <PostingContactForm posting={posting} location={location} />
            </Modal>
          </PageBody>
        </BasePage>
        <Footer />
      </>
    )
  } else {
    return (
      <>
        <MainNav history={history} />
        <SubNav>
          <LinksContainer>{links()}</LinksContainer>
          <NavTabs
            tabs={links()}
            onClick={tabIndex => setSelectedTab(tabIndex)}
            selectedTab={selectedTab}
          />
        </SubNav>
        <BasePage>
          {statusCode
            ? (
              <HTTPError statusCode={statusCode} />
            )
            : (
              <p style={{ textAlign: 'center' }}>Loading...</p>
            )}
        </BasePage>
      </>
    )
  }
}

PostingDetails.propTypes = {
  history: PropTypes.object,
  theme: PropTypes.object,
  location: PropTypes.shape({
    pathname: PropTypes.string
  })
}

export default withTheme(PostingDetails)
